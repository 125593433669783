/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppDispatch } from '../../redux/hooks';
import { logoutUser } from '../../redux/slices/UserSlice';

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

export const Logout: FC = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const logout = async () => {
            await dispatch(logoutUser());
        };
        logout();
    }, [dispatch]);

    return <Navigate to='/auth' />;
};

/* -------------------------------------------------------------------------- */
