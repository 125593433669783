import { FacilityAssessment } from '../../../../../types';

/**
 * Constructs a formatted address from provided address components.
 * @param {(string | null | undefined)[]} addressParts - An array of address components.
 * @returns {string} - The formatted address string.
 */
const formatAddress = (addressParts: (string | null | undefined)[]): string => {
    // Convert null values to undefined and filter out empty values
    return addressParts
        .map(part => part === null ? undefined : part)
        .filter(part => part && part.trim() !== '')
        .join(', ') || 'N/A';
};

/**
 * Generates the PDF content from a facility assessment.
 * @param {FacilityAssessment} assessment.
 * @returns {{ header: string[]; data: Array<{ [key: string]: string }> }} - An object containing headers and data for the PDF table.
 */
export const generateFacilityInformationTableContenForPDF = (assessment: FacilityAssessment) => {
    const streetAddress = formatAddress([
        assessment.pfn_address1street1,
        assessment.pfn_address1street2,
        assessment.pfn_address1city,
        assessment.pfn_address1province,
        assessment.pfn_address1country,
        assessment.pfn_address1postalcode,
    ]);

    const mailingAddress = formatAddress([
        assessment.pfn_address2street1,
        assessment.pfn_address2street2,
        assessment.pfn_address2city,
        assessment.pfn_address2province,
        assessment.pfn_address2country,
        assessment.pfn_address2postalcode,
    ]);

    const controlledDrugShipmentAddress = formatAddress([
        assessment.pfn_address3street1,
        assessment.pfn_address3street2,
        assessment.pfn_address3city,
        assessment.pfn_address3province,
        assessment.pfn_address3country,
        assessment.pfn_address3postalcode,
    ]);

    const data = [
        { label: 'Name', value: assessment.pfn_facilityname || 'N/A' },
        { label: 'Email Address', value: assessment.pfn_email || 'N/A' },
        { label: 'Street Address', value: streetAddress },
        { label: 'Phone Number', value: assessment.pfn_phonenumber || 'N/A' },
        { label: 'Mailing Address', value: mailingAddress },
        { label: 'Website', value: assessment.pfn_website || 'N/A' },
        { label: 'Controlled Drug Shipment', value: controlledDrugShipmentAddress },
        { label: 'Facility Owner and Contact', value: assessment.pfn_ownername || 'N/A' },
        { label: 'Designated Registrant', value: assessment.pfn_contactname || 'N/A' },
        { label: 'Other Veterinarians (including part-time and contract)', value: assessment.pfn_otherveterinarians || 'N/A' },
        { label: 'Number of RVTs', value: assessment.pfn_numberofrvts || 'N/A' },
        { label: 'Number of Other Employees', value: assessment.pfn_numberofotheremployees || 'N/A' },
    ];

    return {
        tableHeader: ['FIELD', 'VALUE'],
        data: data.map(item => ({ Field: item.label, Information: item.value })),
    };
};