/* --------------------------------- IMPORTS -------------------------------- */
import React, { FC, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import facilityAssessmentApi from '../../../../api/facilityAssessmentsApi';
import { FacilityAssessment } from '../../../../types';

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */
const AnnualDeclarationSubmissionConfirmation: FC = () => {
    const { pfn_facilityselfassessmentid } = useParams<{ pfn_facilityselfassessmentid: string }>();
    const [assessment, setAssessment] = useState<FacilityAssessment | null>(null);
    const [loading, setLoading] = useState(true);
    const [errMsg, setErrMsg] = useState<string | null>(null);

    useEffect(() => {
        const fetchAssessment = async () => {
            if (pfn_facilityselfassessmentid) {
                try {
                    const response = await facilityAssessmentApi.getFacilityAssessmentById(pfn_facilityselfassessmentid);
                    if (response && response.success && response.data) {
                        setAssessment(response.data);
                    } else {
                        setErrMsg('Error: Unable to fetch data.');
                    }
                } catch (error) {
                    setErrMsg('Error: An unexpected error occurred while fetching data.');
                }
            } else {
                setErrMsg('Error: Invalid assessment ID.');
            }
            setLoading(false);
        };

        fetchAssessment();
    }, [pfn_facilityselfassessmentid]);

    if (loading) {
        return <div>Loading assessment data, please wait...</div>;
    }

    if (errMsg) {
        return <div className="text-danger">{errMsg}</div>;
    }

    if (!assessment) {
        return <div>Error: No data found.</div>;
    }

    return (
        
        <div>
            <h2>Annual Declaration Submission Confirmation</h2>
            <br/>
            <br/>
            <div className='mb-20'>
                Thank you for submitting your Annual Declaration. You can download the report on the My Annual Declarations page.
            </div>
            <div className='d-flex justify-content-center mt-3'>
                <Link to={`/facilities/assessments/${assessment.pfn_facilityname}/${assessment._pfn_facilityid_value}`}> <button type='button' className='btn btn-primary fw-bolder mx-2'>My Annual Declarations</button></Link>
                <Link to="/facilities/my-facilities" className="btn btn-primary">Back to My Facilities</Link>
            </div>
        </div>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */
export { AnnualDeclarationSubmissionConfirmation };

/* -------------------------------------------------------------------------- */