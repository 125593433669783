import React from 'react';
import { TEXT_FACILITIES_ANNUAL_DECLARATIONS_GENERAL_INFO } from '../../../../../constants';

export const generateGeneralInfoPDFContent = () => {
    return [
        TEXT_FACILITIES_ANNUAL_DECLARATIONS_GENERAL_INFO.value,
    ];
};

const GenerateGeneralInfo: React.FC = () => {
    return (
        <div className='card card-xl mb-5 mb-xl-8'>
            <div className='card-header border-0'>
                <h3 className='card-title fw-bolder text-dark'>General Information</h3>
            </div>
            <div className='card-body pt-0'>
                {TEXT_FACILITIES_ANNUAL_DECLARATIONS_GENERAL_INFO.value}
            </div>
            <div className='card-body pt-0'>
                ¹ See also <a href="https://www.cvbc.ca/resources/practice-facilities/self-assessments/" target="_blank" rel="noopener noreferrer">
                    Self-Assessments – College of Veterinarians of BC (cvbc.ca)
                </a>
            </div>
        </div>
    );
};

export default GenerateGeneralInfo;