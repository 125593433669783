/* --------------------------------- IMPORTS -------------------------------- */
import * as Yup from 'yup';
import { FC, useState } from 'react';
import { useFormik } from 'formik';
import applicationApi from '../../../../../../api/applicationApi';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../../../redux/hooks';
import { Table } from 'react-bootstrap';
import { Application } from '../../../../../../types';
import { LINK_REGISTRATIOM_RENEWAL_DETAILS1, TEXT_CANT_RENEW_WARNING } from '../../../../../../constants';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

interface Props {
    goToNextStep: Function;
    application: Application;
}
/* ----------------------------- INITIAL VALUES ----------------------------- */

const initialValues = {
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
    checkbox5: false,
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- SCHEMA --------------------------------- */

const validationSchema = Yup.object().shape({
    checkbox1: Yup.boolean()
        .oneOf([true], 'This checkbox is required.'),
    checkbox2: Yup.boolean()
        .oneOf([true], 'This checkbox is required.'),
    checkbox3: Yup.boolean()
        .oneOf([true], 'This checkbox is required.'),
    checkbox4: Yup.boolean()
        .oneOf([true], 'This checkbox is required.'),
    checkbox5: Yup.boolean()
        .oneOf([true], 'This checkbox is required.'),
});

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const InitialDetailsForm: FC<Props> = ({ goToNextStep, application }) => {
    const user = useAppSelector((state) => state.user);
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const navigate = useNavigate();
    const prevYearTranscript = user.transcripts.find(transcript => transcript.name === '2023 - 2024'); // Needs to be updated every year. Add logic to calculate this from CRM instead.

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            try {
                setErrMsg(null);
                setLoading(true);

                if (user.statusHistory.category.value && user.statusHistory.subcategory.value) {
                    const response = await applicationApi.updateRenewalAppInitialDetails({
                        pfn_applicationid: application.pfn_applicationid,
                    });

                    if (response) {
                        if (!response.success) {
                            if (response.message) {
                                setErrMsg(response.message);
                            } else {
                                setErrMsg('An error has occurred, please try again later');
                            }
                        } else {
                            navigate(`/applications/renewal/status/${response.data?.pfn_applicationid}`);
                        }
                    } else {
                        setErrMsg('An error has occurred, please try again later');
                    }
                } else {
                    setErrMsg('No category and/or subcategory found on user');
                }

                setLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
                setLoading(false);
            }
        },
    });

    return (
        user.renewalYear && user.pfn_canrenew ?
            <>
                <div className='card card-xl mb-5 mb-xl-8'>
                    <div className='card-header border-0'>
                        <h3 className='card-title fw-bolder text-dark'>Registration Renewal Application Form</h3>
                    </div>
                    <div className='card-body pt-0'>
                        <p className='mb-5'>
                            I, {user.firstname} {user.lastname}, apply to renew my {user.statusHistory.category.formatted} ({user.statusHistory.subcategory.formatted}) registration with the College of Veterinarians of British Columbia for the {user.renewalYear} registration year.
                        </p>
                        <b>Declarations:</b><br /><br />
                        <ol style={{ listStyleType: 'none', paddingLeft: '0' }}>
                            <li className='mb-3'>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <input
                                        type="checkbox" 
                                        id="checkbox1"
                                        name="checkbox1"
                                        checked={formik.values.checkbox1}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        required
                                        style={{ marginRight: '10px' }}
                                    />
                                    <span>1.</span>
                                    <label htmlFor="checkbox1" style={{ marginLeft: '10px' }}>
                                        I understand that it’s my responsibility to ensure that the College has accurate and up-to-date contact information for me at all times. I have already reviewed or will promptly review the Contact Information in my online registrant account to ensure that the information is accurate and up-to-date.
                                        <br />(in a desktop browser, use the left-side pop-out sidebar menu; on a mobile device, use the pop-out menu accessed in the top left corner of the screen. From this menu, select Contact Information under the Manage Account section)
                                    </label>
                                </div>
                                {formik.touched.checkbox1 && formik.errors.checkbox1 && (
                                    <div className="text-danger mt-2">{formik.errors.checkbox1}</div> 
                                )}
                            </li>
                            <br/>
                            <li className='mb-3'>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <input
                                        type="checkbox"
                                        id="checkbox2"
                                        name="checkbox2"
                                        checked={formik.values.checkbox2}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        required
                                        style={{ marginRight: '10px' }}
                                    />
                                    <span>2.</span>
                                    <label htmlFor="checkbox2" style={{ marginLeft: '10px' }}>
                                        I understand that it’s my responsibility to maintain an accurate and up-to-date record of the practice facility(ies) or other, non-practice facility place of employment where I work. I have already reviewed or will promptly review my facility/place of employment information in my online registrant account to ensure that the information is accurate and up-to-date.
                                        <br />(in a desktop browser, use the left-side pop-out sidebar menu; on a mobile device, use the pop-out menu accessed in the top left corner of the screen. From this menu on all devices, select Facilities &rarr; My Facilities to review and update your work information)
                                    </label>
                                </div>
                                {formik.touched.checkbox2 && formik.errors.checkbox2 && (
                                    <div className="text-danger mt-2">{formik.errors.checkbox2}</div>
                                )}
                            </li>
                            <br/>
                            <li className='mb-3'>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <input
                                        type="checkbox"
                                        id="checkbox3"
                                        name="checkbox3"
                                        checked={formik.values.checkbox3}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        required
                                        style={{ marginRight: '10px' }}
                                    />
                                    <span>3.</span>
                                    <label htmlFor="checkbox3" style={{ marginLeft: '10px' }}>
                                        I understand that I:
                                        <ol type="a" className='mt-2'>
                                            <li>must complete my required minimum CE hours for the 2023-2024 CE cycle by December 31, 2024,</li>
                                            <li>must report my CE in the 2023-2024 CE transcript in my online registrant account by no later than January 2, 2025, and</li>
                                            <li>must either upload my certificates of attendance/completion for each reported session to my online account, or be prepared to promptly provide my certificates upon request by the CVBC.</li>
                                            <br />
                                            <p className='mb-3'><b>{user.pfn_salutation_Formatted} {user.firstname} {user.lastname}, your minimum CE requirement (for purposes of continuing your CVBC registration) for the {prevYearTranscript?.name} CE Cycle is {prevYearTranscript?.creditsRequired.formatted} hours</b>. This number does NOT include any CE hours that you have been required to complete as part of an undertaking to the CVBC’s Investigation and/or Discipline Committees (any such CE requirement is in addition to the biennial CE requirement established within the Continuing Competence Program).</p>
                                        </ol>
                                    </label>
                                </div>
                                {formik.touched.checkbox3 && formik.errors.checkbox3 && (
                                    <div className="text-danger mt-2">{formik.errors.checkbox3}</div>
                                )}
                            </li>
                            <br/>
                            <li className='mb-5'>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <input
                                        type="checkbox"
                                        id="checkbox4"
                                        name="checkbox4"
                                        checked={formik.values.checkbox4}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        required
                                        style={{ marginRight: '10px' }}
                                    />
                                    <span>4.</span>
                                    <label htmlFor="checkbox4" style={{ marginLeft: '10px' }}>
                                        I declare my compliance with the Veterinarians Act, the regulations, and the CVBC’s bylaws, and to any applicable limits or conditions that have been imposed upon my registration under the Act or bylaws.
                                    </label>
                                </div>
                                {formik.touched.checkbox4 && formik.errors.checkbox4 && (
                                    <div className="text-danger mt-2">{formik.errors.checkbox4}</div>
                                )}
                            </li>
                            <br/>
                            <li className='mb-5'>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <input
                                        type="checkbox"
                                        id="checkbox5"
                                        name="checkbox5"
                                        checked={formik.values.checkbox5}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        required
                                        style={{ marginRight: '10px' }}
                                    />
                                    <span>5.</span>
                                    <label htmlFor="checkbox5" style={{ marginLeft: '10px' }}>
                                        I understand that a failure to complete part or all of the registration renewal process (including completion and submission of this form, payment of the annual registration fee, payment of any other fees or debts owing to the CVBC, including the late fee if I miss the November 30th renewal deadline, and completion and reporting of my minimum required CE hours in accordance with the timeline indicated above), will result in the cancellation of my registration effective January 1, 2025.
                                    </label>
                                </div>
                                {formik.touched.checkbox5 && formik.errors.checkbox5 && (
                                    <div className="text-danger mt-2">{formik.errors.checkbox5}</div>
                                )}
                            </li>
                        </ol>

                        <p className='mb-5'>
                            I, {user.firstname} {user.lastname}, attest that the above statements are true and accurate to the best of my knowledge.
                        </p>

                        {/* Submit Section */}
                        <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='initailDetailsForm'>
                           <br/>

                            <div className='fv-row'>
                                <div className='d-flex justify-content-between'>
                                    <Link to={LINK_REGISTRATIOM_RENEWAL_DETAILS1.value}>
                                        <button type='button' className='btn btn-primary me-3'>
                                            Cancel
                                        </button>
                                    </Link>

                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        disabled={formik.isSubmitting || loading}
                                    >
                                        {!loading && <span className='indicator-label'>Submit</span>}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Please wait...
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                                {errMsg && <div className='text-danger text-center mt-7'>{errMsg}</div>}
                            </div>
                        </form>
                    </div>
                </div>
            </>
        : (<NoRenewalSection />)
    );
};

/* -------------------------------------------------------------------------- */

/* -------------------------------- SUB-COMPONENTS -------------------------------- */

const NoRenewalSection = () => (
    <div className='card-body pt-0'>
        <div className='note-card d-flex align-items-center bg-light rounded p-5 mb-3'>
            <div className='note-card-inner flex-grow-1 me-2'>{TEXT_CANT_RENEW_WARNING.value}</div>
        </div>
    </div>
);

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { InitialDetailsForm };

/* -------------------------------------------------------------------------- */