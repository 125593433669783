/* --------------------------------- IMPORTS -------------------------------- */

import { FC } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../_metronic/layout/core';
import {
    MixedWidgetCustom1,
    MixedWidgetCustom2,
    ListsWidgetCustom1,
    ListsWidgetCustom2,
    ListsWidgetCustom3,
    // ListsWidgetCustom4,
    ListsWidgetCustom5,
    ListsWidgetCustom6,
    ListsWidgetCustom7,
    ListsWidgetCustom8,
} from '../../../_metronic/partials/widgets';
import { useAppSelector } from '../../redux/hooks';

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const DashboardPage: FC = () => {
    const user = useAppSelector((state) => state.user);

    return (
        <>
            {/* begin::Row */}
            <div className='row gy-5 g-xl-8'>
                <div className='col-lg-4'>
                    <MixedWidgetCustom2 className='card-xl-stretch mb-xl-8' color='info' />
                </div>
                <div className='col-lg-4'>
                    <MixedWidgetCustom1 className='card-xl-stretch mb-xl-8' color='primary' />
                </div>
                <div className='col-lg-4'>
                    <ListsWidgetCustom1 className='card-xl-stretch mb-xl-8' />
                </div>
            </div>
            {/* end::Row */}
            {/* begin::Row */}
            {(user.renewalYear || user.anyActiveInvoices) && (
                <div className='row gy-5 g-xl-8'>
                    <div className='col-lg-12'>
                        <ListsWidgetCustom8 className='card-xl-stretch mb-xl-8' />
                    </div>
                </div>
            )}
            {/* end::Row */}
            {/* begin::Row */}
            <div className='row gy-5 g-xl-8'>
                <div className='col-lg-4'>
                    <ListsWidgetCustom3 className='card-xl-stretch mb-xl-8' />
                </div>
                <div className='col-lg-8'>
                    <ListsWidgetCustom2 className='card-xl-stretch mb-xl-8' />
                </div>
            </div>
            {/* end::Row */}
            {/* begin::Row */}
            <div className='row gy-5 g-xl-8'>
                <div className='col-lg-7'>
                    <ListsWidgetCustom5 className='card-xl-stretch mb-xl-8' />
                </div>
                <div className='col-lg-5'>
                    <div className='row gy-5 g-xl-8'>
                        <ListsWidgetCustom6 className='card-xl-stretch mb-xl-8' />
                    </div>
                    <div className='row gy-5 g-xl-8'>
                        <ListsWidgetCustom7 className='card-xl-stretch mb-xl-8' />
                    </div>
                </div>
            </div>
            {/* end::Row */}
            {/* begin::Row */}
            {/* <div className='row gy-5 g-xl-8'>
            <div className='col-lg-12'>
                <ListsWidgetCustom4 className='card-xl-stretch mb-xl-8' />
            </div>
        </div> */}
            {/* end::Row */}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* ------------------------------ PAGE WRAPPER ------------------------------ */

const DashboardWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <DashboardPage />
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { DashboardWrapper };

/* -------------------------------------------------------------------------- */
