import { FacilityAssessment } from '../../../../../types';

/**
 * Generates the declarations content for PDF.
 * @param {FacilityAssessment} assessment - The facility assessment.
 * @param {string} userFullName - Full name of the user for personalization.
 * @returns {{ label: string; introText: string; content: Array<{ text: string }> }}
 */

export const generateDeclarationsContentForPDF = (assessment: FacilityAssessment, userFullName: string) => {
    const declarationContent = [
        { text: `I, ${userFullName}, the Designated Registrant of ${assessment._pfn_facilityid_value_Formatted}, hereby sign this undertaking declaring that I have read and understood my duties under section 3.6 of Part 3 of the CVBC Bylaws.` },
        { text: "1. The form above has been filled out to the best of my knowledge and in an accurate manner." },
        { text: "2. I will promptly notify the CVBC of any changes in the facility’s scope of practice, location, designated registrant, controlled drug records, or ownership, as required under Part 3.6(9)." },
        { text: "3. I understand that any reported changes may be reviewed by PFAC, and an inspection may be directed as per 3.18(1)." },
        { text: '' },
        { text: "Acknowledgement: I acknowledge that I have completed the accompanying Self-Assessment Form, which is available upon request." },
        { text: "Designated Registrant’s Signature: " + (assessment.pfn_drsignature ? 'Signed' : ''), bold: true},
        { text: "" },
        { text: "" },
        { text: "a. Substantial: One that leads to additional Accreditation Standards and/or related PPS being applicable. This would include, but is not limited to, the addition of veterinary service categories as per Standard 1, the request to order controlled drugs when not previously accredited for such, the addition of a new species group to the services offered, or the addition of major imaging equipment (other than endoscopy and ultrasound)." },
        { text: "b. Significant: A significant or material renovation would be structural alterations that change the existing floor plan or expand the space, with or without a change to scope of practice, i.e., additions or subtractions of walls, add-on structure to an existing facility, addition of a fixed facility to previously mobile-only facility. Painting and changes to fixtures would not be considered significant." },
        { text: "" },
        { text: "" },
    ];

    return { content: declarationContent };
};