/* --------------------------------- IMPORTS -------------------------------- */

import * as Yup from 'yup';
import { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { FacilityAssessment, FacilityDistrict } from '../../../../../../types';
import facilityAssessmentApi from '../../../../../../api/facilityAssessmentsApi';
import { Table } from 'react-bootstrap';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

interface Props {
    goToNextStep: Function;
    assessment: FacilityAssessment;
    goToPreviousStep: Function;
}

/* -------------------------------------------------------------------------- */

/* --------------------------------- SCHEMA --------------------------------- */

const validationSchema = Yup.object().shape({
    pfn_districtid: Yup.string().required('District is required'),
});

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const DistrictsForm: FC<Props> = ({ goToNextStep, goToPreviousStep, assessment }) => {
    const [formLoading, setFormLoading] = useState(false);
    const [districtsLoading, setDistrictsLoading] = useState(true);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const [districts, setDistricts] = useState<FacilityDistrict[]>([]);

    useEffect(() => {
        const getDistrictsAsync = async () => {
            try {
                setDistrictsLoading(true);
                const response = await facilityAssessmentApi.getDistricts();

                if (response) {
                    if (!response.success) {
                        setErrMsg(response?.message || 'No districts found');
                    } else {
                        if (response.data) {
                            setDistricts(response.data);
                        }
                    }
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
            }
            setDistrictsLoading(false);
        };

        getDistrictsAsync();

        return () => {
            setDistricts([]);
            setErrMsg(null);
            setDistrictsLoading(false);
        };
    }, []);

    const initialValues = {
        pfn_districtid: assessment._pfn_districtid_value ? assessment._pfn_districtid_value : '',
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            try {
                setErrMsg(null);
                setFormLoading(true);

                if (assessment) {
                    const response = await facilityAssessmentApi.updateAssessmentDistrict(
                        assessment.pfn_facilityselfassessmentid,
                        assessment._pfn_facilityid_value,
                        values.pfn_districtid,
                    );

                    if (response) {
                        if (!response.success) {
                            if (response.message) {
                                setErrMsg(response.message);
                            } else {
                                setErrMsg('An error has occured, please try again later');
                            }
                        } else {
                            goToNextStep();
                        }
                    } else {
                        setErrMsg('An error has occured, please try again later');
                    }
                } else {
                    setErrMsg('No assessment found');
                }

                setFormLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
                setFormLoading(false);
            }
        },
    });

    return (
        <>
            {districtsLoading ? (
                <div>loading...</div>
            ) : errMsg ? (
                <div>{errMsg}</div>
            ) : (
                <div>
                    <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='districtsForm'>
                        <div className='table-container mb-7'>
                            <label className='form-label fs-6 fw-bolder text-dark required mb-5'>
                                Please select the physical location of your Facility (not the areas covered). See the
                                list below to help pick the correct district.
                            </label>
                            <Table className='districts-table' striped bordered>
                                <thead>
                                    <tr>
                                        <th className='fw-bolder'>District</th>
                                        <th className='fw-bolder'>Head Office</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {districts?.map((district, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className='name d-flex justify-content-start'>
                                                    <input
                                                        type='radio'
                                                        {...formik.getFieldProps('pfn_districtid')}
                                                        className={clsx('form-check-inline ', {
                                                            'is-invalid':
                                                                formik.touched.pfn_districtid &&
                                                                formik.errors.pfn_districtid,
                                                        })}
                                                        name='pfn_districtid'
                                                        value={district.pfn_britishcolumbiaregionalid}
                                                        checked={
                                                            formik.values.pfn_districtid ===
                                                            district.pfn_britishcolumbiaregionalid
                                                        }
                                                    />
                                                    <label>{district.pfn_name}</label>
                                                </td>
                                                <td className='office'>
                                                    {district._pfn_rdofficelocationid_value_Formatted}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                            {formik.touched.pfn_districtid && formik.errors.pfn_districtid && (
                                <div className='fv-plugins-message-container'>
                                    <span className='text-danger' role='alert'>
                                        {formik.errors.pfn_districtid}
                                    </span>
                                </div>
                            )}
                        </div>

                        <div className='d-flex align-items-center mb-10'>
                            <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                            <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                        </div>

                        <div className='fv-row'>
                            <div className='d-flex justify-content-between'>
                                <button
                                    type='button'
                                    className='btn btn-primary me-3'
                                    onClick={() => goToPreviousStep()}
                                >
                                    Previous
                                </button>

                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    disabled={formik.isSubmitting || formLoading}
                                >
                                    {!formLoading && <span className='indicator-label'>Next</span>}
                                    {formLoading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                        {errMsg && <div className='text-danger text-center mt-7'>{errMsg}</div>}
                    </form>
                </div>
            )}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { DistrictsForm };

/* -------------------------------------------------------------------------- */
