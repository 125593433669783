/* --------------------------------- IMPORTS -------------------------------- */

import { FC } from 'react';
import { useAppSelector } from '../../../../redux/hooks';
import { Link } from 'react-router-dom';

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const ContactInfoEmail: FC = () => {
    const user = useAppSelector((state) => state.user);

    return (
        <div className='card card-xl mb-5 mb-xl-8'>
            <div className='card-header border-0'>
                <h3 className='card-title fw-bolder text-dark'>Email</h3>
            </div>

            <div className='card-body pt-0'>
                <div className='d-flex align-items-center bg-light rounded p-5 mb-7'>
                    <span className='bullet bullet-vertical h-40px bg-primary me-10'></span>

                    <div className='flex-grow-1 me-2'>
                        <div className='fw-bolder text-gray-800 fs-6'>
                            Personal Email {user.email.main === user.email.personal && '(Main)'}
                        </div>
                        <span className='text-muted fw-bold d-block'>{user.email.personal}</span>
                    </div>
                </div>

                <div className='d-flex align-items-center bg-light rounded p-5 mb-7'>
                    <span className='bullet bullet-vertical h-40px bg-primary me-10'></span>

                    <div className='flex-grow-1 me-2'>
                        <div className='fw-bolder text-gray-800 fs-6'>
                            Work Email {user.email.main === user.email.work && '(Main)'}
                        </div>
                        <span className='text-muted fw-bold d-block'>{user.email.work || '---'}</span>
                    </div>
                </div>

                <Link className='btn btn-sm btn-primary' to='/change-email'>
                    Edit
                </Link>
            </div>
        </div>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { ContactInfoEmail };

/* -------------------------------------------------------------------------- */
