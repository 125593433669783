/* --------------------------------- IMPORTS -------------------------------- */

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { errorSlice } from './slices/ErrorSlice';
import { userSlice } from './slices/UserSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

/* -------------------------------------------------------------------------- */

/* -------------------------------- REDUCERS -------------------------------- */

const reducers = combineReducers({
    user: userSlice.reducer,
    error: errorSlice.reducer,
});

/* -------------------------------------------------------------------------- */

/* ------------------------- PERSISTANT STATE SETUP ------------------------- */

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

/* -------------------------------------------------------------------------- */

/* ---------------------------------- STORE --------------------------------- */

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

let persistor = persistStore(store);

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { persistor, store };

/* -------------------------------------------------------------------------- */
