/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useState } from 'react';
import { CourseState, UploadedDocState, deleteDocument } from '../../../../redux/slices/UserSlice';
import { useAppDispatch } from '../../../../redux/hooks';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

interface Props {
    course: CourseState;
    doc: UploadedDocState;
}

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const UploadedDocumentDelete: FC<Props> = ({ course, doc }) => {
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const [confirmClick, setConfirmClick] = useState(false);
    const dispatch = useAppDispatch();

    const handleDeleteClick = async () => {
        try {
            setErrMsg(null);
            setLoading(true);

            if (!course.id || !doc.id) {
                throw new Error('Course selected has no id');
            }

            const response = await dispatch(deleteDocument({ pfn_cecourseid: course.id, annotationid: doc.id }));
            if (response) {
                if (!response.payload?.success) {
                    if (response.payload?.message) {
                        setErrMsg(response.payload.message);
                    } else {
                        setErrMsg('An error has occured, please try again later');
                    }
                }
            } else {
                setErrMsg('An error has occured, please try again later');
            }

            setConfirmClick(false);
            setLoading(false);
        } catch (err) {
            if (err instanceof Error) {
                setErrMsg(err.message);
            } else {
                setErrMsg('An error has occurred, please try again later');
            }
            setLoading(false);
        }
    };

    return (
        <div className='mb-2'>
            <span>{doc.fileName}</span>
            {!errMsg ? (
                confirmClick ? (
                    <span className='btn-link link-danger cursor-pointer hover ms-3'>
                        {!loading ? (
                            <span
                                className='text-danger'
                                onClick={() => {
                                    setErrMsg(null);
                                    handleDeleteClick();
                                }}
                            >
                                Delete
                            </span>
                        ) : (
                            <span>Please wait...</span>
                        )}
                    </span>
                ) : (
                    <span
                        className='btn-link link-primary cursor-pointer hover ms-3'
                        onClick={() => {
                            setConfirmClick(true);
                        }}
                    >
                        <i className='bi bi-trash3 fs-4 text-danger'></i>
                    </span>
                )
            ) : (
                <span className='ms-3 text-danger'>An error occured</span>
            )}
        </div>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { UploadedDocumentDelete };

/* -------------------------------------------------------------------------- */
