/* --------------------------------- IMPORTS -------------------------------- */

import * as Yup from 'yup';
import { FC, useState } from 'react';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { FacilityAssessment } from '../../../../../../types';
import facilityAssessmentApi from '../../../../../../api/facilityAssessmentsApi';
import { TEXT_FACILITIES_ANNUAL_DECLARATIONS_PUBLICATIONS1, TEXT_FACILITIES_ANNUAL_DECLARATIONS_PUBLICATIONS2 } from '../../../../../../constants';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

interface Props {
    goToNextStep: Function;
    assessment: FacilityAssessment;
    goToPreviousStep: Function;
}

/* -------------------------------------------------------------------------- */

/* --------------------------------- SCHEMA --------------------------------- */

const validationSchema = Yup.object().shape({
    pfn_onlinepublication: Yup.string().required('Online publication is required'),
});

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const PublicationForm: FC<Props> = ({ goToNextStep, assessment, goToPreviousStep }) => {
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);

    const initialValues = {
        pfn_onlinepublication: assessment.pfn_onlinepublication_Formatted
            ? assessment.pfn_onlinepublication_Formatted
            : '',
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            try {
                setErrMsg(null);
                setLoading(true);

                if (assessment) {
                    const response = await facilityAssessmentApi.updateAssessmentPublication(
                        assessment.pfn_facilityselfassessmentid,
                        assessment._pfn_facilityid_value,
                        values.pfn_onlinepublication,
                    );

                    if (response) {
                        if (!response.success) {
                            if (response.message) {
                                setErrMsg(response.message);
                            } else {
                                setErrMsg('An error has occured, please try again later');
                            }
                        } else {
                            goToNextStep();
                        }
                    } else {
                        setErrMsg('An error has occured, please try again later');
                    }
                } else {
                    setErrMsg('No assessment found');
                }

                setLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
                setLoading(false);
            }
        },
    });

    return (
        <div className='w-lg-600px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
            <form className='form w-100' onSubmit={formik.handleSubmit} id='publicationsForm'>
                <div className='mb-10'>
                    <div className='mb-5'>
                        {TEXT_FACILITIES_ANNUAL_DECLARATIONS_PUBLICATIONS1.value}
                    </div>
                </div>
                {/* Start Form Row */}
                <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark mb-5'>
                    Please choose one of the following for your facility’s publication:
                    </label>
                    <select
                        {...formik.getFieldProps('pfn_onlinepublication')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_onlinepublication && formik.errors.pfn_onlinepublication,
                        })}
                        name='pfn_onlinepublication'
                    >
                        <option disabled value=''>
                            Select Permission Type
                        </option>
                        <option value='All Business Info'>I would like to have all my practice facility’s business information listed</option>
                        <option value='Business Name & Phone'>I do not want to list the street address of my practice facility</option>
                    </select>
                    {formik.touched.pfn_onlinepublication && formik.errors.pfn_onlinepublication && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                    {formik.errors.pfn_onlinepublication}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                <div className='mb-2'><b>Please note: </b> {TEXT_FACILITIES_ANNUAL_DECLARATIONS_PUBLICATIONS2.value}</div>
  
                {/* End Form Row */}

                <div className='d-flex align-items-center mb-10'>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                </div>

                <div className='fv-row'>
                    <div className='d-flex justify-content-between'>
                        <button type='button' className='btn btn-primary me-3' onClick={() => goToPreviousStep()}>
                            Previous
                        </button>

                        <button type='submit' className='btn btn-primary' disabled={formik.isSubmitting || loading}>
                            {!loading && <span className='indicator-label'>Next</span>}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </div>
                {errMsg && <div className='text-danger text-center mt-7'>{errMsg}</div>}
            </form>
        </div>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { PublicationForm };

/* -------------------------------------------------------------------------- */
