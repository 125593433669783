/* --------------------------------- IMPORTS -------------------------------- */

import * as Yup from 'yup';
import { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import applicationApi from '../../../../../../api/applicationApi';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import {
    Education,
    EducationSchool,
    EducationSpecialty,
    EducationType,
    RegAppEducationFormValues,
} from '../../../../../../types';
import { Table } from 'react-bootstrap';
import { EditEducationBtn } from '../editBtns/EditEducationBtn';
import { DeleteEducationBtn } from '../deleteBtns/deleteEducationBtn';
import { removeEmpty } from '../../../../../../helpers';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

interface Props {
    goToNextStep: Function;
}

/* -------------------------------------------------------------------------- */

/* ----------------------------- INITIAL VALUES ----------------------------- */

const initialValues = {
    pfn_educationtypeid: '',
    pfn_educationdiplomateid: '',
    pfn_accountid: '',
    pfn_otherinstitutionname: '',
    pfn_year: '',
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- SCHEMA --------------------------------- */

const validationSchema = Yup.object().shape({
    pfn_educationtypeid: Yup.string().required('Education type is required'),
    pfn_accountid: Yup.string().required('Education school is required'),
    pfn_year: Yup.string().required('Graduation year is required'),
    pfn_otherinstitutionname: Yup.string().when('pfn_accountid', {
        is: 'Other',
        then: Yup.string().required('Other institution name is required'),
    }),
    pfn_educationdiplomateid: Yup.string().when('pfn_educationtypeid', {
        is: '38169efa-3b8b-ea11-a811-000d3a0c8cd2',
        then: Yup.string().required('Education specialty is required'),
    }),
});

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const EducationForm: FC<Props> = ({ goToNextStep }) => {
    const [formLoading, setFormLoading] = useState(false);
    const [typesLoading, setTypesLoading] = useState(true);
    const [schoolsLoading, setSchoolsLoading] = useState(true);
    const [specialtiesLoading, setSpecialtiesLoading] = useState(true);
    const [educationsLoading, setEducationsLoading] = useState(true);
    const [formErrMsg, setFormErrMsg] = useState<string | null>(null);
    const [pageErrMsg, setPageErrMsg] = useState<string | null>(null);
    const [educationTypes, setEducationTypes] = useState<EducationType[]>([]);
    const [educationSchools, setEducationSchools] = useState<EducationSchool[]>([]);
    const [educationSpecialties, setEducationSpecialties] = useState<EducationSpecialty[]>([]);
    const [graduationYears, setGraduationYears] = useState<number[]>([]);
    const [currentEducations, setCurrentEducations] = useState<Education[]>([]);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            try {
                setFormErrMsg(null);
                setFormLoading(true);

                if (values.pfn_accountid === 'Other') {
                    values.pfn_accountid = '';
                } else {
                    values.pfn_otherinstitutionname = '';
                }

                if (values.pfn_educationtypeid !== '38169efa-3b8b-ea11-a811-000d3a0c8cd2') {
                    values.pfn_educationdiplomateid = '';
                }

                const response = await applicationApi.addEducation(removeEmpty(values) as RegAppEducationFormValues);

                if (response) {
                    if (!response.success) {
                        if (response.message) {
                            setFormErrMsg(response.message);
                        } else {
                            setFormErrMsg('An error has occured, please try again later');
                        }
                    } else {
                        getCurrentEducations();
                        formik.resetForm();
                    }
                } else {
                    setFormErrMsg('An error has occured, please try again later');
                }

                setFormLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setFormErrMsg(err.message);
                } else {
                    setFormErrMsg('An error has occurred, please try again later');
                }
                setFormLoading(false);
            }
        },
    });

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const newGraduationYears: number[] = [];

        for (let i = 0; i <= 50; i++) {
            newGraduationYears.push(currentYear - i);
        }

        setGraduationYears(newGraduationYears);

        return () => {
            setGraduationYears([]);
        };
    }, []);

    useEffect(() => {
        const getEducationTypes = async () => {
            try {
                const response = await applicationApi.getEducationTypes();

                if (response) {
                    if (!response.success) {
                        setPageErrMsg('No education types found');
                    } else {
                        if (response.data) {
                            setEducationTypes(response.data);
                        }
                    }
                } else {
                    setPageErrMsg('An error has occurred, please try again later');
                }
            } catch (err) {
                console.error(err);
                if (err instanceof Error) {
                    setPageErrMsg(err.message);
                } else {
                    setPageErrMsg('An error has occurred, please try again later');
                }
            }
            setTypesLoading(false);
        };

        const getEducationSchools = async () => {
            try {
                const response = await applicationApi.getEducationSchools();

                if (response) {
                    if (!response.success) {
                        setPageErrMsg('No education schools found');
                    } else {
                        if (response.data) {
                            setEducationSchools(response.data);
                        }
                    }
                } else {
                    setPageErrMsg('An error has occurred, please try again later');
                }
            } catch (err) {
                console.error(err);
                if (err instanceof Error) {
                    setPageErrMsg(err.message);
                } else {
                    setPageErrMsg('An error has occurred, please try again later');
                }
            }
            setSchoolsLoading(false);
        };

        const getEducationSpecialties = async () => {
            try {
                const response = await applicationApi.getEducationSpecialties();

                if (response) {
                    if (!response.success) {
                        setPageErrMsg('No education specialties found');
                    } else {
                        if (response.data) {
                            setEducationSpecialties(response.data);
                        }
                    }
                } else {
                    setPageErrMsg('An error has occurred, please try again later');
                }
            } catch (err) {
                console.error(err);
                if (err instanceof Error) {
                    setPageErrMsg(err.message);
                } else {
                    setPageErrMsg('An error has occurred, please try again later');
                }
            }
            setSpecialtiesLoading(false);
        };

        getEducationTypes();
        getEducationSchools();
        getEducationSpecialties();

        return () => {
            setEducationSchools([]);
            setEducationSpecialties([]);
            setEducationTypes([]);
            setSchoolsLoading(false);
            setSpecialtiesLoading(false);
            setTypesLoading(false);
            setPageErrMsg(null);
        };
    }, []);

    useEffect(() => {
        getCurrentEducations();

        return () => {
            setEducationsLoading(false);
            setFormErrMsg(null);
            setFormLoading(false);
            setCurrentEducations([]);
        };
    }, []);

    const getCurrentEducations = async () => {
        try {
            setEducationsLoading(true);
            setFormErrMsg(null);
            const response = await applicationApi.getEducationsForUser();

            if (response) {
                if (!response.success) {
                    setFormErrMsg('An error has occurred, please try again later');
                } else {
                    if (response.data) {
                        setCurrentEducations(response.data);
                    }
                }
            } else {
                setFormErrMsg('An error has occurred, please try again later');
            }

            setEducationsLoading(false);
        } catch (err) {
            if (err instanceof Error) {
                setFormErrMsg(err.message);
            } else {
                setFormErrMsg('An error has occurred, please try again later');
            }
            setEducationsLoading(false);
        }
    };

    const handleNextClick = async () => {
        goToNextStep();
    };

    const renderCurrentEducations = () => {
        return (
            <div className='mb-10'>
                <h5 className='mt-5'>List of your educations:</h5>
                <div className='table-container'>
                    <Table className='educations-table' striped bordered>
                        <thead>
                            <tr>
                                <th className='fw-bolder'>Type</th>
                                <th className='fw-bolder'>School / Institution</th>
                                <th className='fw-bolder'>Year</th>
                                <th className='fw-bolder'>Specialty (Diplomate Only)</th>
                                <th className='fw-bolder'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentEducations?.map((education, i) => {
                                return (
                                    <tr key={i}>
                                        <td className='type'>{education._pfn_educationtypeid_value_Formatted}</td>
                                        <td className='school'>
                                            {education._pfn_accountid_value_Formatted ||
                                                education.pfn_otherinstitutionname}
                                        </td>
                                        <td className='year'>{education.pfn_year}</td>
                                        <td className='specialty'>
                                            {education._pfn_educationdiplomateid_value_Formatted || '---'}
                                        </td>
                                        <td className='action'>
                                            {new Date(education.createdon) > new Date(Date.now() - 604800000) && (
                                                <>
                                                    <EditEducationBtn
                                                        education={education}
                                                        modalName={`kt_modal_edit_education_${i}`}
                                                        getCurrentEducations={getCurrentEducations}
                                                        educationSchools={educationSchools}
                                                        educationSpecialties={educationSpecialties}
                                                        educationTypes={educationTypes}
                                                        graduationYears={graduationYears}
                                                    />
                                                    <DeleteEducationBtn
                                                        education={education}
                                                        getCurrentEducations={getCurrentEducations}
                                                        modalName={`kt_modal_delete_education_${i}`}
                                                    />
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    };

    return (
        <>
            {typesLoading || schoolsLoading || specialtiesLoading ? (
                <p>Retreiviing form values, please wait...</p>
            ) : pageErrMsg ? (
                <p>{pageErrMsg}</p>
            ) : (
                <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='addEducationForm'>
                    {/* Start Form Row */}
                    <div className='fv-row mb-7'>
                        <label className='form-label fs-6 fw-bolder text-dark required'>Education Type</label>

                        <select
                            {...formik.getFieldProps('pfn_educationtypeid')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid': formik.touched.pfn_educationtypeid && formik.errors.pfn_educationtypeid,
                            })}
                            name='pfn_educationtypeid'
                        >
                            <option disabled value=''>
                                Select Type
                            </option>
                            {educationTypes.map((type, i) => {
                                return (
                                    <option key={i} value={type.pfn_educationtypeid}>
                                        {type.pfn_name}
                                    </option>
                                );
                            })}
                        </select>

                        {formik.touched.pfn_educationtypeid && formik.errors.pfn_educationtypeid && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span className='text-danger' role='alert'>
                                        {formik.errors.pfn_educationtypeid}
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    <div className='fv-row mb-7'>
                        <label className='form-label fs-6 fw-bolder text-dark required'>School</label>
                        <select
                            {...formik.getFieldProps('pfn_accountid')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid': formik.touched.pfn_accountid && formik.errors.pfn_accountid,
                            })}
                            name='pfn_accountid'
                        >
                            <option disabled value=''>
                                Select Institution
                            </option>
                            {educationSchools.map((school, i) => {
                                return (
                                    <option key={i} value={school.accountid}>
                                        {school.name}
                                    </option>
                                );
                            })}
                            <option value='Other'>Other Institution</option>
                        </select>
                        {formik.touched.pfn_accountid && formik.errors.pfn_accountid && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_accountid}
                                </span>
                            </div>
                        )}
                        <div className='text-muted'>
                            <b>Note:</b> If the school you are looking for is not in this list please chose 'Other
                            Institution'.
                        </div>
                    </div>
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    {formik.values.pfn_accountid === 'Other' && (
                        <div className='fv-row mb-7'>
                            <label className='form-label fs-6 fw-bolder text-dark required'>Institution Name</label>
                            <input
                                {...formik.getFieldProps('pfn_otherinstitutionname')}
                                className={clsx('form-control form-control-lg form-control-solid', {
                                    'is-invalid':
                                        formik.touched.pfn_otherinstitutionname &&
                                        formik.errors.pfn_otherinstitutionname,
                                })}
                                name='pfn_otherinstitutionname'
                                autoComplete='off'
                            />
                            {formik.touched.pfn_otherinstitutionname && formik.errors.pfn_otherinstitutionname && (
                                <div className='fv-plugins-message-container'>
                                    <span className='text-danger' role='alert'>
                                        {formik.errors.pfn_otherinstitutionname}
                                    </span>
                                </div>
                            )}
                        </div>
                    )}
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    <div className='fv-row mb-7'>
                        <label className='form-label fs-6 fw-bolder text-dark required'>Graduation Year</label>
                        <select
                            {...formik.getFieldProps('pfn_year')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid': formik.touched.pfn_year && formik.errors.pfn_year,
                            })}
                            name='pfn_year'
                        >
                            <option disabled value=''>
                                Select Year
                            </option>
                            {graduationYears?.map((year, i) => {
                                return (
                                    <option key={i} value={String(year)}>
                                        {year}
                                    </option>
                                );
                            })}
                        </select>
                        {formik.touched.pfn_year && formik.errors.pfn_year && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_year}
                                </span>
                            </div>
                        )}
                    </div>
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    {formik.values.pfn_educationtypeid === '38169efa-3b8b-ea11-a811-000d3a0c8cd2' && (
                        <div className='fv-row mb-7'>
                            <label className='form-label fs-6 fw-bolder text-dark required'>Specialty</label>
                            <select
                                {...formik.getFieldProps('pfn_educationdiplomateid')}
                                className={clsx('form-control form-control-lg form-control-solid', {
                                    'is-invalid':
                                        formik.touched.pfn_educationdiplomateid &&
                                        formik.errors.pfn_educationdiplomateid,
                                })}
                                name='pfn_educationdiplomateid'
                            >
                                <option disabled value=''>
                                    Select Specialty
                                </option>
                                {educationSpecialties.map((specialty, i) => {
                                    return (
                                        <option key={i} value={specialty.pfn_educationdiplomateid}>
                                            {specialty.pfn_name}
                                        </option>
                                    );
                                })}
                            </select>
                            {formik.touched.pfn_educationdiplomateid && formik.errors.pfn_educationdiplomateid && (
                                <div className='fv-plugins-message-container'>
                                    <span className='text-danger' role='alert'>
                                        {formik.errors.pfn_educationdiplomateid}
                                    </span>
                                </div>
                            )}
                        </div>
                    )}
                    {/* End Form Row */}
                    {formErrMsg && <div className='text-danger text-center'>{formErrMsg}</div>}

                    <button
                        type='submit'
                        className='btn btn-primary mb-15'
                        disabled={formik.isSubmitting || formLoading}
                    >
                        {!formLoading && <span className='indicator-label'>Add Education</span>}
                        {formLoading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Please wait...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>

                    {educationsLoading ? (
                        <h5>Loading your current educations, please wait...</h5>
                    ) : (
                        <>
                            <div className='d-flex align-items-center mb-10'>
                                <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                                <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                            </div>
                            {renderCurrentEducations()}
                        </>
                    )}

                    <div className='d-flex align-items-center mb-10'>
                        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                    </div>

                    <div className='fv-row'>
                        <div className='d-flex justify-content-between'>
                            <Link to='/applications/registration'>
                                <button type='button' className='btn btn-primary me-3'>
                                    Back
                                </button>
                            </Link>

                            <button type='button' className='btn btn-primary me-3' onClick={handleNextClick}>
                                Next
                            </button>
                        </div>
                    </div>
                </form>
            )}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { EducationForm };

/* -------------------------------------------------------------------------- */
