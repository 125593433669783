/* --------------------------------- LINTING -------------------------------- */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

/* -------------------------------------------------------------------------- */

/* --------------------------------- IMPORTS -------------------------------- */

import { ApiResponse } from '../types';
import { apiClient } from './config';

/* -------------------------------------------------------------------------- */

/* ----------------------------------- API ---------------------------------- */

const getCountries = async () => {
    try {
        const { data } = await apiClient.get<ApiResponse<string[]>>('/misc/countries');
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getProvinces = async () => {
    try {
        const { data } = await apiClient.get<ApiResponse<string[]>>('/misc/provinces');
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getStates = async () => {
    try {
        const { data } = await apiClient.get<ApiResponse<string[]>>('/misc/states');
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

/* -------------------------------------------------------------------------- */

/* ------------------------------- API OBJECT ------------------------------- */

const miscApi = { getCountries, getProvinces, getStates };

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export default miscApi;

/* -------------------------------------------------------------------------- */
