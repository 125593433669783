/* --------------------------------- IMPORTS -------------------------------- */

import * as Yup from 'yup';
import { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import applicationApi from '../../../../../../api/applicationApi';
import clsx from 'clsx';
import { Table } from 'react-bootstrap';
import { Application, EmploymentHistory, RegAppEmploymentHistoryFormValues } from '../../../../../../types';
import MaskedInput from 'react-text-mask';
import { EditEmploymentBtn } from '../editBtns/EditEmploymentBtn';
import { DeleteEmploymentBtn } from '../deleteBtns/deleteEmploymentBtn';
import { removeEmpty } from '../../../../../../helpers';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

interface Props {
    goToNextStep: Function;
    goToPreviousStep: Function;
    application: Application;
}

/* -------------------------------------------------------------------------- */

/* ----------------------------- INITIAL VALUES ----------------------------- */

const phoneRegExp = /^\d{3}[-]\d{3}[-]\d{4}$/;

const initialValues = {
    pfn_name: '',
    pfn_businesslocation: '',
    pfn_jobtitle: '',
    pfn_employer: '',
    pfn_email: '',
    pfn_phone: '',
    pfn_fromyear: '',
    pfn_toyear: '',
    pfn_frommonth: '',
    pfn_tomonth: '',
    pfn_description: '',
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- SCHEMA --------------------------------- */

const validationSchema = Yup.object()
    .shape({
        pfn_name: Yup.string().required('Employment name is required'),
        pfn_businesslocation: Yup.string().required('Employment location is required'),
        pfn_jobtitle: Yup.string().required('Job title is required'),
        pfn_employer: Yup.string().required('Employer or supervisor name is required'),
        pfn_email: Yup.string().email('Wrong email format').required('Employer email is required'),
        pfn_phone: Yup.string()
            .matches(phoneRegExp, 'Phone number is not valid')
            .required('Employer phone number is required'),
        pfn_fromyear: Yup.string()
            .required('From year is required')
            .test('checkYearRange', 'Must be no greater than 50 years ago', async (val) => {
                return Number(val) >= new Date().getFullYear() - 50;
            }),
        pfn_toyear: Yup.string().required('To year is required'),
        pfn_frommonth: Yup.string()
            .required('From month is required')
            .test('checkMonthRange', 'Must be less than or equal to 12 months', async (val) => {
                return Number(val) > 0 && Number(val) <= 12;
            }),
        pfn_tomonth: Yup.string()
            .required('To month is required')
            .when('pfn_fromyear', {
                is: (val: string) => val,
                then: Yup.string().test('checkMonthRange', 'Must be less than or equal to 12 months', async (val) => {
                    return val === 'present' || (Number(val) > 0 && Number(val) <= 12);
                }),
            }),
        pfn_description: Yup.string().required('Description is required'),
    })
    .test({
        name: 'ToDateMustBeAfterFromDate',
        test: function (values) {
            if (values.pfn_toyear && values.pfn_tomonth && values.pfn_fromyear && values.pfn_frommonth) {
                if (values.pfn_fromyear === values.pfn_toyear) {
                    if (values.pfn_tomonth === 'present' || values.pfn_frommonth <= values.pfn_tomonth) {
                        return true;
                    } else {
                        return this.createError({
                            path: 'pfn_tomonth',
                            message: 'To date must be after from date',
                        });
                    }
                } else {
                    if (values.pfn_toyear === 'present' || values.pfn_fromyear <= values.pfn_toyear) {
                        return true;
                    } else {
                        return this.createError({
                            path: 'pfn_toyear',
                            message: 'To date must be after from date',
                        });
                    }
                }
            } else {
                return true;
            }
        },
    })
    .test({
        name: 'PresentCheck',
        test: function (values) {
            if (values.pfn_toyear && values.pfn_tomonth) {
                if (
                    (values.pfn_toyear === 'present' && values.pfn_tomonth === 'present') ||
                    (values.pfn_toyear !== 'present' && values.pfn_tomonth !== 'present')
                ) {
                    return true;
                } else {
                    return this.createError({
                        path: 'pfn_tomonth',
                        message: 'To year and month must both be present if present is selected',
                    });
                }
            } else {
                return true;
            }
        },
    });

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const EmploymentForm: FC<Props> = ({ goToNextStep, goToPreviousStep, application }) => {
    const [formLoading, setFormLoading] = useState(false);
    const [employmentsLoading, setEmploymentsLoading] = useState(true);
    const [formErrMsg, setFormErrMsg] = useState<string | null>(null);
    const [yearsRange, setYearsRange] = useState<number[]>([]);
    const [monthsRange, setMonthsRange] = useState<number[]>([]);
    const [currentEmployments, setCurrentEmployments] = useState<EmploymentHistory[]>([]);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            try {
                setFormErrMsg(null);
                setFormLoading(true);

                if (values.pfn_toyear === 'present') {
                    values.pfn_toyear = '';
                }
                if (values.pfn_tomonth === 'present') {
                    values.pfn_tomonth = '';
                }

                const response = await applicationApi.addEmploymentHistory(
                    application.pfn_applicationid,
                    removeEmpty(values) as RegAppEmploymentHistoryFormValues,
                );

                if (response) {
                    if (!response.success) {
                        if (response.message) {
                            setFormErrMsg(response.message);
                        } else {
                            setFormErrMsg('An error has occured, please try again later');
                        }
                    } else {
                        getCurrentEmployments();
                        formik.resetForm();
                    }
                } else {
                    setFormErrMsg('An error has occured, please try again later');
                }

                setFormLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setFormErrMsg(err.message);
                } else {
                    setFormErrMsg('An error has occurred, please try again later');
                }
                setFormLoading(false);
            }
        },
    });

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const newYearRange: number[] = [];
        const newMonthRange: number[] = [];

        for (let i = 0; i <= 50; i++) {
            newYearRange.push(currentYear - i);
        }

        for (let i = 1; i <= 12; i++) {
            newMonthRange.push(i);
        }

        setYearsRange(newYearRange);
        setMonthsRange(newMonthRange);

        return () => {
            setYearsRange([]);
            setMonthsRange([]);
        };
    }, []);

    useEffect(() => {
        getCurrentEmployments();

        return () => {
            setFormErrMsg(null);
            setFormLoading(false);
            setEmploymentsLoading(false);
            setCurrentEmployments([]);
        };
    }, []);

    const getCurrentEmployments = async () => {
        try {
            setEmploymentsLoading(true);
            setFormErrMsg(null);
            const response = await applicationApi.getEmploymentHistories();

            if (response) {
                if (!response.success) {
                    setFormErrMsg('An error has occurred, please try again later');
                } else {
                    if (response.data) {
                        setCurrentEmployments(response.data);
                    }
                }
            } else {
                setFormErrMsg('An error has occurred, please try again later');
            }

            setEmploymentsLoading(false);
        } catch (err) {
            if (err instanceof Error) {
                setFormErrMsg(err.message);
            } else {
                setFormErrMsg('An error has occurred, please try again later');
            }
            setEmploymentsLoading(false);
        }
    };

    const handleNextClick = async () => {
        goToNextStep();
    };

    const handleBackClick = async () => {
        goToPreviousStep();
    };

    const renderCurrentEmployments = () => {
        return (
            <div className='mb-10'>
                <div className='table-container'>
                    <h5 className='mt-5'>List of your employments:</h5>
                    <Table className='employments-table' striped bordered>
                        <thead>
                            <tr>
                                <th className='fw-bolder'>Name</th>
                                <th className='fw-bolder'>Job Title</th>
                                <th className='fw-bolder'>Location</th>
                                <th className='fw-bolder'>From</th>
                                <th className='fw-bolder'>To</th>
                                <th className='fw-bolder'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentEmployments?.map((employment, i) => {
                                return (
                                    <tr key={i}>
                                        <td className='name'>{employment.pfn_name}</td>
                                        <td className='job'>{employment.pfn_jobtitle}</td>
                                        <td className='location'>{employment.pfn_businesslocation}</td>
                                        <td className='from'>{employment.pfn_fromyear}</td>
                                        <td className='to'>{employment.pfn_toyear || 'Present'}</td>
                                        <td className='action'>
                                            {new Date(employment.createdon) > new Date(Date.now() - 604800000) && (
                                                <>
                                                    <EditEmploymentBtn
                                                        employment={employment}
                                                        application={application}
                                                        modalName={`kt_modal_edit_employment_${i}`}
                                                        getCurrentEmployments={getCurrentEmployments}
                                                        yearsRange={yearsRange}
                                                        monthsRange={monthsRange}
                                                    />
                                                    <DeleteEmploymentBtn
                                                        employment={employment}
                                                        getCurrentEmployments={getCurrentEmployments}
                                                        modalName={`kt_modal_delete_employment_${i}`}
                                                    />
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    };

    return (
        <>
            <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='addEmploymentForm'>
                {/* Start Form Row */}
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Name of Place of Employment</label>

                    <input
                        {...formik.getFieldProps('pfn_name')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_name && formik.errors.pfn_name,
                        })}
                        name='pfn_name'
                        autoComplete='off'
                    />

                    {formik.touched.pfn_name && formik.errors.pfn_name && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_name}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>
                        Location of Place of Employment
                    </label>

                    <input
                        {...formik.getFieldProps('pfn_businesslocation')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_businesslocation && formik.errors.pfn_businesslocation,
                        })}
                        name='pfn_businesslocation'
                        autoComplete='off'
                    />

                    {formik.touched.pfn_businesslocation && formik.errors.pfn_businesslocation && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_businesslocation}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Job Title</label>

                    <input
                        {...formik.getFieldProps('pfn_jobtitle')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_jobtitle && formik.errors.pfn_jobtitle,
                        })}
                        name='pfn_jobtitle'
                        autoComplete='off'
                    />

                    {formik.touched.pfn_jobtitle && formik.errors.pfn_jobtitle && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_jobtitle}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Supervisor/Employer Name</label>

                    <input
                        {...formik.getFieldProps('pfn_employer')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_employer && formik.errors.pfn_employer,
                        })}
                        name='pfn_employer'
                        autoComplete='off'
                    />

                    {formik.touched.pfn_employer && formik.errors.pfn_employer && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_employer}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Supervisor/Employer Email</label>
                    <input
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('pfn_email')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_email && formik.errors.pfn_email,
                        })}
                    />
                    {formik.touched.pfn_email && formik.errors.pfn_email && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_email}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6 required'>
                        Supervisor/Employer Telephone
                    </label>
                    <MaskedInput
                        mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        {...formik.getFieldProps('pfn_phone')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_phone && formik.errors.pfn_phone,
                        })}
                    />
                    {formik.touched.pfn_phone && formik.errors.pfn_phone && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_phone}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>From Year</label>
                    <select
                        {...formik.getFieldProps('pfn_fromyear')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_fromyear && formik.errors.pfn_fromyear,
                        })}
                        name='pfn_fromyear'
                    >
                        <option disabled value=''>
                            Select Year
                        </option>
                        {yearsRange?.map((year, i) => {
                            return (
                                <option key={i} value={String(year)}>
                                    {year}
                                </option>
                            );
                        })}
                    </select>
                    {formik.touched.pfn_fromyear && formik.errors.pfn_fromyear && (
                        <div className='fv-plugins-message-container'>
                            <span className='text-danger' role='alert'>
                                {formik.errors.pfn_fromyear}
                            </span>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>From Month</label>
                    <select
                        {...formik.getFieldProps('pfn_frommonth')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_frommonth && formik.errors.pfn_frommonth,
                        })}
                        name='pfn_frommonth'
                    >
                        <option disabled value=''>
                            Select Month
                        </option>
                        {monthsRange?.map((month, i) => {
                            return (
                                <option key={i} value={String(month)}>
                                    {month}
                                </option>
                            );
                        })}
                    </select>
                    {formik.touched.pfn_frommonth && formik.errors.pfn_frommonth && (
                        <div className='fv-plugins-message-container'>
                            <span className='text-danger' role='alert'>
                                {formik.errors.pfn_frommonth}
                            </span>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>To Year</label>
                    <select
                        {...formik.getFieldProps('pfn_toyear')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_toyear && formik.errors.pfn_toyear,
                        })}
                        name='pfn_toyear'
                    >
                        <option disabled value=''>
                            Select Year
                        </option>
                        <option key='present' value='present'>
                            Present
                        </option>
                        {yearsRange?.map((year, i) => {
                            return (
                                <option key={i} value={String(year)}>
                                    {year}
                                </option>
                            );
                        })}
                    </select>
                    {formik.touched.pfn_toyear && formik.errors.pfn_toyear && (
                        <div className='fv-plugins-message-container'>
                            <span className='text-danger' role='alert'>
                                {formik.errors.pfn_toyear}
                            </span>
                        </div>
                    )}
                </div>
                {/* End Form Row */}

                {/* Start Form Row */}
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>To Month</label>
                    <select
                        {...formik.getFieldProps('pfn_tomonth')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_tomonth && formik.errors.pfn_tomonth,
                        })}
                        name='pfn_tomonth'
                    >
                        <option disabled value=''>
                            Select Month
                        </option>
                        <option key='present' value='present'>
                            Present
                        </option>
                        {monthsRange?.map((month, i) => {
                            return (
                                <option key={i} value={String(month)}>
                                    {month}
                                </option>
                            );
                        })}
                    </select>
                    {formik.touched.pfn_tomonth && formik.errors.pfn_tomonth && (
                        <div className='fv-plugins-message-container'>
                            <span className='text-danger' role='alert'>
                                {formik.errors.pfn_tomonth}
                            </span>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-7'>
                    <label className='form-label fw-bolder text-dark fs-6 required'>Description of Employment</label>

                    <textarea
                        autoComplete='off'
                        rows={5}
                        {...formik.getFieldProps('pfn_description')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_description && formik.errors.pfn_description,
                        })}
                    />
                    {formik.touched.pfn_description && formik.errors.pfn_description && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_description}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {formErrMsg && <div className='text-danger text-center'>{formErrMsg}</div>}

                <button type='submit' className='btn btn-primary mb-15' disabled={formik.isSubmitting || formLoading}>
                    {!formLoading && <span className='indicator-label'>Add Employment</span>}
                    {formLoading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>

                {employmentsLoading ? (
                    <h5>Loading your current employments, please wait...</h5>
                ) : (
                    <>
                        <div className='d-flex align-items-center mb-10'>
                            <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                            <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                        </div>
                        {renderCurrentEmployments()}
                    </>
                )}

                <div className='d-flex align-items-center mb-10'>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                </div>

                <div className='fv-row'>
                    <div className='d-flex justify-content-between'>
                        <button type='button' className='btn btn-primary me-3' onClick={handleBackClick}>
                            Back
                        </button>

                        <button type='button' className='btn btn-primary me-3' onClick={handleNextClick}>
                            Next
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { EmploymentForm };

/* -------------------------------------------------------------------------- */
