/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useState } from 'react';
import { KTSVG } from '../../../../../_metronic/helpers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { updatePersonalInfo } from '../../../../redux/slices/UserSlice';
import { closeModalById } from '../../../../helpers';

/* -------------------------------------------------------------------------- */

/* ------------------------------- FORM SCHEMA ------------------------------ */

const editPersonalSchema = Yup.object().shape({
    // pfn_salutation: Yup.string().required('Salutation is required'),
    // gendercode: Yup.string().required('Gender is required'),
    pfn_preferredidentifier: Yup.string(),
    nickname: Yup.string(),
});

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const ContactInfoPersonal: FC = () => {
    const user = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);

    const initialValues = {
        // pfn_salutation: user.personal.salutation.formatted ? user.personal.salutation.formatted : '',
        // gendercode: user.personal.gender.formatted ? user.personal.gender.formatted : '',
        pfn_preferredidentifier: user.personal.preferredIdentifier.formatted
            ? user.personal.preferredIdentifier.formatted
            : '',
        nickname: user.personal.preferredName ? user.personal.preferredName : '',
    };

    const formik = useFormik({
        initialValues,
        validationSchema: editPersonalSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                setErrMsg(null);
                setLoading(true);
                const response = await dispatch(updatePersonalInfo(values));
                if (response) {
                    if (!response.payload?.success) {
                        if (response.payload?.message) {
                            setErrMsg(response.payload.message);
                        } else {
                            setErrMsg('An error has occured, please try again later');
                        }
                    } else {
                        closeModalById('kt_modal_edit_personal');
                    }
                } else {
                    setErrMsg('An error has occured, please try again later');
                }
                setLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
                setLoading(false);
            }
        },
    });

    const editPersonalModal = () => {
        return (
            <div className='modal fade' tabIndex={-1} id='kt_modal_edit_personal'>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Edit Personal Information</h5>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
                            </div>
                        </div>
                        <div className='modal-body'>{editPersonalForm()}</div>
                    </div>
                </div>
            </div>
        );
    };

    const editPersonalForm = () => {
        return (
            <form className='form w-100' onSubmit={formik.handleSubmit} id='edit_personal_form'>
                {/* Start Form Row */}
                {/* <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Salutation</label>
                    <select
                        {...formik.getFieldProps('pfn_salutation')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_salutation && formik.errors.pfn_salutation,
                        })}
                        name='pfn_salutation'
                    >
                        <option disabled value=''>
                            Select Salutation
                        </option>
                        <option value='Dr.'>Dr.</option>
                        <option value='Mr.'>Mr.</option>
                        <option value='Mrs.'>Mrs.</option>
                        <option value='Ms.'>Ms.</option>
                    </select>
                    {formik.touched.pfn_salutation && formik.errors.pfn_salutation && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                    {formik.errors.pfn_salutation}
                                </span>
                            </div>
                        </div>
                    )}
                </div> */}
                {/* End Form Row */}
                {/* Start Form Row */}
                {/* <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Gender</label>
                    <select
                        {...formik.getFieldProps('gendercode')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.gendercode && formik.errors.gendercode,
                        })}
                        name='gendercode'
                    >
                        <option disabled value=''>
                            Select Gender
                        </option>
                        <option value='Male'>Male</option>
                        <option value='Female'>Female</option>
                    </select>
                    {formik.touched.gendercode && formik.errors.gendercode && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                    {formik.errors.gendercode}
                                </span>
                            </div>
                        </div>
                    )}
                </div> */}
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Preferred Identifier</label>
                    <select
                        {...formik.getFieldProps('pfn_preferredidentifier')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid':
                                formik.touched.pfn_preferredidentifier && formik.errors.pfn_preferredidentifier,
                        })}
                        name='pfn_preferredidentifier'
                    >
                        <option disabled value=''>
                            Select Identifier
                        </option>
                        <option value='---'>None</option>
                        <option value='He/him'>He/Him</option>
                        <option value='She/her'>She/Her</option>
                        <option value='They/them'>They/Them</option>
                    </select>
                    {formik.touched.pfn_preferredidentifier && formik.errors.pfn_preferredidentifier && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                    {formik.errors.pfn_preferredidentifier}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Preferred Name</label>
                    <input
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('nickname')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.nickname && formik.errors.nickname,
                        })}
                    />
                    {formik.touched.nickname && formik.errors.nickname && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                    {formik.errors.nickname}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}

                <div className='d-flex align-items-center mb-10'>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                </div>

                <div className='fv-row'>
                    <div className='d-flex justify-content-end'>
                        <button type='button' className='btn btn-light me-3' data-bs-dismiss='modal'>
                            Cancel
                        </button>

                        <button type='submit' className='btn btn-primary' disabled={formik.isSubmitting || loading}>
                            {!loading && <span className='indicator-label'>Submit</span>}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </div>
                {errMsg && <div className='text-danger text-center mt-7'>{errMsg}</div>}
            </form>
        );
    };

    return (
        <div className='card card-xl mb-5 mb-xl-8'>
            <div className='card-header border-0'>
                <h3 className='card-title fw-bolder text-dark'>Personal</h3>
            </div>

            <div className='card-body pt-0'>
                {/* <div className='d-flex align-items-center bg-light rounded p-5 mb-7'>
                    <span className='bullet bullet-vertical h-40px bg-primary me-10'></span>

                    <div className='flex-grow-1 me-2'>
                        <div className='fw-bolder text-gray-800 fs-6'>Salutation</div>
                        <span className='text-muted fw-bold d-block'>{user.personal.salutation.formatted}</span>
                    </div>
                </div> */}
                {/* <div className='d-flex align-items-center bg-light rounded p-5 mb-7'>
                    <span className='bullet bullet-vertical h-40px bg-primary me-10'></span>

                    <div className='flex-grow-1 me-2'>
                        <div className='fw-bolder text-gray-800 fs-6'>Gender</div>
                        <span className='text-muted fw-bold d-block'>{user.personal.gender.formatted}</span>
                    </div>
                </div> */}
                <div className='d-flex align-items-center bg-light rounded p-5 mb-7'>
                    <span className='bullet bullet-vertical h-40px bg-primary me-10'></span>

                    <div className='flex-grow-1 me-2'>
                        <div className='fw-bolder text-gray-800 fs-6'>Preferred Identifier</div>
                        <span className='text-muted fw-bold d-block'>
                            {user.personal.preferredIdentifier.formatted || '---'}
                        </span>
                    </div>
                </div>
                <div className='d-flex align-items-center bg-light rounded p-5 mb-7'>
                    <span className='bullet bullet-vertical h-40px bg-primary me-10'></span>

                    <div className='flex-grow-1 me-2'>
                        <div className='fw-bolder text-gray-800 fs-6'>Preferred Name</div>
                        <span className='text-muted fw-bold d-block'>{user.personal.preferredName || '---'}</span>
                    </div>
                </div>
                <div className='mb-7 text-gray-800'>
                    <span className='fw-bolder'>Note:</span> Preferred name will appear in the online registry, along
                    with your registered name.
                </div>

                <button
                    className='btn btn-sm btn-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_edit_personal'
                    onClick={() => {
                        formik.resetForm();
                        setErrMsg(null);
                    }}
                >
                    Edit
                </button>
                {editPersonalModal()}
            </div>
        </div>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { ContactInfoPersonal };

/* -------------------------------------------------------------------------- */
