import { FacilityAssessment } from '../../../../../types';

/**
 * Generates the PDF content from a facility assessment.
 * @param {FacilityAssessment} assessment.
 * @returns {{ header: string[]; data: Array<{ category: string; species: string }> }} - An object containing headers and data for the PDF table.
 */
export const generateVeterinaryServicesTableContentForPDF = (assessment: FacilityAssessment) => {
    if (!assessment.facilityVetServices?.length) {
        return {
            tableHeader: ['CATEGORY', 'SUBTYPE'],
            data: [{ category: 'None', subtype: 'None' }],
        };
    }

    const data = assessment.facilityVetServices.flatMap((entry) => {
        const category = entry._pfn_servicecategoryid_value_Formatted || 'Unknown';
        if (entry.pfn_veterinaryservices_servicesubtype && entry.pfn_veterinaryservices_servicesubtype.length > 0) {
            return entry.pfn_veterinaryservices_servicesubtype.map((spec) => ({
                category,
                subtype: spec.pfn_name,
            }));
        } else {
            return [{ category, subtype: 'N/A' }];
        }
    });

    return {
        tableHeader: ['CATEGORY', 'SUBTYPE'],
        data,
    };
};