/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PageTitle } from '../../../../../_metronic/layout/core';
import { AssessmentYear, FacilityAssessment } from '../../../../types';
import facilityAssessmentApi from '../../../../api/facilityAssessmentsApi';
import facilityApi from '../../../../api/facilityApi';
import { useAppSelector } from '../../../../redux/hooks';
import { generateAnnualDeclarationPDF } from './PDFContent/generateAnnualDeclarationPDF';
import GenerateGeneralInfo from './PDFContent/generateGeneralInfo';

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const FacilityAssessmentsPage: FC = () => {
    const user = useAppSelector((state) => state.user);
    const [loadingAssessments, setLoadingAssessments] = useState(true);
    const [loadingCreateAssessment, setLoadingCreateAssessment] = useState(false);
    const [loadingAssessmentYear, setLoadingAssessmentYear] = useState(false);
    const [errMsgAssessments, setErrMsgAssessments] = useState<string | null>(null);
    const [errMsgAssessmentYear, setErrMsgAssessmentYear] = useState<string | null>(null);
    const [errMsgCreateAssessment, setErrMsgCreateAssessment] = useState<string | null>(null);
    const [assessments, setAssessments] = useState<FacilityAssessment[]>([]);
    const [currentAssessmentYear, setCurrentAssessmentYear] = useState<AssessmentYear | null>(null);
    const { facilityId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const getCurrentAssessmentYearAsync = async () => {
            try {
                const response = await facilityAssessmentApi.getCurrentAssessmentYear();
                if (response) {
                    if (!response.success) {
                        setErrMsgAssessmentYear(response.message || 'An error occurred, please try again later');
                    } else if (response.data) {
                        setCurrentAssessmentYear(response.data);
                    } else {
                        setErrMsgAssessmentYear('No assessment year found');
                    }
                } else {
                    setErrMsgAssessmentYear('An error has occurred, please try again later');
                }
            } catch (err) {
                setErrMsgAssessmentYear(err instanceof Error ? err.message : 'An error has occurred, please try again later');
            }
            setLoadingAssessmentYear(false);
        };

        const getAssessmentsAsync = async () => {
            try {
                if (facilityId) {
                    const response1 = await facilityApi.getFacilityById(facilityId);
                    if (response1) {
                        if (!response1.success) {
                            setErrMsgAssessments(response1.message || 'An error occurred, please try again later');
                        } else if (response1.data && response1.data._pfn_contactid_value === user.id) {
                            const response2 = await facilityAssessmentApi.getFacilityAssessmentsByFacilityId(facilityId);
                            if (response2) {
                                if (!response2.success) {
                                    setErrMsgAssessments(response2.message || 'An error occurred, please try again later');
                                } else if (response2.data) {
                                    setAssessments(response2.data);
                                }
                            } else {
                                setErrMsgAssessments('An error has occurred, please try again later');
                            }
                        } else {
                            setErrMsgAssessments('You are not the designated Vet for this facility');
                        }
                    } else {
                        setErrMsgAssessments('An error has occurred, please try again later');
                    }
                } else {
                    setErrMsgAssessments('An error has occurred, please try again later');
                }
            } catch (err) {
                setErrMsgAssessments(err instanceof Error ? err.message : 'An error has occurred, please try again later');
            }
            setLoadingAssessments(false);
        };

        getAssessmentsAsync();
        getCurrentAssessmentYearAsync();

        return () => {
            setLoadingAssessments(false);
            setErrMsgAssessments(null);
            setAssessments([]);
            setLoadingAssessmentYear(false);
            setErrMsgAssessmentYear(null);
            setCurrentAssessmentYear(null);
        };
    }, [facilityId, user.id]);

    const createNewAssessment = async () => {
        try {
            if (facilityId) {
                setLoadingCreateAssessment(true);
                const response = await facilityAssessmentApi.getFacilityAssessmentsByFacilityId(facilityId);
                if (response) {
                    if (!response.success) {
                        setErrMsgCreateAssessment(response.message || 'An error occurred, please try again later');
                    } else if (currentAssessmentYear && currentAssessmentYear.pfn_assessmentyearid) {
                        const response2 = await facilityAssessmentApi.createAssessment(facilityId, currentAssessmentYear.pfn_assessmentyearid);
                        if (!response2.success) {
                            setErrMsgCreateAssessment(response2.message || 'An error occurred, please try again later');
                        } else if (response2.data) {
                            navigate(`/facilities/assessment/${facilityId}/${response2.data.pfn_facilityselfassessmentid}`);
                        }
                    } else {
                        setErrMsgCreateAssessment('Could not find current assessment year');
                    }
                } else {
                    setErrMsgCreateAssessment('An error has occurred, please try again later');
                }
                setLoadingCreateAssessment(false);
            } else {
                setErrMsgCreateAssessment('An error has occurred, no facility id found');
            }
        } catch (err) {
            setErrMsgCreateAssessment(err instanceof Error ? err.message : 'An error has occurred, please try again later');
            setLoadingCreateAssessment(false);
        }
    };

    const checkIfNeedToStartNewAssessment = () => {
        return !assessments || assessments.length === 0 || assessments[0]._pfn_assessmentyear_value !== currentAssessmentYear?.pfn_assessmentyearid;
    };

    const GenerateMyAssessments: FC = () => {
        return (
            <div className='card card-xl mb-5 mb-xl-8'>
                <div className='card-header border-0'>
                    <h3 className='card-title fw-bolder text-dark'>My Annual Declarations</h3>
                </div>
                <div className='card-body pt-0'>
                    {assessments.length > 0 ? (
                        <>
                            <div className='table-container'>
                                <Table className='assessments-table' striped bordered>
                                    <thead>
                                        <tr>
                                            <th className='date fw-bolder ps-3'>Assessment Year</th>
                                            <th className='date fw-bolder'>Started Date</th>
                                            <th className='date fw-bolder'>Required Date</th>
                                            <th className='fw-bolder'>Completed</th>
                                            <th className='fw-bolder'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {assessments.map((assessment, i) => {
                                            const isWithinDateRange = assessment.pfn_AssessmentYear?.pfn_availableto &&
                                                new Date() < new Date(assessment.pfn_AssessmentYear.pfn_availableto);
                                            const isFormCompleted = assessment.pfn_formcompleted === true;

                                            return (
                                                <tr key={i}>
                                                    <td className='date ps-3'>{assessment._pfn_assessmentyear_value_Formatted}</td>
                                                    <td className='date'>{assessment.pfn_startdate}</td>
                                                    <td className='date'>{assessment.pfn_AssessmentYear?.pfn_expirydate?.split('T')[0]}</td>
                                                    <td className='completed'>{isFormCompleted ? 'Yes' : 'No'}</td>
                                                    <td className='action'>
                                                        {isWithinDateRange && !isFormCompleted ? (
                                                            <Link to={`/facilities/assessment/${facilityId}/${assessment.pfn_facilityselfassessmentid}`}>
                                                                <button type='button' className='btn btn-sm btn-primary my-1'>Continue</button>
                                                            </Link>
                                                        ) : isWithinDateRange && isFormCompleted ? ( // For now, only available for current year.
                                                            <a href="#" onClick={() => generateAnnualDeclarationPDF(assessment)} className="text-decoration-underline">Download PDF Report</a>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                            {checkIfNeedToStartNewAssessment() && (
                                <div className='mt-10'>
                                    <button type='button' className='btn btn-primary my-1' onClick={createNewAssessment} disabled={loadingCreateAssessment}>
                                        {!loadingCreateAssessment ? <span className='indicator-label'>Start {currentAssessmentYear?.pfn_name} Annual Declaration</span> : <span className='indicator-progress' style={{ display: 'block' }}>Please wait...<span className='spinner-border spinner-border-sm align-middle ms-2'></span></span>}
                                    </button>
                                    {errMsgCreateAssessment && <div>{errMsgCreateAssessment}</div>}
                                </div>
                            )}
                        </>
                    ) : (
                        <div>
                            <div>You have not yet started an Annual Declaration. Please press the button below to begin the process. You can save and resume the Annual Declaration here at any point if you do not finish the entire Annual Declaration.</div>
                            <div className='mt-10'>
                                <button type='button' className='btn btn-primary my-1' onClick={createNewAssessment} disabled={loadingCreateAssessment}>
                                    {!loadingCreateAssessment ? <span className='indicator-label'>Start {currentAssessmentYear?.pfn_name} Annual Declaration</span> : <span className='indicator-progress' style={{ display: 'block' }}>Please wait...<span className='spinner-border spinner-border-sm align-middle ms-2'></span></span>}
                                </button>
                                {errMsgCreateAssessment && <div>{errMsgCreateAssessment}</div>}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <>
            <GenerateGeneralInfo />
            {loadingAssessments || loadingAssessmentYear ? (
                <p>Retrieving Annual Declarations, please wait...</p>
            ) : errMsgAssessments ? (
                <p>{errMsgAssessments}</p>
            ) : errMsgAssessmentYear ? (
                <p>{errMsgAssessmentYear}</p>
            ) : (
                <GenerateMyAssessments />
            )}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- WRAPPER -------------------------------- */

const FacilityAssessmentsWrapper: FC = () => {
    const { facilityName } = useParams();
    const title = `${facilityName}`;

    return (
        <>
            <PageTitle breadcrumbs={[]}>{title}</PageTitle>
            <FacilityAssessmentsPage />
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { FacilityAssessmentsWrapper };

/* -------------------------------------------------------------------------- */