/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PageTitle } from '../../../../_metronic/layout/core';
import applicationApi from '../../../api/applicationApi';
import { convertIsoDateToString } from '../../../helpers';
import { Application } from '../../../types';
import { useAppSelector } from '../../../redux/hooks';

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const ApplicationHistoryPage: FC = () => {
    const user = useAppSelector((state) => state.user);
    const [loading, setLoading] = useState(true);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const [applications, setApplications] = useState<Application[]>([]);

    useEffect(() => {
        const getApplicationsAsync = async () => {
            try {
                const response = await applicationApi.getAppsByUserId();

                if (response) {
                    if (!response.success) {
                        if (response.message) {
                            setErrMsg(response.message);
                        } else {
                            setErrMsg('An error occured, please try again later');
                        }
                    } else {
                        if (response.data) {
                            setApplications(response.data);
                        } else {
                            setErrMsg('No data found');
                        }
                    }
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
            }
            setLoading(false);
        };

        getApplicationsAsync();

        return () => {
            setLoading(false);
            setErrMsg(null);
            setApplications([]);
        };
    }, []);

    return (
        <>
            <div className='card card-xl mb-5 mb-xl-8'>
                <div className='card-header border-0'>
                    <h3 className='card-title fw-bolder text-dark'>General Information</h3>
                </div>

                <div className='card-body pt-0'>
                    Applications for Registration and Renewal prior to November 2019 are not reflected in this account.
                </div>
            </div>
            <div className='card card-xl-stretch mb-5 mb-xl-8'>
                {/* begin::Header */}
                <div className='card-header border-0'>
                    <h3 className='card-title fw-bolder text-dark'>Registration Application History</h3>
                    {/* <div className='card-toolbar'>
                    <button
                        type='button'
                        className='btn btn-sm btn-icon btn-color-primary btn-active-light'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                    >
                        <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                    </button>
                    <DropdownCustom6 />
                </div> */}
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body pt-0'>
                    {/* begin::Item */}
                    {loading ? (
                        <div>Applications loading, please wait...</div>
                    ) : errMsg ? (
                        <div className='text-danger'>{errMsg}</div>
                    ) : applications?.some((app) => app.pfn_purpose_Formatted === 'Registration') ? (
                        <div className='table-container'>
                            <Table className='applications-table' striped bordered>
                                <thead>
                                    <tr>
                                        <th className='date fw-bolder ps-3'>Registration Year</th>
                                        <th className='date fw-bolder'>Created Date</th>
                                        {/* <th className='fw-bolder'>Received Date</th> */}
                                        <th className='fw-bolder'>Type</th>
                                        <th className='fw-bolder'>Status</th>
                                        {/* <th className='fw-bolder'>Details Completed</th> */}
                                        <th className='fw-bolder'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {applications.map((application, i) => {
                                        if (application.pfn_purpose_Formatted === 'Registration') {
                                            return (
                                                <tr key={i}>
                                                    <td className='date ps-3'>
                                                        {application._pfn_renewalyearid_value_Formatted}
                                                    </td>
                                                    <td className='date'>
                                                        {convertIsoDateToString(application?.createdon)}
                                                    </td>
                                                    {/* <td className='date'>
                                                    {convertIsoDateToString(application?.pfn_receiveddate)}
                                                </td> */}
                                                    <td className='type'>
                                                        {application._pfn_applicationtypeid_value_Formatted}
                                                    </td>
                                                    <td className='status'>{application.statuscode_Formatted}</td>
                                                    {/* <td className='details'>{application.pfn_fomcomplete_Formatted}</td> */}
                                                    <td className='action'>
                                                        {application.statuscode_Formatted === 'Active' ||
                                                        application.statuscode_Formatted === 'Ready to be finalized' ? (
                                                            <>
                                                                {application.pfn_fomcomplete ? (
                                                                    <>
                                                                        <Link
                                                                            to={`/applications/registration/status/${application.pfn_applicationid}`}
                                                                        >
                                                                            <button
                                                                                type='button'
                                                                                className='btn btn-sm btn-primary my-1 me-2'
                                                                            >
                                                                                Check Status
                                                                            </button>
                                                                        </Link>
                                                                        <Link
                                                                            to={`/applications/registration/${application.pfn_applicationid}`}
                                                                        >
                                                                            <button
                                                                                type='button'
                                                                                className='btn btn-sm btn-primary my-1'
                                                                            >
                                                                                Print Summary
                                                                            </button>
                                                                        </Link>
                                                                    </>
                                                                ) : (
                                                                    <Link
                                                                        to={`/applications/registration/${application.pfn_applicationid}`}
                                                                    >
                                                                        <button
                                                                            type='button'
                                                                            className='btn btn-sm btn-primary my-1'
                                                                        >
                                                                            Continue Application
                                                                        </button>
                                                                    </Link>
                                                                )}
                                                            </>
                                                        ) : application.statuscode_Formatted === 'Granted' ? (
                                                            <Link
                                                                to={`/applications/registration/status/${application.pfn_applicationid}`}
                                                            >
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-sm btn-primary my-1 me-2'
                                                                >
                                                                    View Summary
                                                                </button>
                                                            </Link>
                                                        ) : (
                                                            <div>---</div>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    ) : user.statusHistory.subcategory.formatted === 'Applicant' ? (
                        <div className='note-card d-flex align-items-center bg-light rounded p-5 mb-7'>
                            <div className='note-card-inner flex-grow-1 me-2'>
                                <div className='text-gray-700 fw-bold mb-5'>
                                    You have not yet started any applications. Please use the buttons below to view more
                                    information about the application process for registration applications.
                                </div>
                                <Link to={'/applications/registration'} className='me-3'>
                                    <button type='button' className='btn btn-primary my-3'>
                                        Registration Applications
                                    </button>
                                </Link>
                                <a
                                    href='https://www.cvbc.ca/registration/registration-application/'
                                    target='_blank'
                                    rel='noreferrer'
                                    className='btn btn-primary'
                                >
                                    More info
                                </a>
                            </div>
                        </div>
                    ) : (
                        <div className='note-card d-flex align-items-center bg-light rounded p-5 mb-7'>
                            <div className='note-card-inner flex-grow-1 me-2'>
                                <div className='text-gray-700 fw-bold'>No applications available.</div>
                                {/* <Link to={'/applications/registration'} className='me-3'>
                                <button type='button' className='btn btn-primary my-3'>
                                    Registration Applications
                                </button>
                            </Link> */}
                                {/* <a
                                href='https://www.cvbc.ca/registration/registration-application/'
                                target='_blank'
                                rel='noreferrer'
                                className='btn btn-primary'
                            >
                                More info
                            </a> */}
                            </div>
                        </div>
                    )}

                    {/* end::Item */}
                </div>
                {/* end::Body */}
            </div>
            {user.statusHistory.subcategory.formatted !== 'Applicant' ? (
                <div className='card card-xl-stretch mb-5 mb-xl-8'>
                    {/* begin::Header */}
                    <div className='card-header border-0'>
                        <h3 className='card-title fw-bolder text-dark'>Renewal Application History</h3>
                        {/* <div className='card-toolbar'>
                    <button
                        type='button'
                        className='btn btn-sm btn-icon btn-color-primary btn-active-light'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                    >
                        <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                    </button>
                    <DropdownCustom6 />
                </div> */}
                    </div>
                    {/* end::Header */}
                    {/* begin::Body */}
                    <div className='card-body pt-0'>
                        {/* begin::Item */}
                        {loading ? (
                            <div>Applications loading, please wait...</div>
                        ) : errMsg ? (
                            <div className='text-danger'>{errMsg}</div>
                        ) : applications?.some((app) => app.pfn_purpose_Formatted === 'Renewal') ? (
                            <div className='table-container'>
                                <Table className='applications-table' striped bordered>
                                    <thead>
                                        <tr>
                                            <th className='date fw-bolder ps-3'>Renewal Year</th>
                                            <th className='date fw-bolder'>Created Date</th>
                                            {/* <th className='fw-bolder'>Received Date</th> */}
                                            <th className='fw-bolder'>Type</th>
                                            <th className='fw-bolder'>Status</th>
                                            {/* <th className='fw-bolder'>Details Completed</th> */}
                                            <th className='fw-bolder'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {applications.map((application, i) => {
                                            if (application.pfn_purpose_Formatted === 'Renewal') {
                                                return (
                                                    <tr key={i}>
                                                        <td className='date ps-3'>
                                                            {application._pfn_renewalyearid_value_Formatted}
                                                        </td>
                                                        <td className='date'>
                                                            {convertIsoDateToString(application?.createdon)}
                                                        </td>
                                                        {/* <td className='date'>
                                                    {convertIsoDateToString(application?.pfn_receiveddate)}
                                                </td> */}
                                                        <td className='type'>
                                                            {application._pfn_applicationtypeid_value_Formatted}
                                                        </td>
                                                        <td className='status'>{application.statuscode_Formatted}</td>
                                                        {/* <td className='details'>
                                                            {application.pfn_fomcomplete_Formatted}
                                                        </td> */}
                                                        <td className='action'>
                                                            {application.statuscode_Formatted === 'Active' ? (
                                                                <>
                                                                    {application.pfn_fomcomplete ? (
                                                                        <Link
                                                                            to={`/applications/renewal/status/${application.pfn_applicationid}`}
                                                                        >
                                                                            <button
                                                                                type='button'
                                                                                className='btn btn-sm btn-primary my-1'
                                                                            >
                                                                                Check Status
                                                                            </button>
                                                                        </Link>
                                                                    ) : (
                                                                        <Link
                                                                            to={`/applications/renewal/${application.pfn_applicationid}`}
                                                                        >
                                                                            <button
                                                                                type='button'
                                                                                className='btn btn-sm btn-primary my-1'
                                                                            >
                                                                                Continue Application
                                                                            </button>
                                                                        </Link>
                                                                    )}
                                                                </>
                                                            ) : application.statuscode_Formatted === 'Granted' ? (
                                                                <Link
                                                                    to={`/applications/renewal/status/${application.pfn_applicationid}`}
                                                                >
                                                                    <button
                                                                        type='button'
                                                                        className='btn btn-sm btn-primary my-1 me-2'
                                                                    >
                                                                        View Summary
                                                                    </button>
                                                                </Link>
                                                            ) : (
                                                                <div>---</div>
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        ) : (
                            <div className='note-card d-flex align-items-center bg-light rounded p-5 mb-7'>
                                <div className='note-card-inner flex-grow-1 me-2'>
                                    <div className='text-gray-700 fw-bold mb-5'>
                                        You have not yet started any applications. Please use the navigation below to
                                        view more information about the application process for renewal applications.
                                    </div>
                                    {user.renewalYear && (
                                        <Link to={'/applications/renewal'}>
                                            <button type='button' className='btn btn-primary my-3 me-3'>
                                                Renewal Applications
                                            </button>
                                        </Link>
                                    )}
                                    <a
                                        href='https://www.cvbc.ca/registration/registration-application/'
                                        target='_blank'
                                        rel='noreferrer'
                                        className='btn btn-primary'
                                    >
                                        More info
                                    </a>
                                </div>
                            </div>
                        )}

                        {/* end::Item */}
                    </div>
                    {/* end::Body */}
                </div>
            ) : (
                ''
            )}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- WRAPPER -------------------------------- */

const ApplicationHistoryWrapper: FC = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Application History</PageTitle>
            <ApplicationHistoryPage />
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { ApplicationHistoryWrapper };

/* -------------------------------------------------------------------------- */
