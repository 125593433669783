/* --------------------------------- IMPORTS -------------------------------- */

import * as Yup from 'yup';
import { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import applicationApi from '../../../../../../api/applicationApi';
import clsx from 'clsx';
import { Application, Question, QuestionsFormConvertedValues, QuestionsFormValues } from '../../../../../../types';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

interface Props {
    goToNextStep: Function;
    goToPreviousStep: Function;
    application: Application;
}

/* -------------------------------------------------------------------------- */

/* --------------------------------- SCHEMA --------------------------------- */

const validationSchema = Yup.object().shape({
    q1: Yup.object().shape({
        pfn_answer: Yup.string().required('This field is required'),
        pfn_explanation: Yup.string().when('pfn_answer', {
            is: 'Yes',
            then: Yup.string().required("Explanation is required when selection 'Yes'"),
        }),
    }),
    q2: Yup.object().shape({
        pfn_answer: Yup.string().required('This field is required'),
        pfn_explanation: Yup.string().when('pfn_answer', {
            is: 'Yes',
            then: Yup.string().required("Explanation is required when selection 'Yes'"),
        }),
    }),
    q3: Yup.object().shape({
        pfn_answer: Yup.string().required('This field is required'),
        pfn_explanation: Yup.string().when('pfn_answer', {
            is: 'Yes',
            then: Yup.string().required("Explanation is required when selection 'Yes'"),
        }),
    }),
    q4: Yup.object().shape({
        pfn_answer: Yup.string().required('This field is required'),
        pfn_explanation: Yup.string().when('pfn_answer', {
            is: 'Yes',
            then: Yup.string().required("Explanation is required when selection 'Yes'"),
        }),
    }),
    q5: Yup.object().shape({
        pfn_answer: Yup.string().required('This field is required'),
        pfn_explanation: Yup.string().when('pfn_answer', {
            is: 'Yes',
            then: Yup.string().required("Explanation is required when selection 'Yes'"),
        }),
    }),
    q6: Yup.object().shape({
        pfn_answer: Yup.string().required('This field is required'),
        pfn_explanation: Yup.string().when('pfn_answer', {
            is: 'Yes',
            then: Yup.string().required("Explanation is required when selection 'Yes'"),
        }),
    }),
});

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const QuestionsForm: FC<Props> = ({ goToNextStep, goToPreviousStep, application }) => {
    const [formLoading, setFormLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [formErrMsg, setFormErrMsg] = useState<string | null>(null);
    const [pageErrMsg, setPageErrMsg] = useState<string | null>(null);
    const [currentQuestions, setCurrentQuestions] = useState<Question[]>([]);

    const initialValues = {
        q1: {
            pfn_answer: currentQuestions[0]?.pfn_answer_Formatted ? currentQuestions[0]?.pfn_answer_Formatted : '',
            pfn_explanation: currentQuestions[0]?.pfn_explanation ? currentQuestions[0]?.pfn_explanation : '',
        },
        q2: {
            pfn_answer: currentQuestions[1]?.pfn_answer_Formatted ? currentQuestions[1]?.pfn_answer_Formatted : '',
            pfn_explanation: currentQuestions[1]?.pfn_explanation ? currentQuestions[1]?.pfn_explanation : '',
        },
        q3: {
            pfn_answer: currentQuestions[2]?.pfn_answer_Formatted ? currentQuestions[2]?.pfn_answer_Formatted : '',
            pfn_explanation: currentQuestions[2]?.pfn_explanation ? currentQuestions[2]?.pfn_explanation : '',
        },
        q4: {
            pfn_answer: currentQuestions[3]?.pfn_answer_Formatted ? currentQuestions[3]?.pfn_answer_Formatted : '',
            pfn_explanation: currentQuestions[3]?.pfn_explanation ? currentQuestions[3]?.pfn_explanation : '',
        },
        q5: {
            pfn_answer: currentQuestions[4]?.pfn_answer_Formatted ? currentQuestions[4]?.pfn_answer_Formatted : '',
            pfn_explanation: currentQuestions[4]?.pfn_explanation ? currentQuestions[4]?.pfn_explanation : '',
        },
        q6: {
            pfn_answer: currentQuestions[5]?.pfn_answer_Formatted ? currentQuestions[5]?.pfn_answer_Formatted : '',
            pfn_explanation: currentQuestions[5]?.pfn_explanation ? currentQuestions[5]?.pfn_explanation : '',
        },
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                setFormErrMsg(null);
                setFormLoading(true);

                const convertedValues = convertValues(values);

                const response = await applicationApi.updateQuestions(convertedValues);

                if (response) {
                    if (!response.success) {
                        if (response.message) {
                            setFormErrMsg(response.message);
                        } else {
                            setFormErrMsg('An error has occured, please try again later');
                        }
                    } else {
                        goToNextStep();
                    }
                } else {
                    setFormErrMsg('An error has occured, please try again later');
                }

                setFormLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setFormErrMsg(err.message);
                } else {
                    setFormErrMsg('An error has occurred, please try again later');
                }
                setFormLoading(false);
            }
        },
    });

    useEffect(() => {
        const getQuestionsAsync = async () => {
            try {
                setPageLoading(true);
                setPageErrMsg(null);
                const response = await applicationApi.getQuestions(application.pfn_applicationid);

                if (response) {
                    if (!response.success) {
                        setPageErrMsg('An error has occurred, please try again later');
                    } else {
                        if (response.data) {
                            setCurrentQuestions(response.data);
                        }
                    }
                } else {
                    setPageErrMsg('An error has occurred, please try again later');
                }

                setPageLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setPageErrMsg(err.message);
                } else {
                    setPageErrMsg('An error has occurred, please try again later');
                }
                setPageLoading(false);
            }
        };

        getQuestionsAsync();

        return () => {
            setPageErrMsg(null);
            setPageLoading(false);
            setCurrentQuestions([]);
        };
    }, [application.pfn_applicationid]);

    const convertValues = (values: QuestionsFormValues): QuestionsFormConvertedValues => {
        const convertedValues: QuestionsFormConvertedValues = {
            q1: {
                pfn_applicationquestionid: currentQuestions[0]?.pfn_applicationquestionid,
                pfn_answer: values.q1.pfn_answer === 'Yes',
            },
            q2: {
                pfn_applicationquestionid: currentQuestions[1]?.pfn_applicationquestionid,
                pfn_answer: values.q2.pfn_answer === 'Yes',
            },
            q3: {
                pfn_applicationquestionid: currentQuestions[2]?.pfn_applicationquestionid,
                pfn_answer: values.q3.pfn_answer === 'Yes',
            },
            q4: {
                pfn_applicationquestionid: currentQuestions[3]?.pfn_applicationquestionid,
                pfn_answer: values.q4.pfn_answer === 'Yes',
            },
            q5: {
                pfn_applicationquestionid: currentQuestions[4]?.pfn_applicationquestionid,
                pfn_answer: values.q5.pfn_answer === 'Yes',
            },
            q6: {
                pfn_applicationquestionid: currentQuestions[5]?.pfn_applicationquestionid,
                pfn_answer: values.q6.pfn_answer === 'Yes',
            },
        };

        if (values.q1.pfn_answer === 'Yes' && values.q1.pfn_explanation)
            convertedValues.q1.pfn_explanation = values.q1.pfn_explanation;

        if (values.q2.pfn_answer === 'Yes' && values.q2.pfn_explanation)
            convertedValues.q2.pfn_explanation = values.q2.pfn_explanation;

        if (values.q3.pfn_answer === 'Yes' && values.q3.pfn_explanation)
            convertedValues.q3.pfn_explanation = values.q3.pfn_explanation;

        if (values.q4.pfn_answer === 'Yes' && values.q4.pfn_explanation)
            convertedValues.q4.pfn_explanation = values.q4.pfn_explanation;

        if (values.q5.pfn_answer === 'Yes' && values.q5.pfn_explanation)
            convertedValues.q5.pfn_explanation = values.q5.pfn_explanation;

        if (values.q6.pfn_answer === 'Yes' && values.q6.pfn_explanation)
            convertedValues.q6.pfn_explanation = values.q6.pfn_explanation;

        return convertedValues;
    };

    const handleBackClick = async () => {
        goToPreviousStep();
    };

    return (
        <>
            {pageLoading ? (
                <div>Loading questions, please wait...</div>
            ) : pageErrMsg ? (
                <div>{pageErrMsg}</div>
            ) : (
                <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='updateQuestionsForm'>
                    {/* Start Form Row */}
                    <div
                        className={clsx('fv-row', {
                            'mb-7': formik.values.q1.pfn_answer === 'Yes',
                            'mb-10': formik.values.q1.pfn_answer !== 'Yes',
                        })}
                    >
                        <label className='form-label fs-6 fw-bolder text-dark required'>
                            {currentQuestions[0]?.pfn_details}
                            {!currentQuestions[0]?.pfn_details?.endsWith('?') && '?'}
                        </label>
                        <select
                            {...formik.getFieldProps('q1.pfn_answer')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid': formik.touched.q1?.pfn_answer && formik.errors.q1?.pfn_answer,
                            })}
                            name='q1.pfn_answer'
                        >
                            <option disabled value=''>
                                Select Option
                            </option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                        </select>
                        {formik.touched.q1?.pfn_answer && formik.errors.q1?.pfn_answer && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.q1?.pfn_answer}
                                </span>
                            </div>
                        )}
                    </div>
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    {formik.values.q1.pfn_answer === 'Yes' && (
                        <div className='fv-row mb-10'>
                            <label className='form-label fw-bolder text-dark fs-6 required'>Explanation</label>

                            <textarea
                                autoComplete='off'
                                rows={5}
                                {...formik.getFieldProps('q1.pfn_explanation')}
                                className={clsx('form-control form-control-lg form-control-solid', {
                                    'is-invalid':
                                        formik.touched.q1?.pfn_explanation && formik.errors.q1?.pfn_explanation,
                                })}
                            />
                            {formik.touched.q1?.pfn_explanation && formik.errors.q1?.pfn_explanation && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span className='text-danger' role='alert'>
                                            {formik.errors.q1?.pfn_explanation}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    <div
                        className={clsx('fv-row', {
                            'mb-7': formik.values.q2.pfn_answer === 'Yes',
                            'mb-10': formik.values.q2.pfn_answer !== 'Yes',
                        })}
                    >
                        <label className='form-label fs-6 fw-bolder text-dark required'>
                            {currentQuestions[1]?.pfn_details}
                            {!currentQuestions[1]?.pfn_details?.endsWith('?') && '?'}
                        </label>
                        <select
                            {...formik.getFieldProps('q2.pfn_answer')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid': formik.touched.q2?.pfn_answer && formik.errors.q2?.pfn_answer,
                            })}
                            name='q2.pfn_answer'
                        >
                            <option disabled value=''>
                                Select Option
                            </option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                        </select>
                        {formik.touched.q2?.pfn_answer && formik.errors.q2?.pfn_answer && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.q2?.pfn_answer}
                                </span>
                            </div>
                        )}
                    </div>
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    {formik.values.q2.pfn_answer === 'Yes' && (
                        <div className='fv-row mb-10'>
                            <label className='form-label fw-bolder text-dark fs-6 required'>Explanation</label>

                            <textarea
                                autoComplete='off'
                                rows={5}
                                {...formik.getFieldProps('q2.pfn_explanation')}
                                className={clsx('form-control form-control-lg form-control-solid', {
                                    'is-invalid':
                                        formik.touched.q2?.pfn_explanation && formik.errors.q2?.pfn_explanation,
                                })}
                            />
                            {formik.touched.q2?.pfn_explanation && formik.errors.q2?.pfn_explanation && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span className='text-danger' role='alert'>
                                            {formik.errors.q2?.pfn_explanation}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    <div
                        className={clsx('fv-row', {
                            'mb-7': formik.values.q3.pfn_answer === 'Yes',
                            'mb-10': formik.values.q3.pfn_answer !== 'Yes',
                        })}
                    >
                        <label className='form-label fs-6 fw-bolder text-dark required'>
                            {currentQuestions[2]?.pfn_details}
                            {!currentQuestions[2]?.pfn_details?.endsWith('?') && '?'}
                        </label>
                        <select
                            {...formik.getFieldProps('q3.pfn_answer')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid': formik.touched.q3?.pfn_answer && formik.errors.q3?.pfn_answer,
                            })}
                            name='q3.pfn_answer'
                        >
                            <option disabled value=''>
                                Select Option
                            </option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                        </select>
                        {formik.touched.q3?.pfn_answer && formik.errors.q3?.pfn_answer && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.q3?.pfn_answer}
                                </span>
                            </div>
                        )}
                    </div>
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    {formik.values.q3.pfn_answer === 'Yes' && (
                        <div className='fv-row mb-10'>
                            <label className='form-label fw-bolder text-dark fs-6 required'>Explanation</label>

                            <textarea
                                autoComplete='off'
                                rows={5}
                                {...formik.getFieldProps('q3.pfn_explanation')}
                                className={clsx('form-control form-control-lg form-control-solid', {
                                    'is-invalid':
                                        formik.touched.q3?.pfn_explanation && formik.errors.q3?.pfn_explanation,
                                })}
                            />
                            {formik.touched.q3?.pfn_explanation && formik.errors.q3?.pfn_explanation && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span className='text-danger' role='alert'>
                                            {formik.errors.q3?.pfn_explanation}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    <div
                        className={clsx('fv-row', {
                            'mb-7': formik.values.q4.pfn_answer === 'Yes',
                            'mb-10': formik.values.q4.pfn_answer !== 'Yes',
                        })}
                    >
                        <label className='form-label fs-6 fw-bolder text-dark required'>
                            {currentQuestions[3]?.pfn_details}
                            {!currentQuestions[3]?.pfn_details?.endsWith('?') && '?'}
                        </label>
                        <select
                            {...formik.getFieldProps('q4.pfn_answer')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid': formik.touched.q4?.pfn_answer && formik.errors.q4?.pfn_answer,
                            })}
                            name='q4.pfn_answer'
                        >
                            <option disabled value=''>
                                Select Option
                            </option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                        </select>
                        {formik.touched.q4?.pfn_answer && formik.errors.q4?.pfn_answer && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.q4?.pfn_answer}
                                </span>
                            </div>
                        )}
                    </div>
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    {formik.values.q4.pfn_answer === 'Yes' && (
                        <div className='fv-row mb-10'>
                            <label className='form-label fw-bolder text-dark fs-6 required'>Explanation</label>

                            <textarea
                                autoComplete='off'
                                rows={5}
                                {...formik.getFieldProps('q4.pfn_explanation')}
                                className={clsx('form-control form-control-lg form-control-solid', {
                                    'is-invalid':
                                        formik.touched.q4?.pfn_explanation && formik.errors.q4?.pfn_explanation,
                                })}
                            />
                            {formik.touched.q4?.pfn_explanation && formik.errors.q4?.pfn_explanation && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span className='text-danger' role='alert'>
                                            {formik.errors.q4?.pfn_explanation}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    <div
                        className={clsx('fv-row', {
                            'mb-7': formik.values.q5.pfn_answer === 'Yes',
                            'mb-10': formik.values.q5.pfn_answer !== 'Yes',
                        })}
                    >
                        <label className='form-label fs-6 fw-bolder text-dark required'>
                            {currentQuestions[4]?.pfn_details}
                            {!currentQuestions[4]?.pfn_details?.endsWith('?') && '?'}
                        </label>
                        <select
                            {...formik.getFieldProps('q5.pfn_answer')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid': formik.touched.q5?.pfn_answer && formik.errors.q5?.pfn_answer,
                            })}
                            name='q5.pfn_answer'
                        >
                            <option disabled value=''>
                                Select Option
                            </option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                        </select>
                        {formik.touched.q5?.pfn_answer && formik.errors.q5?.pfn_answer && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.q5?.pfn_answer}
                                </span>
                            </div>
                        )}
                    </div>
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    {formik.values.q5.pfn_answer === 'Yes' && (
                        <div className='fv-row mb-10'>
                            <label className='form-label fw-bolder text-dark fs-6 required'>Explanation</label>

                            <textarea
                                autoComplete='off'
                                rows={5}
                                {...formik.getFieldProps('q5.pfn_explanation')}
                                className={clsx('form-control form-control-lg form-control-solid', {
                                    'is-invalid':
                                        formik.touched.q5?.pfn_explanation && formik.errors.q5?.pfn_explanation,
                                })}
                            />
                            {formik.touched.q5?.pfn_explanation && formik.errors.q5?.pfn_explanation && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span className='text-danger' role='alert'>
                                            {formik.errors.q5?.pfn_explanation}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    <div
                        className={clsx('fv-row', {
                            'mb-7': formik.values.q6.pfn_answer === 'Yes',
                            'mb-10': formik.values.q6.pfn_answer !== 'Yes',
                        })}
                    >
                        <label className='form-label fs-6 fw-bolder text-dark required'>
                            {currentQuestions[5]?.pfn_details}
                            {!currentQuestions[5]?.pfn_details?.endsWith('?') && '?'}
                        </label>
                        <select
                            {...formik.getFieldProps('q6.pfn_answer')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid': formik.touched.q6?.pfn_answer && formik.errors.q6?.pfn_answer,
                            })}
                            name='q6.pfn_answer'
                        >
                            <option disabled value=''>
                                Select Option
                            </option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                        </select>
                        {formik.touched.q6?.pfn_answer && formik.errors.q6?.pfn_answer && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.q6?.pfn_answer}
                                </span>
                            </div>
                        )}
                    </div>
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    {formik.values.q6.pfn_answer === 'Yes' && (
                        <div className='fv-row mb-10'>
                            <label className='form-label fw-bolder text-dark fs-6 required'>Explanation</label>

                            <textarea
                                autoComplete='off'
                                rows={5}
                                {...formik.getFieldProps('q6.pfn_explanation')}
                                className={clsx('form-control form-control-lg form-control-solid', {
                                    'is-invalid':
                                        formik.touched.q6?.pfn_explanation && formik.errors.q6?.pfn_explanation,
                                })}
                            />
                            {formik.touched.q6?.pfn_explanation && formik.errors.q6?.pfn_explanation && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span className='text-danger' role='alert'>
                                            {formik.errors.q6?.pfn_explanation}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {/* End Form Row */}

                    <div className='d-flex align-items-center mb-10'>
                        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                    </div>

                    <div className='fv-row'>
                        <div className='d-flex justify-content-between'>
                            <button type='button' className='btn btn-primary me-3' onClick={handleBackClick}>
                                Back
                            </button>

                            <button
                                type='submit'
                                className='btn btn-primary'
                                disabled={formik.isSubmitting || formLoading}
                            >
                                {!formLoading && <span className='indicator-label'>Next</span>}
                                {formLoading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                    {formErrMsg && <div className='text-danger text-center mt-7'>{formErrMsg}</div>}
                </form>
            )}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { QuestionsForm };

/* -------------------------------------------------------------------------- */
