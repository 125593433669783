/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useEffect, useState } from 'react';
import { PageTitle } from '../../../../_metronic/layout/core';
import Table from 'react-bootstrap/Table';
import invoiceApi from '../../../api/invoiceApi';
import { useNavigate, useParams } from 'react-router-dom';
import { Invoice, MonerisComplete } from '../../../types';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { useAppSelector } from '../../../redux/hooks';
import { convertIsoDateToString } from '../../../helpers';
import paymentApi from '../../../api/paymentApi';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

interface Props {
    invoice: Invoice;
    monerisTicket: string;
}

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const InvoicePage: FC<Props> = ({ invoice, monerisTicket }) => {
    const user = useAppSelector((state) => state.user);
    const [monerisErrMsg, setMonerisErrMsg] = useState<string | null>(null);
    // const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const myPageLoad = (e: any) => {
            // console.log('loaded', e);
        };

        const myCancelTransaction = (e: any) => {
            // console.log('cancel', e);
        };

        const myErrorEvent = (e: any) => {
            // console.log('error', e);
        };

        const myPaymentReceipt = (e: any) => {
            // console.log('receipt', e);
        };

        const myPaymentComplete = async (e: string) => {
            // console.log('complete', e);
            const monerisComplete: MonerisComplete = JSON.parse(e);

            try {
                if (user.id) {
                    const response = await paymentApi.handleMonerisReceipt(user.id, monerisComplete.ticket);
                    if (response) {
                        if (!response.success) {
                            if (response.message) {
                                setMonerisErrMsg(response.message);
                            } else {
                                setMonerisErrMsg(
                                    'An error has occured, please contact cvbc and do not attempt another payment because it may have gone through',
                                );
                            }
                        } else {
                            navigate(`/payment/success`, {
                                state: {
                                    pfn_name: response.data?.pfn_name,
                                    pfn_paymentid: response.data?.pfn_paymentid,
                                },
                            });
                        }
                    } else {
                        setMonerisErrMsg(
                            'An error has occured, please contact cvbc and do not attempt another payment because it may have gone through',
                        );
                    }
                } else {
                    setMonerisErrMsg('An error has occured, no user id found');
                }
            } catch (err) {
                setMonerisErrMsg(
                    'An error has occured, please contact cvbc and do not attempt another payment because it may have gone through',
                );
            }
        };

        const myCheckout = new (window as any).monerisCheckout();
        myCheckout.setMode(process.env.REACT_APP_MONERIS_ENV);
        myCheckout.setCheckoutDiv('monerisCheckout');
        myCheckout.startCheckout(monerisTicket);
        myCheckout.setCallback('page_loaded', myPageLoad);
        myCheckout.setCallback('cancel_transaction', myCancelTransaction);
        myCheckout.setCallback('error_event', myErrorEvent);
        myCheckout.setCallback('payment_receipt', myPaymentReceipt);
        myCheckout.setCallback('payment_complete', myPaymentComplete);
    }, [monerisTicket, navigate, user.id]);

    const CreateItemsTable = () => {
        return (
            <div className='mb-10 mt-20'>
                <h5 className='mt-5'>Invoice Items:</h5>
                <div className='table-container'>
                    <Table className='invoice-items-table border' striped bordered>
                        <thead>
                            <tr>
                                <th className='fw-bolder'>Fee / Item</th>
                                <th className='fw-bolder'>Amount</th>
                                <th className='fw-bolder'>Quantity</th>
                                <th className='fw-bolder'>Subtotal</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoice?.items?.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td className='item'>{item._pfn_feeid_value_Formatted}</td>
                                        <td className='amount'>{item.pfn_amount_Formatted}</td>
                                        <td className='qty'>{item.pfn_quantity_Formatted}</td>
                                        <td className='sub'>{item.pfn_subtotal_Formatted}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    };

    return (
        <div className='invoice-page'>
            <div className='d-flex justify-content-center mb-10'>
                <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/cvbc_logo_trans_blue.png')}
                    className='payment-logo h-80px'
                />
            </div>
            <div className='d-flex justify-content-between px-2'>
                {invoice._pfn_accountid_value ? (
                    <div>
                        <div className='text-primary'>
                            <b>{invoice._pfn_accountid_value_Formatted}</b>
                        </div>
                        <div>
                            <b>
                                {user.firstname} {user.lastname}
                            </b>
                        </div>
                        <div>{invoice.pfn_accountid?.address1_line1}</div>
                        <div>{invoice.pfn_accountid?.address1_line2}</div>
                        <div>{invoice.pfn_accountid?.address1_city}, BC</div>
                        <div>{invoice.pfn_accountid?.address1_postalcode}</div>
                        <div>Canada</div>
                    </div>
                ) : user.address.mailing === 'Home' ? (
                    <div>
                        <div>
                            <b>
                                {user.firstname} {user.lastname}
                            </b>
                        </div>
                        <div>{user.address.home.line1}</div>
                        <div>{user.address.home.line2}</div>
                        <div>
                            {user.address.home.city}, {user.address.home.province}
                        </div>
                        <div>{user.address.home.postalCode}</div>
                        <div>{user.address.home.country}</div>
                    </div>
                ) : (
                    <div>
                        <div>
                            <b>
                                {user.firstname} {user.lastname}
                            </b>
                        </div>
                        <div>{user.facilities.primary.address.line1}</div>
                        <div>{user.facilities.primary.address.line2}</div>
                        <div>{user.facilities.primary.address.city}, BC</div>
                        <div>{user.facilities.primary.address.postalCode}</div>
                        <div>Canada</div>
                    </div>
                )}
                <div className='d-flex flex-column align-items-end'>
                    <div>
                        <b>{invoice?.pfn_name}</b>
                    </div>
                    <div>{convertIsoDateToString(invoice?.createdon)}</div>
                </div>
            </div>
            {CreateItemsTable()}
            <div className='mb-10'>
                <h5>Invoice Summary:</h5>
                <div className='table-container'>
                    <Table className='invoice-summary-table border' bordered striped>
                        <tbody>
                            <tr>
                                <td className='title'>
                                    <b>Subtotal:</b>
                                </td>
                                <td className='value'>{invoice.pfn_subtotal_Formatted}</td>
                            </tr>
                            <tr>
                                <td className='title'>
                                    <b>Taxes (GST# 106779424 RT0001):</b>
                                </td>
                                <td className='value'>{invoice.pfn_tax_Formatted}</td>
                            </tr>
                            <tr>
                                <td className='title'>
                                    <b>Total:</b>
                                </td>
                                <td className='value'>{invoice.pfn_total_Formatted}</td>
                            </tr>
                            <tr>
                                <td className='title'>
                                    <b>Amount Paid:</b>
                                </td>
                                <td className='value'>
                                    {invoice.pfn_paid_Formatted ? (
                                        <b className='text-success'>{invoice.pfn_paid_Formatted}</b>
                                    ) : (
                                        '$0'
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td className='title'>
                                    <b>Outstanding Amount:</b>
                                </td>
                                <td className='value'>{invoice.pfn_outstanding_Formatted}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>

            <div className='d-flex justify-content-center no-print payment-options-container'>
                <div className='accordion' id='kt_accordion_1'>
                    <div className='accordion-item'>
                        <h2 className='accordion-header' id='kt_accordion_1_header_1'>
                            <button
                                className='accordion-button fs-4 fw-bold collapsed'
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target='#kt_accordion_1_body_1'
                                aria-expanded='false'
                                aria-controls='kt_accordion_1_body_1'
                            >
                                Pay Online ({invoice.pfn_outstanding_Formatted})
                            </button>
                        </h2>
                        <div
                            id='kt_accordion_1_body_1'
                            className='accordion-collapse collapse'
                            aria-labelledby='kt_accordion_1_header_1'
                            data-bs-parent='#kt_accordion_1'
                        >
                            <div className='accordion-body'>
                                {!monerisErrMsg ? (
                                    <div id='outerDiv' style={{ height: '750px' }}>
                                         <div id='monerisCheckout'></div>
                                    </div>
                                ) : (
                                    <div>{monerisErrMsg}</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='accordion' id='kt_accordion_2'>
                    <div className='accordion-item'>
                        <h2 className='accordion-header' id='kt_accordion_2_header_1'>
                            <button
                                className='accordion-button fs-4 fw-bold collapsed'
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target='#kt_accordion_2_body_1'
                                aria-expanded='false'
                                aria-controls='kt_accordion_2_body_1'
                            >
                                Pay By Mail
                            </button>
                        </h2>
                        <div
                            id='kt_accordion_2_body_1'
                            className='accordion-collapse collapse'
                            aria-labelledby='kt_accordion_2_header_1'
                            data-bs-parent='#kt_accordion_2'
                        >
                            <div className='accordion-body'>
                                <div>
                                    <span>
                                        This invoice has been generated for {user.firstname} {user.lastname}. Payment
                                        can be made online or a cheque may be submitted to the office (payable to the
                                        College of Veterinarians of BC). If you have any questions about the applied
                                        fee(s), please contact the office at reception@cvbc.ca or 604-929-7090.
                                    </span>
                                    <span className='fw-bolder'>
                                        {' '}
                                        If paying by check or bank draft, please mail to the address below:
                                    </span>
                                </div>
                                <div className='mt-5'>
                                    <div>College of Veterinarians of BC,</div>
                                    <div>10991 Shellbridge Way, Suite 210,</div>
                                    <div>Richmond, BC V6X 3C6</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='d-flex justify-content-center no-print mt-10'>
                <button className='btn btn-primary mx-3' onClick={() => navigate(-1)}>
                    Back
                </button>
                <button className='btn btn-primary mx-3' onClick={() => window.print()}>
                    Print
                </button>
            </div>
        </div>
    );
};

/* -------------------------------------------------------------------------- */

/* ------------------------------ PAGE WRAPPER ------------------------------ */

const InvoiceWrapper: FC = () => {
    const { invoice_name, pfn_invoiceid } = useParams();
    const title = `Invoice: ${invoice_name}`;
    const [invoice, setInvoice] = useState<Invoice | null>(null);
    const [monerisTicket, setMonerisTicket] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [errMsg, setErrMsg] = useState<string | null>(null);

    useEffect(() => {
        const setupInvoiceAsync = async () => {
            try {
                if (pfn_invoiceid) {
                    const invoiceResponse = await invoiceApi.getInvoice(pfn_invoiceid);

                    if (invoiceResponse) {
                        if (!invoiceResponse.success) {
                            if (invoiceResponse.message) {
                                setErrMsg(invoiceResponse.message);
                            } else {
                                setErrMsg('An error occured, please try again later');
                            }
                        } else {
                            if (
                                invoiceResponse.data &&
                                invoiceResponse.data.pfn_invoiceid &&
                                invoiceResponse.data.pfn_outstanding
                            ) {
                                setInvoice(invoiceResponse.data);

                                const monerisPreloadResponse = await paymentApi.getMonerisPreloadResponse(
                                    invoiceResponse.data.pfn_invoiceid,
                                    invoiceResponse.data.pfn_outstanding,
                                );

                                if (monerisPreloadResponse) {
                                    if (!monerisPreloadResponse.success) {
                                        if (monerisPreloadResponse.message) {
                                            setErrMsg(monerisPreloadResponse.message);
                                        } else {
                                            setErrMsg(
                                                'An error has occured setting up Moneris, please try again later',
                                            );
                                        }
                                    } else {
                                        if (monerisPreloadResponse.data) {
                                            setMonerisTicket(monerisPreloadResponse.data);
                                        } else {
                                            setErrMsg('No data found for Moneris Request');
                                        }
                                    }
                                } else {
                                    setErrMsg('An error has occured setting up Moneris, please try again later');
                                }
                            } else {
                                setErrMsg('No data found');
                            }
                        }
                    } else {
                        setErrMsg('An error has occured, please try again later');
                    }
                } else {
                    setErrMsg('No invoice id found');
                }
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occured, please try again later');
                }
            }
            setLoading(false);
        };

        setupInvoiceAsync();

        return () => {
            setErrMsg(null);
            setInvoice(null);
            setLoading(false);
        };
    }, [pfn_invoiceid]);

    return (
        <>
            <PageTitle breadcrumbs={[]}>{title}</PageTitle>
            {loading ? (
                <div>Loading payment method, please wait...</div>
            ) : errMsg ? (
                <div>{errMsg}</div>
            ) : invoice && monerisTicket ? (
                <InvoicePage invoice={invoice} monerisTicket={monerisTicket} />
            ) : (
                <>
                    <div>Invoice could not be loaded</div>
                    <div>{errMsg}</div>
                </>
            )}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { InvoiceWrapper };

/* -------------------------------------------------------------------------- */
