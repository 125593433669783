export const SpecialMonthValues = {
    DO_NOT_RECALL: {
        value: '13',
        title: 'Do Not Recall'
    },
    PRESENT: {
        value: '14',
        title: 'Present'
    },
};

export const SpecialYearValues = {
    PRESENT: {
        value: 'present',
        title: 'Present'
    },
};

export const REGISTRATION_EMAIL = {
    value: 'registration@cvbc.ca',
    title: 'REGISTRATION EMAIL'
};

export const TEXT_CANT_RENEW_WARNING = {
    value: 'You have not been given access to the registration renewal process and should have already been contacted by the CVBC with an explanation. If you have not heard from the CVBC or if you have any questions about your status, please contact the CVBC at ' + REGISTRATION_EMAIL.value + ' for more information.'
};

export const TEXT_RENEW_BREADCRUMB1 = {
    value: 'Registration Renewal Application'
};

export const LINK_REGISTRATIOM_RENEWAL_DETAILS1 = {
    value: '/applications/registration-renewal-details1'
};

export const LINK_REGISTRATIOM_RENEWAL_DETAILS2 = {
    value: '/applications/registration-renewal-details2'
};

export const TEXT_FACILITIES_ANNUAL_DECLARATIONS_GENERAL_INFO = {
    value: 'The Designated Registrant is responsible for submitting the Annual Declaration form for the previous year by January 31st of the following year. The accompanying Self-Assessment Form¹ that assesses the previous year must also be completed and kept in the facility to be available upon request. In the event of a Facility Inspection, the Declaration Form and Self-Assessment Form must be submitted to the CVBC office for review by the inspector. In the case of a new facility or addition of new scopes of practice, please fill out the forms as they relate to the anticipated services.'
};

export const CVBC_RECEPTION_EMAIL = {
    value: 'reception@cvbc.ca',
    title: 'Email'
};

export const CVBC_FACILITIES_EMAIL = {
    value: 'facilities@cvbc.ca',
    title: 'Facilities Email'
};

export const TEXT_FACILITIES_ANNUAL_DECLARATIONS_INITIAL_DETAILS1 = {
    value: 'Other Veterinarians (Registrants can add or remove themselves by accessing their portal and choosing if the information is published. Alternately, contact ' + CVBC_RECEPTION_EMAIL.value + ' to update this information).'
};

export const TEXT_FACILITIES_ANNUAL_DECLARATIONS_PUBLICATIONS1 = {
    value: 'CVBC by-law 3.2 mandates an online registry of all accredited practice facilities be maintained by the Registrar. For the Facility Registry, publication of the street address is optional. The default setting is to not list the street address unless given permission by the DR to do so.'
};

export const TEXT_FACILITIES_ANNUAL_DECLARATIONS_PUBLICATIONS2 = {
    value: 'Not all information required by veterinary distributors for shipment of supplies and pharmaceuticals to practice facilities is available on the public online registry. To assist practice facilities, CVBC shares information regarding facility address, designated registrant information, and confirms the facility\'s accreditation status with AVP, Summit, WDDC and CDMV. Please advise the CVBC at ' + CVBC_FACILITIES_EMAIL.value + ' if you do not consent to the CVBC sharing this information with veterinary distributors.'
};

export const CVBC_RECEPTION_PHONE = {
    value: '604-929-7090',
    title: 'Phone'
};

export const CVBC_RECEPTION_FAX = {
    value: '604-929-7095',
    title: 'Fax'
};

export const CVBC_OFFICE_ADDRESS = {
    value: '210-10991 Shellbridge Way, Richmond, BC V6X 3C6',
    title: 'Office Address'
};
