/* --------------------------------- LINTING -------------------------------- */

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

/* -------------------------------------------------------------------------- */

/* --------------------------------- IMPORTS -------------------------------- */

import axios from 'axios';
import { logoutUser, refreshAccessToken } from '../redux/slices/UserSlice';
import authApi from './authApi';

/* -------------------------------------------------------------------------- */

/* -------------------------------- API SETUP ------------------------------- */

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    withCredentials: true,
});

/* -------------------------------------------------------------------------- */

/* ---------------------------- API INTERCEPTORS ---------------------------- */

const apiInterceptors = (store: any) => {
    const { dispatch } = store;

    apiClient.interceptors.request.use(
        (config) => {
            // dispatch(clearErrors());
            return config;
        },
        (err) => {
            return Promise.reject(err);
        },
    );

    apiClient.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;
            if (err.response?.data?.message === 'Forbidden') {
                if (!originalConfig?._retry) {
                    try {
                        originalConfig._retry = true;
                        // await authApi.refreshAccessToken();
                        await dispatch(refreshAccessToken());
                        return apiClient(originalConfig);
                    } catch (err) {
                        dispatch(logoutUser());
                        return Promise.reject(err);
                    }
                } else {
                    dispatch(logoutUser());
                }
            }

            return Promise.reject(err);
        },
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { apiClient, apiInterceptors };

/* -------------------------------------------------------------------------- */
