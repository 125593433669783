/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../../_metronic/layout/core';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { useAppSelector } from '../../../redux/hooks';
import { AddCourseBtn } from './components/AddCourseBtn';
import { EditCourseBtn } from './components/EditCourseBtn';
import { DeleteCourseBtn } from './components/DeleteCourseBtn';
import { UploadDocumentBtn } from './components/UploadDocumentsBtn';

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const TranscriptsPage: FC = () => {
    const user = useAppSelector((state) => state.user);
    const [transcriptIndexSelected, setTranscriptIndexSelected] = useState(0);

    const GenerateClassesTable = () => {
        return (
            <div className='table-container mb-10 mt-10'>
                <Table className='transcript-courses-table border' striped bordered>
                    <thead>
                        <tr>
                            <th className='fw-bolder'>Start Date</th>
                            <th className='fw-bolder'>End Date</th>
                            <th className='fw-bolder'>Title</th>
                            <th className='fw-bolder'>Credits Submitted</th>
                            <th className='fw-bolder'>
                                Credits Approved (After CVBC Audit)<span className='note-number no-print'>2</span>
                            </th>
                            <th className='fw-bolder'>
                                Status<span className='note-number no-print'>3</span>
                            </th>
                            <th className='doc fw-bolder no-print'>Supporting Documentation</th>
                            <th className='action fw-bolder no-print'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {user.transcripts[transcriptIndexSelected]?.courses.map((course, i) => (
                            <tr key={i}>
                                <td className='date'>{course.startDate.formatted}</td>
                                <td className='date'>{course.endDate.formatted}</td>
                                <td className='title'>{course.name}</td>
                                <td className='credits'>{course.creditsSubmitted.formatted}</td>
                                <td className='credits'>{course.creditsAuditted.formatted}</td>
                                <td className='status'>
                                    {course.status.formatted === 'Active' ? 'Submitted' : course.status.formatted}
                                </td>
                                <td className='doc no-print'>
                                    {course.uploadedDocs.length > 0 ? (
                                        course.uploadedDocs.map((doc, i) => <div key={i}>{doc.fileName}</div>)
                                    ) : (
                                        <UploadDocumentBtn
                                            course={course}
                                            modalName={`kt_modal_upload_document_${i}`}
                                        />
                                    )}
                                </td>
                                <td className='action no-print'>
                                    <EditCourseBtn course={course} modalName={`kt_modal_edit_course_${i}`} />
                                    <DeleteCourseBtn course={course} modalName={`kt_modal_delete_course_${i}`} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    };

    const GenerateTranscriptUserInfo = () => {
        return (
            <div className='flex-grow-1'>
                <img
                    alt='Logo'
                    className='transcripts-logo h-80px mb-10'
                    src={toAbsoluteUrl('/media/logos/cvbc_logo_trans_blue.png')}
                />
                <div>
                    <span className='fw-bolder'>Name:</span>{' '}
                    <span>
                        {user.firstname} {user.lastname}
                    </span>
                </div>
                <div>
                    <span className='fw-bolder'>Registration #:</span>{' '}
                    <span>{user.registrationNumber ? user.registrationNumber : 'NA'}</span>
                </div>
                <div>
                    <span className='fw-bolder'>Registrant Type:</span> <span>{user.statusHistory.type.formatted}</span>
                </div>
                <div>
                    <span className='fw-bolder'>Registration Category:</span>{' '}
                    <span>{user.statusHistory.category.formatted}</span>
                </div>
                <div>
                    <span className='fw-bolder'>Registration Subcategory:</span>{' '}
                    <span>{user.statusHistory.subcategory.formatted}</span>
                </div>
            </div>
        );
    };

    const GenerateTranscriptsDropDown = () => {
        return (
            <div className='flex-grow-1'>
                <InputGroup className='mb-12 mt-5'>
                    <InputGroup.Text id='basic-addon1'>CE Cycle Transcript Selected</InputGroup.Text>
                    <Form.Select
                        aria-label='select transcript'
                        onChange={(e) => {
                            if (typeof e.target.value === 'string' && user.transcripts.length > 0) {
                                const index = Number(e.target.value);
                                setTranscriptIndexSelected(index);
                            }
                        }}
                    >
                        {user.transcripts.length > 0 &&
                            user.transcripts.map((transcript, i) => {
                                return (
                                    <option key={i} value={i}>
                                        {transcript.name}
                                    </option>
                                );
                            })}
                    </Form.Select>
                </InputGroup>
                {user.transcripts[transcriptIndexSelected] && (
                    <Table className='mb-10 border' bordered striped>
                        <tbody>
                            <tr>
                                <td className='fw-bolder'>Time Period:</td>
                                <td>{user.transcripts[transcriptIndexSelected].name}</td>
                            </tr>
                            <tr>
                                <td className='fw-bolder'>Credits Required:</td>
                                <td>{user.transcripts[transcriptIndexSelected].creditsRequired.formatted}</td>
                            </tr>
                            <tr>
                                <td className='fw-bolder'>Credits Submitted:</td>
                                <td>{user.transcripts[transcriptIndexSelected].totalCredits.formatted}</td>
                            </tr>
                            <tr>
                                <td className='fw-bolder'>
                                    CVBC Auditted<span className='note-number no-print'>1</span> :
                                </td>
                                <td>{user.transcripts[transcriptIndexSelected].isAudited ? 'Yes' : 'No'}</td>
                            </tr>
                            {user.transcripts[transcriptIndexSelected].isAudited && (
                                <>
                                    <tr>
                                        <td className='fw-bolder'>Auditted Reason:</td>
                                        <td>{user.transcripts[transcriptIndexSelected].auditReason.formatted}</td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bolder'>Auditted Approved Credits:</td>
                                        <td>{user.transcripts[transcriptIndexSelected].creditsAuditted.formatted}</td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </Table>
                )}
            </div>
        );
    };

    const GenerateNote3 = () => {
        return (
            <div className='no-print'>
                <div className='note-card d-flex align-items-center bg-light rounded p-5 mb-10'>
                    <div className='note-card-inner flex-grow-1 me-2'>
                        <div className='text-gray-800'>
                            <div className='pb-3'>
                                <span>
                                    <span className='fw-bolder'>Note 3: </span>
                                </span>
                                <span>Explanation of “Status” descriptions:</span>
                            </div>
                            <ul>
                                <li>
                                    <span className='fw-bolder'>Submitted: </span>
                                    <span>
                                        your reported CE hours have been successfully recorded in your account and the
                                        hours have been applied to your “Total Credits Submitted” tally. An audit has
                                        not been performed, or has not yet been completed.
                                    </span>
                                </li>
                                <li>
                                    <span className='fw-bolder'>Audited – Approved: </span>
                                    <span>
                                        an auditor has reviewed your CE entry and has approved this session for the CE
                                        hours as submitted.
                                    </span>
                                </li>
                                <li>
                                    <span className='fw-bolder'>Audited – Partially Approved: </span>
                                    <span>
                                        an auditor has reviewed your CE entry and ahs approved a portion of the
                                        submitted hours.
                                    </span>
                                </li>
                                <li>
                                    <span className='fw-bolder'>Audited – Denied: </span>
                                    <span>
                                        an auditor has reviewed your CE entry and was unable to approve the session for
                                        any CE credit.
                                    </span>
                                </li>
                                <li>
                                    <span className='fw-bolder'>RAC-Required CE: </span>
                                    <span>
                                        if a registrant was required to complete CE hours as part of the outcome of a
                                        complaint investigation (or a discipline hearing), completion of those hours
                                        should be reported through your account, but they will not count towards your CE
                                        hours required by the Continuing Competence Program.
                                    </span>
                                </li>
                            </ul>
                            <div>
                                If you have any questions about the assigned status of your CE course and/or the total
                                credits approved by the auditor, please contact the CVBC at{' '}
                                <a
                                    className='text-decoration-underline fw-bold'
                                    href='mailto:continuingeducation@cvbc.ca'
                                >
                                    continuingeducation@cvbc.ca
                                </a>
                                .
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const GenerateNote1 = () => {
        return (
            <div className='no-print'>
                <div className='note-card d-flex align-items-center bg-light rounded p-5 mb-10'>
                    <div className='note-card-inner flex-grow-1 me-2'>
                        <div className='text-gray-800'>
                            <div className='pb-3'>
                                <span>
                                    <span className='fw-bolder'>Note 1: </span>
                                </span>
                                <span>
                                    Following the end of each 2-year CE Cycle, the CVBC will perform CE audits.
                                    Registrants may receive an audit as part of:
                                </span>
                            </div>
                            <ul>
                                <li>Random audits of 2.5% of all active registrants</li>
                                <li>
                                    Directed audits – all registrants who have not reported their minimum required CE
                                    hours by the December 31st end-date of the CE cycle will receive directed audits;
                                    the Continuing Competence Committee may also direct an audit of a registrant’s CE
                                    for other reasons.
                                </li>
                            </ul>
                            <div>
                                If you are selected for an audit, you will be contacted by the CVBC Auditor in the
                                Spring following the completed CE Cycle (eg. Spring of 2023 for audits of the 2021-2022
                                CE cycle). Audits assess reported CE against the criteria established in section 254 of
                                the CVBC Bylaws and in the{' '}
                                <a
                                    className='text-decoration-underline fw-bold'
                                    href='https://www.cvbc.ca/wp-content/uploads/2022/02/CE-Auditing-Policy-v2-Approved-Oct-29-2021.pdf'
                                    target='blank'
                                >
                                    CVBC’s Continuing Education Approval Criteria Policy.
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const GenerateNote2 = () => {
        return (
            <div className='no-print'>
                <div className='note-card d-flex align-items-center bg-light rounded p-5 mb-10'>
                    <div className='note-card-inner flex-grow-1 me-2'>
                        <div className='text-gray-800'>
                            <div className='pb-3'>
                                <span>
                                    <span className='fw-bolder'>Note 2: </span>
                                </span>
                                <span>
                                    If you received an audit of your CE, this column will show how many CE hours were
                                    approved for the audited courses. If you have not been audited (because you were not
                                    selected for an audit or because audits have not yet been conducted for this 2-year
                                    cycle transcript) this column will be empty.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            {user.statusHistory.status.formatted !== 'Active' ? (
                <div>This section is restricted to active members only</div>
            ) : user.transcripts && user.transcripts.length > 0 ? (
                <div className='transcript-top'>
                    <div className='transcripts-info-container d-flex'>
                        {GenerateTranscriptUserInfo()}
                        {GenerateTranscriptsDropDown()}
                    </div>

                    {user.transcripts[transcriptIndexSelected]?.courses.length > 0 ? (
                        GenerateClassesTable()
                    ) : (
                        <p>No courses on file for this transcript</p>
                    )}

                    <div className='d-flex justify-content-center no-print mb-15'>
                        {user.transcripts[transcriptIndexSelected] && (
                            <AddCourseBtn transcript={user.transcripts[transcriptIndexSelected]} />
                        )}
                        <button className='btn btn-primary' onClick={() => window.print()}>
                            Print
                        </button>
                    </div>

                    {GenerateNote1()}
                    {GenerateNote2()}
                    {GenerateNote3()}
                </div>
            ) : (
                <div>No transcripts found for your account</div>
            )}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- WRAPPER -------------------------------- */

const TranscriptsWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.TRANSCRIPTS' })}</PageTitle>
            <TranscriptsPage />
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { TranscriptsWrapper };

/* -------------------------------------------------------------------------- */
