/* --------------------------------- LINTING -------------------------------- */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

/* -------------------------------------------------------------------------- */

/* --------------------------------- IMPORTS -------------------------------- */

import { ApiResponse, Invoice } from '../types';
import { apiClient } from './config';

/* -------------------------------------------------------------------------- */

/* ----------------------------------- API ---------------------------------- */

const getInvoices = async (): Promise<ApiResponse<Invoice[]>> => {
    try {
        const { data } = await apiClient.get<ApiResponse<Invoice[]>>('/invoices/all');
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getInvoicesByAppId = async (pfn_applicationid: string): Promise<ApiResponse<Invoice[]>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<Invoice[]>>('/invoices/application', { pfn_applicationid });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getFacilityInvoices = async (pfn_accountid: string): Promise<ApiResponse<Invoice[]>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<Invoice[]>>('/invoices/facility', { pfn_accountid });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getInvoice = async (pfn_invoiceid: string): Promise<ApiResponse<Invoice>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<Invoice>>('/invoices/invoice', { pfn_invoiceid });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

/* -------------------------------------------------------------------------- */

/* ------------------------------- API OBJECT ------------------------------- */

const invoiceApi = { getInvoices, getFacilityInvoices, getInvoice, getInvoicesByAppId };

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export default invoiceApi;

/* -------------------------------------------------------------------------- */
