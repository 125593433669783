/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { useAppDispatch } from '../../../redux/hooks';
import { loginUser } from '../../../redux/slices/UserSlice';
import userApi from '../../../api/userApi';

/* -------------------------------------------------------------------------- */

/* ----------------------------- INITIAL VALUES ----------------------------- */

const initialValues = {
    pfn_username: '',
    pfn_password: '',
};

/* -------------------------------------------------------------------------- */

/* ------------------------------- FORM SCHEMA ------------------------------ */

const loginSchema = Yup.object().shape({
    pfn_username: Yup.string().email('Must be an email address').required('Username is required'),
    pfn_password: Yup.string().required('Password is required'),
});

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

export const Login: FC = () => {
    const [hidden, setHidden] = useState(true);
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrorMsg] = useState<string | null>(null);
    const [verificationEmailSent, setVerificationEmailSent] = useState(false);
    const [verificationResponseMsg, setVerificationResponseMsg] = useState<string | null>(null);
    const dispatch = useAppDispatch();

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: async (values) => {
            try {
                setVerificationEmailSent(false);
                setVerificationResponseMsg(null);
                setErrorMsg(null);
                setLoading(true);
                const { pfn_username, pfn_password } = values;
                const response = await dispatch(loginUser({ pfn_username, pfn_password }));

                if (response.type === 'user/loginUser/fulfilled') {
                    if (!response.payload?.success) {
                        if (response.payload?.message) {
                            setErrorMsg(response.payload.message);
                        } else {
                            setErrorMsg('An error has occured, please try again later');
                        }
                        setLoading(false);
                    }
                } else {
                    setErrorMsg('An error has occured, please try again later');
                    setLoading(false);
                }
            } catch (err) {
                setErrorMsg('An error has occured, please try again later');
                setLoading(false);
            }
        },
    });

    const resendVerificationEmail = async () => {
        try {
            setVerificationResponseMsg('Sending, please wait...');
            setVerificationEmailSent(true);
            const response = await userApi.resendVerificationEmail(formik.values.pfn_username);

            if (response) {
                if (response.success) {
                    setVerificationResponseMsg('Sent, please check your email');
                } else {
                    if (response.message) {
                        setVerificationResponseMsg(response.message);
                    } else {
                        setVerificationResponseMsg('Error trying to send verification email');
                    }
                }
            } else {
                setVerificationResponseMsg('Error trying to send verification email');
            }
        } catch (err) {
            setVerificationResponseMsg('Error trying to send verification email');
        }
    };

    return (
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
            <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_login_signin_form'>
                <div className='text-center mb-10'>
                    <h1 className='text-dark mb-3'>Sign In to CVBC</h1>
                    <div className='text-gray-400 fw-bold fs-4'>
                        New applicant?{' '}
                        <Link to='/auth/registration' className='link-primary fw-bolder'>
                            Create an Account
                        </Link>
                    </div>
                </div>
                <div className='d-flex align-items-center mb-10'>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                </div>

                {/* Start Form Row */}
                <div className='fv-row mb-10'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Username</label>
                    <input
                        placeholder='email.address@cvbc.ca'
                        {...formik.getFieldProps('pfn_username')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_username && formik.errors.pfn_username,
                        })}
                        type='email'
                        name='pfn_username'
                        autoComplete='off'
                        autoFocus={true}
                    />
                    {formik.touched.pfn_username && formik.errors.pfn_username && (
                        <div className='fv-plugins-message-container'>
                            <span role='alert' className='text-danger'>
                                {formik.errors.pfn_username}
                            </span>
                        </div>
                    )}
                </div>
                {/* End Form Row */}

                {/* Start Form Row */}
                <div className='fv-row mb-10'>
                    <div className='d-flex justify-content-between mt-n5'>
                        <div className='d-flex flex-stack mb-2'>
                            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
                            <Link
                                to='/auth/forgot-password'
                                className='link-primary fs-6 fw-bolder'
                                style={{ marginLeft: '5px' }}
                                tabIndex={-1}
                            >
                                Forgot Password?
                            </Link>
                        </div>
                    </div>

                    <div className='password-input-container'>
                        <input
                            type={hidden ? 'password' : 'text'}
                            autoComplete='off'
                            {...formik.getFieldProps('pfn_password')}
                            className={clsx('form-control form-control-lg form-control-solid password-input', {
                                'is-invalid': formik.touched.pfn_password && formik.errors.pfn_password,
                            })}
                        />

                        <i
                            className={clsx('bi fs-1 password-toggle', {
                                'bi-eye': hidden,
                                'bi-eye-slash': !hidden,
                            })}
                            onClick={() => {
                                setHidden(!hidden);
                            }}
                        ></i>
                    </div>
                    {formik.touched.pfn_password && formik.errors.pfn_password && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                    {formik.errors.pfn_password}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}

                {/* Start Form Row */}
                <div className='text-center'>
                    <button
                        type='submit'
                        id='kt_sign_in_submit'
                        className='btn btn-lg btn-primary w-100 mb-5'
                        disabled={formik.isSubmitting || loading}
                    >
                        {!loading && <span className='indicator-label'>Sign In</span>}
                        {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Please wait...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
                {/* End Form Row */}
            </form>
            {errMsg && <div className='text-danger text-center'>{errMsg}</div>}
            <div className='fv-row mt-1 px-1 text-center'>
                {errMsg === 'User is not verified' ? (
                    verificationEmailSent ? (
                        <span className=' ms-3 text-primary'>{verificationResponseMsg}</span>
                    ) : (
                        <span
                            className='btn-link link-primary cursor-pointer hover ms-3'
                            onClick={resendVerificationEmail}
                        >
                            re-send verification email?
                        </span>
                    )
                ) : errMsg === 'User does not have a password' ? (
                    <Link to='/auth/forgot-password' className='link-primary fs-6' style={{ marginLeft: '5px' }}>
                        Set new password
                    </Link>
                ) : (
                    ''
                )}
            </div>
            <div className='text-gray-800 mt-5'>
                <span className='fw-bolder'>Note: </span>Since October 2023, the CVBC is using a new platform for its
                Registrant Portal. As it is a separate site from the CVBC website, any registrant logging into their
                online account for the first time since the change will need to set a new password. To set your new
                password,{' '}
                <Link to='/auth/forgot-password' className='text-decoration-underline fw-bolder'>
                    please click here
                </Link>
                .
            </div>
        </div>
    );
};

/* -------------------------------------------------------------------------- */
