/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useState } from 'react';
import { KTSVG } from '../../../../../../../_metronic/helpers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { closeAllModals, convertPhoneNumberToHyphens } from '../../../../../../helpers';
import applicationApi from '../../../../../../api/applicationApi';
import { Application, EmploymentHistory } from '../../../../../../types';
import MaskedInput from 'react-text-mask';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

interface Props {
    employment: EmploymentHistory;
    application: Application;
    modalName: string;
    getCurrentEmployments: Function;
    yearsRange: number[];
    monthsRange: number[];
}

/* -------------------------------------------------------------------------- */

/* ------------------------------- FORM SCHEMA ------------------------------ */

const phoneRegExp = /^\d{3}[-]\d{3}[-]\d{4}$/;

const validationSchema = Yup.object()
    .shape({
        pfn_name: Yup.string().required('Employment name is required'),
        pfn_businesslocation: Yup.string().required('Employment location is required'),
        pfn_jobtitle: Yup.string().required('Job title is required'),
        pfn_employer: Yup.string().required('Employer or supervisor name is required'),
        pfn_email: Yup.string().email('Wrong email format').required('Employer email is required'),
        pfn_phone: Yup.string()
            .matches(phoneRegExp, 'Phone number is not valid')
            .required('Employer phone number is required'),
        pfn_fromyear: Yup.string()
            .required('From year is required')
            .test('checkYearRange', 'Must be no greater than 50 years ago', async (val) => {
                return Number(val) >= new Date().getFullYear() - 50;
            }),
        pfn_toyear: Yup.string().required('To year is required'),
        pfn_frommonth: Yup.string()
            .required('From month is required')
            .test('checkMonthRange', 'Must be less than or equal to 12 months', async (val) => {
                return Number(val) > 0 && Number(val) <= 12;
            }),
        pfn_tomonth: Yup.string()
            .required('To month is required')
            .when('pfn_fromyear', {
                is: (val: string) => val,
                then: Yup.string().test('checkMonthRange', 'Must be less than or equal to 12 months', async (val) => {
                    return val === 'present' || (Number(val) > 0 && Number(val) <= 12);
                }),
            }),
        pfn_description: Yup.string().required('Description is required'),
    })
    .test({
        name: 'ToDateMustBeAfterFromDate',
        test: function (values) {
            if (values.pfn_toyear && values.pfn_tomonth && values.pfn_fromyear && values.pfn_frommonth) {
                if (values.pfn_fromyear === values.pfn_toyear) {
                    if (values.pfn_tomonth === 'present' || values.pfn_frommonth <= values.pfn_tomonth) {
                        return true;
                    } else {
                        return this.createError({
                            path: 'pfn_tomonth',
                            message: 'To date must be after from date',
                        });
                    }
                } else {
                    if (values.pfn_toyear === 'present' || values.pfn_fromyear <= values.pfn_toyear) {
                        return true;
                    } else {
                        return this.createError({
                            path: 'pfn_toyear',
                            message: 'To date must be after from date',
                        });
                    }
                }
            } else {
                return true;
            }
        },
    })
    .test({
        name: 'PresentCheck',
        test: function (values) {
            if (values.pfn_toyear && values.pfn_tomonth) {
                if (
                    (values.pfn_toyear === 'present' && values.pfn_tomonth === 'present') ||
                    (values.pfn_toyear !== 'present' && values.pfn_tomonth !== 'present')
                ) {
                    return true;
                } else {
                    return this.createError({
                        path: 'pfn_tomonth',
                        message: 'To year and month must both be present if present is selected',
                    });
                }
            } else {
                return true;
            }
        },
    });

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const EditEmploymentBtn: FC<Props> = ({
    employment,
    application,
    modalName,
    getCurrentEmployments,
    yearsRange,
    monthsRange,
}) => {
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);

    const initialValues = {
        pfn_name: employment.pfn_name ? employment.pfn_name : '',
        pfn_businesslocation: employment.pfn_businesslocation ? employment.pfn_businesslocation : '',
        pfn_jobtitle: employment.pfn_jobtitle ? employment.pfn_jobtitle : '',
        pfn_employer: employment.pfn_employer ? employment.pfn_employer : '',
        pfn_email: employment.pfn_email ? employment.pfn_email : '',
        pfn_phone: employment.pfn_phone ? convertPhoneNumberToHyphens(employment.pfn_phone) : '',
        pfn_fromyear: employment.pfn_fromyear ? employment.pfn_fromyear : '',
        pfn_frommonth: employment.pfn_frommonth ? String(employment.pfn_frommonth) : '',
        pfn_toyear: employment.pfn_toyear ? employment.pfn_toyear : 'present',
        pfn_tomonth: employment.pfn_tomonth ? String(employment.pfn_tomonth) : 'present',
        pfn_description: employment.pfn_description ? employment.pfn_description : '',
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                setErrMsg(null);
                setLoading(true);
                if (!employment.pfn_employmenthistoryid) {
                    throw new Error('EmploymentHistory selected has no id');
                }
                if (values.pfn_toyear === 'present') {
                    values.pfn_toyear = '';
                }
                if (values.pfn_tomonth === 'present') {
                    values.pfn_tomonth = '';
                }

                const response = await applicationApi.editEmploymentHistory(employment.pfn_employmenthistoryid, values);
                if (response) {
                    if (!response.success) {
                        if (response.message) {
                            setErrMsg(response.message);
                        } else {
                            setErrMsg('An error has occured, please try again later');
                        }
                    } else {
                        closeAllModals();
                        getCurrentEmployments();
                    }
                } else {
                    setErrMsg('An error has occured, please try again later');
                }

                setLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
                setLoading(false);
            }
        },
    });

    const RenderEditEmploymentModal = () => {
        return (
            <div className='modal fade' tabIndex={-1} id={modalName}>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h3 className='modal-title'>Edit Employment</h3>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
                            </div>
                        </div>
                        <div className='modal-body'>{RenderEditEmploymentForm()}</div>
                    </div>
                </div>
            </div>
        );
    };

    const RenderEditEmploymentForm = () => {
        return (
            <form className='form w-100 text-start' noValidate id='editEmploymentForm'>
                {/* Start Form Row */}
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Name of Place of Employment</label>

                    <input
                        {...formik.getFieldProps('pfn_name')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_name && formik.errors.pfn_name,
                        })}
                        name='pfn_name'
                        autoComplete='off'
                    />

                    {formik.touched.pfn_name && formik.errors.pfn_name && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_name}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>
                        Location of Place of Employment
                    </label>

                    <input
                        {...formik.getFieldProps('pfn_businesslocation')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_businesslocation && formik.errors.pfn_businesslocation,
                        })}
                        name='pfn_businesslocation'
                        autoComplete='off'
                    />

                    {formik.touched.pfn_businesslocation && formik.errors.pfn_businesslocation && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_businesslocation}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Job Title</label>

                    <input
                        {...formik.getFieldProps('pfn_jobtitle')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_jobtitle && formik.errors.pfn_jobtitle,
                        })}
                        name='pfn_jobtitle'
                        autoComplete='off'
                    />

                    {formik.touched.pfn_jobtitle && formik.errors.pfn_jobtitle && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_jobtitle}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Supervisor/Employer Name</label>

                    <input
                        {...formik.getFieldProps('pfn_employer')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_employer && formik.errors.pfn_employer,
                        })}
                        name='pfn_employer'
                        autoComplete='off'
                    />

                    {formik.touched.pfn_employer && formik.errors.pfn_employer && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_employer}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Supervisor/Employer Email</label>
                    <input
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('pfn_email')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_email && formik.errors.pfn_email,
                        })}
                    />
                    {formik.touched.pfn_email && formik.errors.pfn_email && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_email}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6 required'>
                        Supervisor/Employer Telephone
                    </label>
                    <MaskedInput
                        mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        {...formik.getFieldProps('pfn_phone')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_phone && formik.errors.pfn_phone,
                        })}
                    />
                    {formik.touched.pfn_phone && formik.errors.pfn_phone && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_phone}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>From Year</label>
                    <select
                        {...formik.getFieldProps('pfn_fromyear')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_fromyear && formik.errors.pfn_fromyear,
                        })}
                        name='pfn_fromyear'
                    >
                        <option disabled value=''>
                            Select Year
                        </option>
                        {yearsRange?.map((year, i) => {
                            return (
                                <option key={i} value={String(year)}>
                                    {year}
                                </option>
                            );
                        })}
                    </select>
                    {formik.touched.pfn_fromyear && formik.errors.pfn_fromyear && (
                        <div className='fv-plugins-message-container'>
                            <span className='text-danger' role='alert'>
                                {formik.errors.pfn_fromyear}
                            </span>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>From Month</label>
                    <select
                        {...formik.getFieldProps('pfn_frommonth')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_frommonth && formik.errors.pfn_frommonth,
                        })}
                        name='pfn_frommonth'
                    >
                        <option disabled value=''>
                            Select Year
                        </option>
                        {monthsRange?.map((month, i) => {
                            return (
                                <option key={i} value={String(month)}>
                                    {month}
                                </option>
                            );
                        })}
                    </select>
                    {formik.touched.pfn_frommonth && formik.errors.pfn_frommonth && (
                        <div className='fv-plugins-message-container'>
                            <span className='text-danger' role='alert'>
                                {formik.errors.pfn_frommonth}
                            </span>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>To Year</label>
                    <select
                        {...formik.getFieldProps('pfn_toyear')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_toyear && formik.errors.pfn_toyear,
                        })}
                        name='pfn_toyear'
                    >
                        <option disabled value=''>
                            Select Year
                        </option>
                        <option key='present' value='present'>
                            Present
                        </option>
                        {yearsRange?.map((year, i) => {
                            return (
                                <option key={i} value={String(year)}>
                                    {year}
                                </option>
                            );
                        })}
                    </select>
                    {formik.touched.pfn_toyear && formik.errors.pfn_toyear && (
                        <div className='fv-plugins-message-container'>
                            <span className='text-danger' role='alert'>
                                {formik.errors.pfn_toyear}
                            </span>
                        </div>
                    )}
                </div>
                {/* End Form Row */}

                {/* Start Form Row */}
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>To Month</label>
                    <select
                        {...formik.getFieldProps('pfn_tomonth')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_tomonth && formik.errors.pfn_tomonth,
                        })}
                        name='pfn_tomonth'
                    >
                        <option disabled value=''>
                            Select Year
                        </option>
                        <option key='present' value='present'>
                            Present
                        </option>
                        {monthsRange?.map((month, i) => {
                            return (
                                <option key={i} value={String(month)}>
                                    {month}
                                </option>
                            );
                        })}
                    </select>
                    {formik.touched.pfn_tomonth && formik.errors.pfn_tomonth && (
                        <div className='fv-plugins-message-container'>
                            <span className='text-danger' role='alert'>
                                {formik.errors.pfn_tomonth}
                            </span>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-7'>
                    <label className='form-label fw-bolder text-dark fs-6 required'>Description of Employment</label>

                    <textarea
                        autoComplete='off'
                        rows={5}
                        {...formik.getFieldProps('pfn_description')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_description && formik.errors.pfn_description,
                        })}
                    />
                    {formik.touched.pfn_description && formik.errors.pfn_description && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_description}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}

                <div className='d-flex align-items-center mb-10'>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                </div>

                <div className='fv-row text-start'>
                    <div className='d-flex justify-content-end'>
                        <button type='button' className='btn btn-light me-3' data-bs-dismiss='modal'>
                            Cancel
                        </button>

                        <button
                            type='button'
                            className='btn btn-primary'
                            disabled={formik.isSubmitting || loading}
                            onClick={() => {
                                formik.handleSubmit();
                            }}
                        >
                            {!loading && <span className='indicator-label'>Update</span>}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </div>
                {errMsg && <div className='text-danger text-center mt-7'>{errMsg}</div>}
            </form>
        );
    };

    return (
        <>
            <button
                type='button'
                className='btn btn-primary btn-sm btn-icon me-2'
                data-bs-toggle='modal'
                data-bs-target={`#${modalName}`}
                onClick={() => {
                    formik.resetForm();
                    setErrMsg(null);
                }}
            >
                <i className='bi bi-pencil-square fs-2'></i>
            </button>

            {RenderEditEmploymentModal()}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { EditEmploymentBtn };

/* -------------------------------------------------------------------------- */
