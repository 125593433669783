import { FacilityAssessment } from '../../../../../types';
import { TEXT_FACILITIES_ANNUAL_DECLARATIONS_PUBLICATIONS1, TEXT_FACILITIES_ANNUAL_DECLARATIONS_PUBLICATIONS2 } from '../../../../../constants';

/**
 * Generates the PDF content from a facility assessment.
 * @param {FacilityAssessment} assessment.
 * @returns {{ content: { text: string; bold?: boolean; line?: boolean }[] }} - An object containing the text content for the PDF with formatting.
 */
export const generatePublicationContentForPDF = (assessment: FacilityAssessment) => {
    const introText = TEXT_FACILITIES_ANNUAL_DECLARATIONS_PUBLICATIONS1.value;
    const conclusionText = 'Please Note: ' + TEXT_FACILITIES_ANNUAL_DECLARATIONS_PUBLICATIONS2.value;

    const publicationContent: { text: string; bold?: boolean, line?: boolean  }[] = [
        { text: introText, bold: false },
        { text: '', line: true },
        { text: 'You chose the following option for your facility\'s publication:', bold: false }
    ];

    const publicationValue = assessment.pfn_onlinepublication || '';

    if (publicationValue == '229380000') {
        publicationContent.push({ text: 'I would like to have all my practice facility’s business information listed.', bold: true });
    } else if (publicationValue == '229380002') {
        publicationContent.push({ text: 'I do not want to list the street address of my practice facility.', bold: true });
    } else {
        publicationContent.push({ text: 'No specific publication preference selected.', bold: true });
    }

    publicationContent.push({ text: '', line: true }); // Line separator
    publicationContent.push({ text: conclusionText, bold: false });

    return {
        content: publicationContent,
    };
};