/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useEffect, useState } from 'react';
import applicationApi from '../../../../../../api/applicationApi';
import { Application, RegAppSummary } from '../../../../../../types';
import { useAppSelector } from '../../../../../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { toAbsoluteUrl } from '../../../../../../../_metronic/helpers';
import { convertPhoneNumberToHyphens } from '../../../../../../helpers';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

interface Props {
    goToPreviousStep: Function;
    application: Application;
}

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const SummaryForm: FC<Props> = ({ goToPreviousStep, application }) => {
    const [formLoading, setFormLoading] = useState(false);
    const [formErrMsg, setFormErrMsg] = useState<string | null>(null);
    const [pageLoading, setPageLoading] = useState(true);
    const [pageErrMsg, setPageErrMsg] = useState<string | null>(null);
    const [summary, setSummary] = useState<RegAppSummary | null>(null);
    const user = useAppSelector((state) => state.user);
    const navigate = useNavigate();

    useEffect(() => {
        const getRegAppSummaryAsync = async () => {
            try {
                setPageLoading(true);
                setPageErrMsg(null);
                const response = await applicationApi.getRegAppSummary(application.pfn_applicationid);

                if (response) {
                    if (!response.success) {
                        setPageErrMsg('An error has occurred, please try again later');
                    } else {
                        if (response.data) {
                            setSummary(response.data);
                        }
                    }
                } else {
                    setPageErrMsg('An error has occurred, please try again later');
                }

                setPageLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setPageErrMsg(err.message);
                } else {
                    setPageErrMsg('An error has occurred, please try again later');
                }
                setPageLoading(false);
            }
        };

        getRegAppSummaryAsync();

        return () => {
            setPageErrMsg(null);
            setPageLoading(false);
            setSummary(null);
        };
    }, [application.pfn_applicationid]);

    const handleBackClick = async () => {
        if (summary?.application.pfn_fomcomplete) {
            navigate('/applications/registration');
        } else {
            goToPreviousStep();
        }
    };

    const handleSubmitClick = async () => {
        try {
            setFormErrMsg(null);
            setFormLoading(true);

            const response = await applicationApi.submitRegAppSummary(application.pfn_applicationid);

            if (response) {
                if (!response.success) {
                    if (response.message) {
                        setFormErrMsg(response.message);
                    } else {
                        setFormErrMsg('An error has occured, please try again later');
                    }
                } else {
                    navigate('/applications/registration');
                }
            } else {
                setFormErrMsg('An error has occured, please try again later');
            }

            setFormLoading(false);
        } catch (err) {
            if (err instanceof Error) {
                setFormErrMsg(err.message);
            } else {
                setFormErrMsg('An error has occurred, please try again later');
            }
            setFormLoading(false);
        }
    };

    return (
        <>
            {pageLoading ? (
                <div>Loading summary, please wait...</div>
            ) : pageErrMsg || !summary ? (
                <div>{pageErrMsg}</div>
            ) : (
                <div className='summary-page'>
                    <div className='no-print'>
                        <div className='note-card d-flex align-items-center bg-light rounded p-5 mb-15'>
                            <div className='note-card-inner flex-grow-1 me-2'>
                                <div className='text-gray-800'>
                                    <span className='fw-bolder'>Note: </span>Below is a summary of all information
                                    provided so far. Please review it for accuracy and print a copy before you “submit”.
                                    You will need to have the paper version of the application witnessed by a notary
                                    before you submit the document in original form to the CVBC office. Please make sure
                                    that all the sections in this page are included in the printing area. Google Chrome
                                    or Microsoft Edge are recommended for the best printing result. (your printed form
                                    may be missing sections if you use a different browser).
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='print summary-header'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <img
                                alt='logo'
                                className='h-100px'
                                src={toAbsoluteUrl('/media/logos/cvbc_logo_trans_blue.png')}
                            />
                            <div className='passport-photo-box d-flex flex-column align-items-center justify-content-center'>
                                <h3>Attach</h3>
                                <h3>Passport</h3>
                                <h3>Photo</h3>
                            </div>
                        </div>
                    </div>

                    <div className='print-space-350'></div>

                    <h2 className='text-primary mb-10'>Application for {summary.application.pfn_purpose_Formatted}</h2>
                    <div>
                        <div className='mb-5'>
                            Complete this application form fully and precisely;{' '}
                            <span className='fw-bolder'>
                                omissions or inaccuracies in your answers may delay your licensure.
                            </span>{' '}
                            The declaration must be made before a notary public or a commissioner. If the space provided
                            for any answers is insufficient, complete your answer on a separate sheet.{' '}
                            <span className='fw-bolder'>
                                Please review eligibility requirements before completing or filing this form.
                            </span>
                        </div>

                        <div className='mb-5'>
                            The process of registration with the CVBC is governed by the Bylaws, Part 2 – Registration.
                        </div>
                        <div className='mb-10'>
                            Please send the original signed 'Application for Registration' Form, plus all additional
                            sheets and supporting documents, to the CVBC at:
                            <span className='fw-bolder'>"210 – 10991 Shellbridge Way, Richmond, BC V6X 3C6"</span>. Once
                            the electronic version of this application is submitted, an invoice will be generated and
                            uploaded to your applicant file – this can be paid online or by cheque or money order
                            submitted by mail/in person to the CVBC office, to the attention of the Registration
                            Coordinator. <span className='fw-bolder'>Payment MUST be in Canadian funds.</span>
                        </div>
                    </div>
                    {/* <div className='summary-footer w-100'>
                        <div className='d-flex justify-content-between'>
                            <div>CVBC</div>
                            <div className='me-5'>1/7</div>
                        </div>
                    </div> */}
                    <div className='pagebreak'></div>

                    <h3 className='text-primary mb-7'>
                        To the Registrar of the College of Veterinarians of British Columbia:
                    </h3>

                    <div className='mb-15'>
                        <h4 className='text-primary text-decoration-underline mb-5'>Contact Information</h4>
                        {user.address.mailing === 'Home' ? (
                            <div className='mb-7'>
                                <div className='text-center table-container'>
                                    <Table className='summary-user-info-table mb-1' striped bordered>
                                        <tbody>
                                            <tr>
                                                <td className='first'>
                                                    <span className='fw-bolder'>First Name: </span>
                                                    {user.firstname}
                                                </td>
                                                <td className='middle'>
                                                    <span className='fw-bolder'>Middle Name: </span>
                                                    {user.middlename}
                                                </td>
                                                <td className='last'>
                                                    <span className='fw-bolder'>Last Name: </span>
                                                    {user.lastname}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='line1'>
                                                    <span className='fw-bolder'>Street Address: </span>
                                                    {user.address.home.line1}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='city'>
                                                    <span className='fw-bolder'>City: </span>
                                                    {user.address.home.city}
                                                </td>
                                                <td className='province'>
                                                    <span className='fw-bolder'>Province: </span>
                                                    {user.address.home.province}
                                                </td>
                                                <td className='country'>
                                                    <span className='fw-bolder'>Country: </span>
                                                    {user.address.home.country}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='postal'>
                                                    <span className='fw-bolder'>Postal Code: </span>
                                                    {user.address.home.postalCode}
                                                </td>
                                                <td className='phone'>
                                                    <span className='fw-bolder'>Phone: </span>
                                                    {user.phone.mobile}
                                                </td>
                                                <td className='email'>
                                                    <span className='fw-bolder'>Email: </span>
                                                    {user.email.main}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                                <div className='fst-italic'>
                                    You must provide complete and current contact information to the CVBC and keep this
                                    information up to date with the CVBC at all times.
                                </div>
                            </div>
                        ) : (
                            <div className='mb-7'>
                                <div className='text-center table-container'>
                                    <Table className='summary-user-info-table mb-1' striped bordered>
                                        <tbody>
                                            <tr>
                                                <td className='first'>
                                                    <span className='fw-bolder'>First Name: </span>
                                                    {user.firstname}
                                                </td>
                                                <td className='middle'>
                                                    <span className='fw-bolder'>Middle Name: </span>
                                                    {user.middlename}
                                                </td>
                                                <td className='last'>
                                                    <span className='fw-bolder'>Last Name: </span>
                                                    {user.lastname}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='line1'>
                                                    <span className='fw-bolder'>Street Address: </span>
                                                    {user.facilities.primary.address.line1}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='city'>
                                                    <span className='fw-bolder'>City: </span>
                                                    {user.facilities.primary.address.city}
                                                </td>
                                                <td className='province'>
                                                    <span className='fw-bolder'>Province: </span>
                                                    BC
                                                </td>
                                                <td className='country'>
                                                    <span className='fw-bolder'>Country: </span>
                                                    Canada
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='postal'>
                                                    <span className='fw-bolder'>Postal Code: </span>
                                                    {user.facilities.primary.address.postalCode}
                                                </td>
                                                <td className='phone'>
                                                    <span className='fw-bolder'>Phone: </span>
                                                    {user.phone.mobile}
                                                </td>
                                                <td className='email'>
                                                    <span className='fw-bolder'>Email: </span>
                                                    {user.email.main}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                                <div className='fst-italic'>
                                    You must provide complete and current contact information to the CVBC and keep this
                                    information up to date with the CVBC at all times.
                                </div>
                            </div>
                        )}
                    </div>

                    <div className='mb-15'>
                        <h3 className='text-primary text-decoration-underline mb-5'>
                            Government Issued Identification
                        </h3>
                        <div className='fw-bolder mb-2'>
                            I include the following pieces of current Government-issued identification (two pieces, one
                            of which must include a photo):
                        </div>
                        <div>
                            <div>
                                <i className='bi bi-square me-2'></i>
                                <span>Driver’s License</span>
                            </div>
                            <div>
                                <i className='bi bi-square me-2'></i>
                                <span>Provincial ID</span>
                            </div>
                            <div>
                                <i className='bi bi-square me-2'></i>
                                <span>Passport</span>
                            </div>
                            <div>
                                <i className='bi bi-square me-2'></i>
                                <span>Other:</span>
                                <span className='blank-answer-space-400'></span>
                            </div>
                            <div className='fst-italic'>
                                You must provide certified true copies (by a commissioner of oaths or notary public in
                                Canada or other official empowered by statute).
                            </div>
                        </div>
                    </div>

                    <div className='mb-15'>
                        <h3 className='text-primary text-decoration-underline mb-5'>Application Class</h3>
                        <div className='fw-bolder'>
                            I hereby make application for registration for the following class of registration:
                        </div>
                        <div>
                            <span className='fw-bolder'>Class:</span>
                            <span className='answer-space-400'>
                                {summary.application._pfn_applicationtypeid_value_Formatted}
                            </span>
                        </div>
                    </div>
                    <div className='mb-15'>
                        <h3 className='text-primary mb-5 text-decoration-underline'>Place of Employment</h3>
                        <div className='fw-bolder'>Intended Place of Employment (once registered with CVBC):</div>
                        <div className='mb-2'>
                            <span className='fw-bolder'>Practice Name:</span>
                            <span className='answer-space-400'>{summary.application.pfn_practicename}</span>
                        </div>
                        <div className='fst-italic'>
                            If you have secured employment, please provide the practice name and fill out a Contact and
                            Practice Information Form. If you do not yet have a position secured, you may write
                            “pending” in the space provided above. You must inform the College promptly upon securing a
                            position by submitting the 'Contact and Practice Information Form' to the College (e-mail to
                            registration@cvbc.ca). Pursuant to s. 3.5 of the bylaws, a registrant must only provide
                            veterinary services in or from an accredited practice facility. Failure to notify the
                            College of the name of the practice facility from which you will be practicing may result in
                            the revocation of your registration.
                        </div>
                    </div>

                    <div className='pagebreak'></div>

                    <div className='mb-15'>
                        <h3 className='text-primary mb-5 text-decoration-underline'>Personal Information</h3>
                        <h5 className='text-primary mb-2'>
                            I declare the following to be true, accurate and complete:
                        </h5>
                        <ol className='bold-ol'>
                            <li className='mb-5'>
                                <div className='fw-bolder'>My immigration status in Canada is:</div>
                                <div className='mb-1'>
                                    <span className='fw-bolder'>Immigration Status:</span>
                                    <span className='answer-space-500'>
                                        {user.immigrationStatus === 'Work Permit'
                                            ? 'Canadian work permit allowing employment as a veterinarian.'
                                            : user.immigrationStatus}
                                    </span>
                                </div>

                                <div className='fst-italic'>
                                    You must provide proof by way of the original or a certified true copy. If any of
                                    your supporting documents are not in English, you must provide official translations
                                    of the documents.
                                </div>
                            </li>

                            <li className='mb-5'>
                                <div className='fw-bolder mb-2'>
                                    I graduated (or I am about to graduate) with a degree or diploma in veterinary
                                    medicine from:
                                </div>
                                <div className='text-start table-container'>
                                    <Table className='summary-educations-table mb-1' striped bordered>
                                        <thead>
                                            <tr>
                                                <th className='fw-bolder'>Education Degree</th>
                                                <th className='fw-bolder'>Year</th>
                                                <th className='fw-bolder'>School / Institution</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {summary.educations?.map((education, i) => {
                                                if (education._pfn_educationtypeid_value_Formatted !== 'Diplomate') {
                                                    return (
                                                        <tr key={i}>
                                                            <td className='type'>
                                                                {education._pfn_educationtypeid_value_Formatted}
                                                            </td>
                                                            <td className='year'>{education.pfn_year}</td>
                                                            <td className='school'>
                                                                {education._pfn_accountid_value_Formatted ||
                                                                    education.pfn_otherinstitutionname}
                                                            </td>
                                                        </tr>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                                <div className='fst-italic'>
                                    You must provide the original or a certified true copy of your veterinary degrees or
                                    diplomas. If you did not obtain instruction in English, you will be required to
                                    demonstrate English language proficiency (valid test score, obtained within 2 years
                                    of application). If any of your supporting documents are not in English, you must
                                    provide official translations of the documents.
                                </div>
                            </li>

                            <li className='mb-7'>
                                <div className='fw-bolder'>
                                    I have received a Certificate of Qualification (CQ) that was assessed in the English
                                    language from the National Examining Board (NEB) of the Canadian Veterinary Medical
                                    Association (CVMA):
                                </div>
                                {summary.application.pfn_certificateofqualification ? (
                                    <div>
                                        <div>
                                            <span className='fw-bolder'>Answer:</span>
                                            <span className='answer-space-200'>Yes, I have received it.</span>
                                        </div>

                                        <div>
                                            <span className='fw-bolder'>Date of CQ:</span>
                                            <span className='answer-space-200'>
                                                {summary.application.pfn_dateofcq_Formatted}
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div>
                                            <span className='fw-bolder'>Answer:</span>
                                            <span className='answer-space-200'>No, I have not received it.</span>
                                        </div>
                                        <div>
                                            <span className='fw-bolder'>Explanation: </span>
                                            {summary.application.pfn_explanation}
                                        </div>
                                    </div>
                                )}
                                <div className='fst-italic mt-2'>
                                    If you answered ‘Yes’, then you must provide a certified true copy. If any of your
                                    supporting documents are not in English, you must provide official translations of
                                    the documents.
                                </div>
                                <div className='fst-italic'>
                                    A CQ is not required for Specialty Private Practice (SPP) or Public Sector (PS)
                                    classes of registration.
                                </div>
                            </li>
                            {summary.educations.some(
                                (education) => education._pfn_educationtypeid_value_Formatted === 'Diplomate',
                            ) ? (
                                <li className='mb-7'>
                                    <div className='fw-bolder mb-2'>
                                        I hold a Diplomate Certificate issued by a College recognized by the ABVS/EBVS.
                                    </div>
                                    <div className='text-start table-container'>
                                        <Table className='summary-educations-table mb-1' striped bordered>
                                            <thead>
                                                <tr>
                                                    <th className='fw-bolder'>Area of Specialty & Credentials</th>
                                                    <th className='fw-bolder'>Year</th>
                                                    <th className='fw-bolder'>School / Institution</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {summary.educations?.map((education, i) => {
                                                    if (
                                                        education._pfn_educationtypeid_value_Formatted === 'Diplomate'
                                                    ) {
                                                        return (
                                                            <tr key={i}>
                                                                <td className='type'>
                                                                    {
                                                                        education._pfn_educationdiplomateid_value_Formatted
                                                                    }
                                                                </td>
                                                                <td className='year'>{education.pfn_year}</td>
                                                                <td className='school'>
                                                                    {education._pfn_accountid_value_Formatted ||
                                                                        education.pfn_otherinstitutionname}
                                                                </td>
                                                            </tr>
                                                        );
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                    <div className='fst-italic mb-2'>
                                        You must provide the original or a certified true copy of your specialist
                                        diploma, and a letter from the certifying board (ABVS/EBVS) verifying your
                                        status. The CVBC will also accept a letter provided directly from the certifying
                                        board that stated the details of your credentials and your current
                                        status/standing. If any of your supporting documents are not in English, you
                                        must provide official translations of the documents.
                                    </div>
                                    <div className='fst-italic'>
                                        All classes of registrants must disclose any diplomate certifications and
                                        promptly notify the CVBC of any change to your diplomate status.
                                    </div>
                                </li>
                            ) : (
                                <li className='mb-7'>
                                    <div className='fw-bolder mb-2'>I don't hold a Diplomate Certificate.</div>
                                    <div className='fst-italic'>
                                        All classes of registrants must disclose any diplomate certifications and
                                        promptly notify the CVBC of any change to your diplomate status.
                                    </div>
                                </li>
                            )}

                            <div className='pagebreak'></div>

                            <li className='mb-7'>
                                <div className='fw-bolder mb-2'>
                                    My paid or volunteer employment history for the past 5 years (attach additional
                                    sheets if necessary):
                                </div>
                                {summary.employmentHistories?.map((employment, i) => {
                                    return (
                                        <div key={i} className='mb-7'>
                                            <div className='text-start table-container'>
                                                <Table className='summary-employments-table mb-2' striped bordered>
                                                    <thead>
                                                        <tr>
                                                            <th className='fw-bolder'>Employment Name</th>
                                                            <th className='fw-bolder'>Location</th>
                                                            <th className='fw-bolder'>Job Title</th>
                                                            <th className='fw-bolder'>From - To</th>
                                                            <th className='fw-bolder'>Employer Name</th>
                                                            <th className='fw-bolder'>Employer Phone</th>
                                                            <th className='fw-bolder'>Employer Email</th>
                                                            {/* <th className='fw-bolder'>Description</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className='employment-name'>{employment.pfn_name}</td>
                                                            <td className='location'>
                                                                {employment.pfn_businesslocation}
                                                            </td>
                                                            <td className='title'>{employment.pfn_jobtitle}</td>
                                                            <td className='year'>
                                                                {employment.pfn_frommonth}/{employment.pfn_fromyear} -{' '}
                                                                {employment.pfn_toyear ? `${employment.pfn_tomonth}/${employment.pfn_toyear}` : 'Present'}
                                                            </td>
                                                            <td className='employer-name'>{employment.pfn_employer}</td>
                                                            <td className='employer-phone'>
                                                                {convertPhoneNumberToHyphens(employment.pfn_phone)}
                                                            </td>
                                                            <td className='employer-email'>{employment.pfn_email}</td>
                                                            {/* <td className='description'>{employment.pfn_description}</td> */}
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                            <div>
                                                <span className='fw-bolder'>Description: </span>
                                                {employment.pfn_description}
                                            </div>
                                        </div>
                                    );
                                })}
                            </li>

                            <div className='pagebreak'></div>

                            <li className='mb-7'>
                                <div className='fw-bolder mb-2'>
                                    I practised veterinary medicine in the following jurisdictions (attach additional
                                    sheets if necessary). New graduates must list any jurisdictions where they worked as
                                    a student veterinarian or held student licensure.
                                </div>
                                <div>
                                    <div className='text-start table-container'>
                                        <Table className='summary-jurisdictions-table mb-1' striped bordered>
                                            <thead>
                                                <tr>
                                                    <th className='fw-bolder'>Regulatory Body</th>
                                                    <th className='fw-bolder'>Type of License</th>
                                                    <th className='fw-bolder'>License Number</th>
                                                    <th className='fw-bolder'>From - To</th>
                                                    <th className='fw-bolder'>Time spent in practice</th>
                                                    <th className='fw-bolder'>
                                                        Scope of Practice (species, services offered, etc.)
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {summary.jurisdictions?.map((jurisdiction, i) => {
                                                    // Determine the start date
                                                    const startDate = jurisdiction.pfn_frommonth === 13 
                                                        ? jurisdiction.pfn_fromyear 
                                                        : `${jurisdiction.pfn_frommonth}/${jurisdiction.pfn_fromyear}`;

                                                    // Determine the end date
                                                    const endDate = 
                                                    (jurisdiction.pfn_toyear !== null && jurisdiction.pfn_toyear !== 'Present')
                                                    ? (
                                                        jurisdiction.pfn_tomonth === 13 ? jurisdiction.pfn_toyear : `${jurisdiction.pfn_tomonth}/${jurisdiction.pfn_toyear}` 
                                                    ) : 'Present';

                                                    // Combine start and end dates
                                                    const dateRange = `${startDate} - ${endDate}`;

                                                    return (
                                                        <tr key={i}>
                                                            <td className='body'>{jurisdiction.pfn_name}</td>
                                                            <td className='type'>{jurisdiction.pfn_typeoflicense}</td>
                                                            <td className='number'>{jurisdiction.pfn_licensenumber}</td>
                                                            <td className='date'>{dateRange}</td>
                                                            <td className='time'>
                                                                {jurisdiction.pfn_practicetimeyears} years,{' '}
                                                                {jurisdiction.pfn_practicetimemonths} months
                                                            </td>
                                                            <td className='scope'>{jurisdiction.pfn_details}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                <div className='fst-italic mb-2'>
                                    We will require a letter of standing and registration from each jurisdiction.
                                </div>
                                <div>
                                    If you have ever practiced veterinary medicine under a different first name or
                                    surname formally or informally, please provide particulars:
                                    <span className='blank-answer-space-200'></span>
                                </div>
                            </li>

                            <div className='pagebreak'></div>

                            {summary.questions.map((question, i) => {
                                if (question.pfn_answer_Formatted === 'Yes') {
                                    return (
                                        <li key={i} className='mb-7'>
                                            <div className='fw-bolder'>
                                                {question.pfn_details}
                                                {!question.pfn_details?.endsWith('?') && '?'}
                                            </div>
                                            <div>
                                                <span className='fw-bolder'>Answer:</span>
                                                <span className='answer-space-30'>Yes</span>
                                            </div>
                                            <div>
                                                <span className='fw-bolder'>Explanation: </span>
                                                {question.pfn_explanation}
                                            </div>
                                        </li>
                                    );
                                } else {
                                    return (
                                        <li key={i} className='mb-7'>
                                            <div className='fw-bolder'>
                                                {question.pfn_details}
                                                {!question.pfn_details?.endsWith('?') && '?'}
                                            </div>
                                            <div>
                                                <span className='fw-bolder'>Answer:</span>
                                                <span className='answer-space-30'>No</span>
                                            </div>
                                        </li>
                                    );
                                }
                            })}
                        </ol>

                        <div className='fw-bolder'>
                            I, {user.firstname} {user.middlename} {user.lastname}, do solemnly declare that:
                        </div>

                        <ol>
                            <li className='fw-bolder'>
                                {' '}
                                I am the applicant described in this application for enrollment;
                            </li>

                            <li className='fw-bolder'>
                                {' '}
                                I have personal knowledge of the information I have added in completing this
                                application;
                            </li>

                            <li className='fw-bolder'> The information is true, accurate and complete; and </li>
                        </ol>
                        <div>
                            I make this solemn declaration conscientiously believing it to be true and knowing that it
                            has the same legal force and effect as if made under oath.
                        </div>
                        <div className='mb-3'>
                            Applicant Signature:<span className='blank-answer-space-400'></span>
                        </div>
                        <div>Official Witness (Notary Public or Commissioner for taking Affidavits):</div>
                        <div>
                            Declared before me in (Municipality)<span className='blank-answer-space-50'></span>in the
                            Province/State/Country of<span className='blank-answer-space-200'></span>, this
                            <span className='blank-answer-space-200 me-1'></span>day of
                            <span className='blank-answer-space-200 me-1'></span>
                            20<span className='blank-answer-space-30'></span>.
                        </div>
                        <div>
                            Notary Public / Commissioner for Taking Affidavits (circle one) in and for (jurisdiction)
                            <span className='blank-answer-space-400'></span>
                        </div>
                        <div>
                            Name:<span className='blank-answer-space-400'></span> Seal/Stamp:
                        </div>
                        <div>
                            Signature:<span className='blank-answer-space-400'></span>
                        </div>
                    </div>

                    <div className='pagebreak'></div>

                    <div className='mb-15'>
                        <h3 className='text-primary mb-5 text-decoration-underline'>Authorization</h3>
                        <h5 className='text-primary mb-2'>
                            I declare the following to be true, accurate and complete:
                        </h5>

                        <div>
                            <ol>
                                <li className='mb-2'>
                                    I authorize the Registrar of the College of Veterinarians of British Columbia (CVBC)
                                    to obtain a copy of my transcripts and diploma from my veterinary educational
                                    institution or (if applicable) from the AAVSB or the National Examination Board
                                    (NEB).
                                </li>
                                <li className='mb-2'>
                                    I authorize the Registrar of the CVBC to request information on past, present or
                                    contemplated investigations or inquiries by my veterinary educational institution
                                    regarding my conduct during the period of my veterinary studies.
                                </li>
                                <li className='mb-2'>
                                    I authorize the Registrar of the CVBC to obtain a criminal record check from
                                    Canadian authorities and from other jurisdictions where I have resided or held
                                    licensure.
                                </li>
                                <li className='mb-2'>
                                    I authorize the Registrar of the CVBC to obtain information from any other
                                    regulatory body concerning current or past professional licensure or registration,
                                    including particulars about complaint investigations (whether dismissed or leading
                                    to a consent resolution) and disciplinary or remedial actions.
                                </li>
                                <li className='mb-2'>
                                    I authorize those agencies, bodies or individuals possessing the information
                                    described above to provide it upon request to the Registrar of the CVBC, including
                                    without limitation, law enforcement agencies.
                                </li>
                                <li className='mb-2'>
                                    I undertake to promptly inform the CVBC of any material change to the information
                                    provided in this application.
                                </li>
                                <li className='mb-2'>
                                    If I am granted registration by the CVBC, I undertake to act in accordance with the
                                    Veterinarians Act of British Columbia and the CVBC bylaws and practice standards.
                                </li>
                            </ol>

                            <div>
                                <span className='fw-bolder'>Applicant Name: </span>
                                {user.firstname} {user.middlename} {user.lastname}
                            </div>
                            <div>
                                Applicant Signature:<span className='blank-answer-space-400'></span>
                            </div>
                            <div>
                                Date:<span className='blank-answer-space-400'></span>
                            </div>
                        </div>
                    </div>

                    <div className='pagebreak'></div>

                    {summary.application.pfn_applicationtypeid?._pfn_subcategory_value_Formatted ===
                    'Provisional Supervised' ? (
                        <div className='mb-7'>
                            <h3 className='text-primary mb-5 text-decoration-underline'>
                                Undertaking For Provisional Supervised Registration In The Private Practice Class
                            </h3>
                            <div className='mb-4'>
                                <div className='mb-2'>
                                    Provisional Supervised Active (PSA) Registration in the <b>Private Practice (PP)</b>{' '}
                                    class of registration is intended for veterinarians who do not yet have a CQ. You
                                    must provide:
                                </div>
                                <ol>
                                    <li className='mb-1'>Proof of registration with the National Examining Board;</li>
                                    <li className='mb-1'>
                                        Proof of successful completion of the Basic & Clinical Sciences Examination
                                        (BCSE) and the North American Veterinary Licensing Examination (NAVLE); and
                                    </li>
                                    <li className='mb-1'>
                                        A signed undertaking agreeing to only practice under the direct supervision of a
                                        CVBC-registered veterinarian within an approved Supervision Agreement, and to
                                        comply with all requirements of this provisional registration.
                                    </li>
                                </ol>
                            </div>
                            <div className='mb-4'>
                                <div className='mb-2'>
                                    I, {user.firstname} {user.middlename} {user.lastname}, provide the following
                                    undertaking to the College of Veterinarians of British Columbia (CVBC), pursuant to
                                    s. 2.8A of the CVBC Bylaws.
                                </div>
                                <ul>
                                    <li className='mb-1'>
                                        I have read, understand and will comply with the Veterinarians Act, and the
                                        CVBC's Bylaws and Professional Practice Standards.
                                    </li>
                                    <li className='mb-1'>
                                        I have provided proof of registration with the National Examining Board (NEB),
                                        and of completion of the BCSE and NAVLE.
                                    </li>
                                    <li className='mb-1'>
                                        As a Provisional Supervised Active registrant in the Private Practice class, I
                                        will only work under the direct supervision of a fully registered Private
                                        Practice registrant who is a Registrant Supervisor as defined in the Bylaws, and
                                        under an approved form of Supervision Agreement in accordance with the Bylaws
                                    </li>
                                    <li className='mb-1'>
                                        I will notify the CVBC immediately of any change to my candidate status with the
                                        NEB, and will keep the College apprised of new test results (Pre-Surgical
                                        Assessment, Clinical Proficiency Exam) as they become available.
                                    </li>
                                    <li className='mb-1'>
                                        I understand that any breach of this undertaking may result in disciplinary
                                        consequences by the CVBC.
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <div>
                                    <span className='fw-bolder'>Applicant Name: </span>
                                    {user.firstname} {user.middlename} {user.lastname}.
                                </div>

                                <div>
                                    Applicant Signature:<span className='blank-answer-space-400'></span>
                                </div>
                                <div>
                                    Date:<span className='blank-answer-space-400'></span>
                                </div>
                            </div>
                        </div>
                    ) : summary.application.pfn_applicationtypeid?._pfn_subcategory_value_Formatted ===
                          'Temporary License' ||
                      summary.application.pfn_applicationtypeid?._pfn_subcategory_value_Formatted ===
                          'Work Permit - Temp License' ? (
                        <div>
                            <div className='mb-7'>
                                <h3 className='text-primary mb-5 text-decoration-underline'>
                                    Undertaking For Temporary Registration For An External Licensed Veterinarian{' '}
                                </h3>
                                <div className='mb-4'>
                                    <div className='mb-2'>
                                        Registration in the{' '}
                                        <span className='fw-bolder'>
                                            Temporary For External Licensed Veterinarian (TEMP)
                                        </span>{' '}
                                        class of registration requires a signed undertaking. Attendance at the bylaw
                                        seminar and passing the bylaw exam is not required. <sup>1</sup>
                                    </div>
                                </div>
                                <div className='mb-4'>
                                    <div className='mb-2'>
                                        I{' '}
                                        <span className='fw-bolder'>
                                            {user.firstname} {user.middlename} {user.lastname}
                                        </span>
                                        , provide the following undertaking to the College of Veterinarians of British
                                        Columbia (CVBC), pursuant to s. 2.15 of the CVBC Bylaws.
                                    </div>

                                    <ul>
                                        <li className='mb-1'>
                                            I have read, understand and will comply with the Act, the bylaws and all
                                            practice standards.
                                        </li>
                                        <li className='mb-1'>
                                            I will restrict my Temporary registration for an external licensed
                                            veterinarian to the following active class of registration:{' '}
                                            <b>{summary.application._pfn_applicationtypeid_value_Formatted}</b>
                                        </li>
                                        <li className='mb-1'>
                                            I will notify the CVBC immediately should there be a change to my
                                            registration status in the external jurisdiction that serves as the basis
                                            for my eligibility for registration in the CVBC’s Temporary class.
                                        </li>
                                        <li className='mb-1'>
                                            I agree to restrict my temporary practice in accordance with s. 2.15 (4) to
                                            a maximum of 30 days for each registration (consecutive or non-consecutive).
                                        </li>
                                        <li className='mb-1'>
                                            I agree to maintain a log recording the date(s) and practice facility names
                                            where I worked and to provide a copy to the registrar upon request.
                                        </li>
                                        <li className='mb-1'>
                                            I understand that any breach of this undertaking may result in disciplinary
                                            consequences by the CVBC.
                                        </li>
                                    </ul>
                                </div>

                                <div className='mb-3'>
                                    <div>
                                        <span className='fw-bolder'>Applicant Name: </span>
                                        {user.firstname} {user.middlename} {user.lastname}.
                                    </div>

                                    <div>
                                        Applicant Signature:<span className='blank-answer-space-400'></span>
                                    </div>
                                    <div>
                                        Date:<span className='blank-answer-space-400'></span>
                                    </div>
                                </div>
                                <div className='fst-italic'>
                                    <sup>1</sup> Bylaws s. 2.1 (14): “external licensed veterinarian means a person who
                                    … (a) is registered or licensed a veterinarian by a regulatory body in another
                                    province of Canada or a foreign jurisdiction recognized by the registrar, and holds
                                    substantially the same class of registration as required of an active registrant”
                                </div>
                            </div>
                        </div>
                    ) : summary.application.pfn_applicationtypeid?._pfn_categoryid_value_Formatted ===
                      'Specialty Private Practice' ? (
                        <div className='mb-7'>
                            <h3 className='text-primary mb-5 text-decoration-underline'>
                                Undertaking For Specialty Private Practice
                            </h3>

                            <div className='mb-4'>
                                <div className='mb-2'>
                                    Registration in the <b>Specialty Private Practice (SPP)</b> class of registration
                                    does not require a CQ. To be recognized as a Diplomate (i.e., a “specialist”) you
                                    must provide:
                                </div>

                                <ol>
                                    <li className='mb-1'>
                                        Proof by means of a certified copy of the diploma and a Letter of Good Standing
                                        that you hold a current diplomate status in a recognized veterinary specialty as
                                        administered by the ABVS (American Board of Veterinary Specialties) or the EBVS
                                        (European Board of Veterinary Scpecialists); and
                                    </li>
                                    <li className='mb-1'>
                                        A signed undertaking agreeing to restrict your practice as a veterinarian to the
                                        scope of the diplomate certificate held.
                                    </li>
                                </ol>
                            </div>

                            <div className='mb-4'>
                                <div className='mb-2'>
                                    I{' '}
                                    <span className='fw-bolder'>
                                        {user.firstname} {user.middlename} {user.lastname}
                                    </span>
                                    , provide the following undertaking to the College of Veterinarians of British
                                    Columbia (CVBC), pursuant to s. 2.14 of the CVBC Bylaws.
                                </div>

                                <ul>
                                    <li className='mb-1'>
                                        I have read, understand and will comply with the Act, the bylaws and practice
                                        standards.
                                    </li>

                                    <li className='mb-1'>
                                        I will restrict my Specialty Private Practice registration in accordance with
                                        the scope of the diplomate certificate which I hold from a College recognized by
                                        the American Board of Veterinary Specialties (ABVS) or the European Board of
                                        Veterinary Specialists (EBVS).
                                    </li>

                                    <li className='mb-1'>
                                        I will promptly notify the CVBC of any future changes or alterations to my ABVS
                                        or EBVS diplomate status.
                                    </li>

                                    <li className='mb-1'>
                                        I understand that any breach of this undertaking may result in disciplinary
                                        consequences.
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <div>
                                    <span className='fw-bolder'>Applicant Name: </span>
                                    {user.firstname} {user.middlename} {user.lastname}.
                                </div>

                                <div>
                                    Applicant Signature:<span className='blank-answer-space-400'></span>
                                </div>
                                <div>
                                    Date:<span className='blank-answer-space-400'></span>
                                </div>
                            </div>
                        </div>
                    ) : summary.application.pfn_applicationtypeid?._pfn_categoryid_value_Formatted ===
                          'Public Sector' ? (
                        <div>
                            <div className='mb-7'>
                                <h3 className='text-primary mb-5 text-decoration-underline'>
                                    Undertaking For Public Sector
                                </h3>
                                <div className='mb-4'>
                                    <div className='mb-2'>
                                        Registration in the <span className='fw-bolder'>Public Sector (PS)</span> class
                                        of registration does not require a CQ. You must provide:
                                    </div>

                                    <ol>
                                        <li className='mb-1'>
                                            Proof of employment as a veterinarian in British Columbia by the Crown in
                                            right of Canada or by the Province of British Columbia or other similar
                                            employment (attach letter of employment or contract); and
                                        </li>
                                        <li className='mb-1'>
                                            A signed undertaking agreeing to restrict your practice as a veterinarian in
                                            British Columbia as an employee of the Crown in right of Canada or as an
                                            employee of the Province of British Columbia.
                                        </li>
                                    </ol>
                                </div>
                                <div className='mb-4'>
                                    <div className='mb-2'>
                                        I{' '}
                                        <span className='fw-bolder'>
                                            {user.firstname} {user.middlename} {user.lastname}
                                        </span>
                                        , provide the following undertaking to the College of Veterinarians of British
                                        Columbia (CVBC), pursuant to s. 2.13 of the CVBC Bylaws.
                                    </div>

                                    <ul>
                                        <li className='mb-1'>
                                            I have read, understand and will comply with the Act, the bylaws and
                                            practice standards.
                                        </li>

                                        <li className='mb-1'>
                                            I have provided proof of employment as a veterinarian in British Columbia by
                                            the Crown in right of Canada or by the Province of British Columbia or other
                                            similar employment.
                                        </li>

                                        <li className='mb-1'>
                                            I will restrict my Public Sector registration to practice as a veterinarian
                                            in British Columbia as an employee of the Crown in right of Canada or by the
                                            Province of British Columbia or other similar employment.
                                        </li>

                                        <li className='mb-1'>
                                            I understand that any breach of this undertaking may result in disciplinary
                                            consequences by the CVBC.
                                        </li>
                                    </ul>
                                </div>

                                <div>
                                    <div>
                                        <span className='fw-bolder'>Applicant Name: </span>
                                        {user.firstname} {user.middlename} {user.lastname}.
                                    </div>

                                    <div>
                                        Applicant Signature:<span className='blank-answer-space-400'></span>
                                    </div>
                                    <div>
                                        Date:<span className='blank-answer-space-400'></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )  : (<></>)}
                    
                    <div className='no-print'>
                        <div className='note-card d-flex align-items-center bg-light rounded p-5 mb-10 mt-20'>
                            <div className='note-card-inner flex-grow-1 me-2'>
                                <div className='text-gray-800'>
                                    <span className='fw-bolder'>Note: </span>Please make sure that all the sections in
                                    this page are included in the printing area. Google Chrome or Microsoft Edge are
                                    recommended for the best printing result. (your printed form may be missing sections
                                    if you use a different browser).
                                </div>
                            </div>
                        </div>
                    </div>

                    {!application.pfn_fomcomplete ? (
                        <div className='d-flex justify-content-between no-print'>
                            <button type='button' className='btn btn-primary' onClick={handleBackClick}>
                                Back
                            </button>

                            <button className='btn btn-primary' onClick={() => window.print()}>
                                Print
                            </button>

                            <button
                                type='button'
                                className='btn btn-primary'
                                onClick={handleSubmitClick}
                                disabled={formLoading}
                            >
                                {!formLoading && <span className='indicator-label'>Submit</span>}
                                {formLoading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    ) : (
                        <div className='d-flex justify-content-between no-print'>
                            <button
                                type='button'
                                className='btn btn-primary'
                                onClick={() => navigate('/applications/registration')}
                            >
                                Back
                            </button>

                            <button className='btn btn-primary' onClick={() => window.print()}>
                                Print
                            </button>
                        </div>
                    )}

                    {formErrMsg && <div className='text-danger text-center mt-7'>{formErrMsg}</div>}
                </div>
            )}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { SummaryForm };

/* -------------------------------------------------------------------------- */
