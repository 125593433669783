/* --------------------------------- IMPORTS -------------------------------- */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

export interface ErrorState {
    formValidation: string | null;
    server: string | null;
    userVerification: string | null;
}

/* -------------------------------------------------------------------------- */

/* ------------------------------ INITIAL STATE ----------------------------- */

const initialState: ErrorState = {
    formValidation: null,
    server: null,
    userVerification: null,
};

/* -------------------------------------------------------------------------- */

/* ---------------------------------- SLICE --------------------------------- */

const errorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        setFormValidationError: (state, action: PayloadAction<string | null>) => {
            state.formValidation = action.payload;
        },
        setServerError: (state, action: PayloadAction<string | null>) => {
            state.server = action.payload;
        },
        setUserVerificationError: (state, action: PayloadAction<string | null>) => {
            state.userVerification = action.payload;
        },
        clearErrors: (state) => {
            state.server = null;
            state.formValidation = null;
        },
    },
});

const { setFormValidationError, setServerError, setUserVerificationError, clearErrors } = errorSlice.actions;

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { errorSlice, setFormValidationError, setServerError, setUserVerificationError, clearErrors };

/* -------------------------------------------------------------------------- */
