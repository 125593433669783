/* --------------------------------- IMPORTS -------------------------------- */

import * as Yup from 'yup';
import { FC, useState } from 'react';
import { useFormik } from 'formik';
import applicationApi from '../../../../../../api/applicationApi';
import clsx from 'clsx';
import { Table } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { removeEmpty } from '../../../../../../helpers';
import { RegAppInitialDetailsFormValues } from '../../../../../../types';
import { omit } from 'lodash';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

interface Props {
    goToNextStep: Function;
}

/* -------------------------------------------------------------------------- */

/* ----------------------------- INITIAL VALUES ----------------------------- */

const initialValues = {
    applicationClass: '',
    pfn_applicationtypeid: '',
    pfn_practicename: '',
    pfn_englishschool: '',
    pfn_testdate: '',
    pfn_testtype: '',
    pfn_certificateofqualification: '',
    pfn_dateofcq: '',
    pfn_explanation: '',
    psaQuestion: '',
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- SCHEMA --------------------------------- */

const validationSchema = Yup.object().shape({
    applicationClass: Yup.string().required('Application class is required'),
    pfn_applicationtypeid: Yup.string()
        .required('Application type is required')
        .when('applicationClass', {
            is: 'private',
            then: Yup.string().oneOf(
                ['PP - Full License', 'PP - Temporary License'],
                'Application type must be a private type',
            ),
        })
        .when('applicationClass', {
            is: 'public',
            then: Yup.string().oneOf(
                // ['PS - Full License', 'PS - Temporary License'],
                ['PS - Full License'],
                'Application type must be a public type',
            ),
        })
        .when('applicationClass', {
            is: 'special',
            then: Yup.string().oneOf(
                ['SPP - Full License', 'SPP - Temporary License'],
                'Application type must be a special type',
            ),
        }),
    pfn_practicename: Yup.string().required('Practice name is required'),
    pfn_englishschool: Yup.string().required('English school question is required'),
    pfn_testdate: Yup.string().when('pfn_englishschool', {
        is: 'No',
        then: Yup.string().required('Test date is required'),
    }),
    pfn_testtype: Yup.string().when('pfn_englishschool', {
        is: 'No',
        then: Yup.string().required('Test type is required'),
    }),
    pfn_certificateofqualification: Yup.string().required('Certificate of Qualification question is required'),
    pfn_dateofcq: Yup.string().when('pfn_certificateofqualification', {
        is: 'Yes',
        then: Yup.string().required('Date of Certificate of Qualification is required'),
    }),
    pfn_explanation: Yup.string().when('pfn_certificateofqualification', {
        is: 'No',
        then: Yup.string().required('Explanation is required'),
    }),
    psaQuestion: Yup.string().when('pfn_applicationtypeid', {
        is: 'PP - Full License',
        then: Yup.string().when('pfn_certificateofqualification', {
            is: 'No',
            then: Yup.string().required('This question is required'),
        }),
    }),
    // .when('pfn_applicationtypeid', {
    //     is: 'PP - Full License',
    //     then: Yup.string().matches(
    //         /^Yes$/,
    //         "You cannot select application type PP - Full License and answer 'No' to this question. Please change your selected application type.",
    //     ),
    // }),
});

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const InitialDetailsForm: FC<Props> = ({ goToNextStep }) => {
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const [reviewMode, setReviewMode] = useState(false);
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            try {
                setErrMsg(null);
                setLoading(true);

                if (
                    formik.values.psaQuestion === 'Yes' &&
                    formik.values.pfn_applicationtypeid === 'PP - Full License'
                ) {
                    formik.values.pfn_applicationtypeid = 'PSA - Full License';
                }

                if (formik.values.pfn_certificateofqualification === 'Yes') {
                    formik.values.pfn_explanation = '';
                } else {
                    formik.values.pfn_dateofcq = '';
                }

                if (formik.values.pfn_englishschool === 'Yes') {
                    formik.values.pfn_testdate = '';
                    formik.values.pfn_testtype = '';
                }

                setReviewMode(true);
                setLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
                setLoading(false);
            }
        },
    });

    const submitForm = async () => {
        try {
            setErrMsg(null);
            setLoading(true);

            const response = await applicationApi.createNewRegApp(
                omit(removeEmpty(formik.values), ['applicationClass', 'psaQuestion']) as RegAppInitialDetailsFormValues,
            );

            if (response) {
                if (!response.success) {
                    if (response.message) {
                        setErrMsg(response.message);
                    } else {
                        setErrMsg('An error has occured, please try again later');
                    }
                } else {
                    navigate(`/applications/registration/${response.data?.pfn_applicationid}`);
                }
            } else {
                setErrMsg('An error has occured, please try again later');
            }

            setLoading(false);
        } catch (err) {
            if (err instanceof Error) {
                setErrMsg(err.message);
            } else {
                setErrMsg('An error has occurred, please try again later');
            }
            setLoading(false);
        }
    };

    return (
        <>
            {reviewMode ? (
                <>
                    <h3 className='mb-10'>Review Your Answers:</h3>
                    <div className='table-container mb-5'>
                        <Table className='intitail-values-review-table' striped borderless>
                            <tbody>
                                <tr>
                                    <td className='fw-bolder label'>Apllication Type:</td>
                                    {formik.values.psaQuestion === 'Yes' ? (
                                        <td className='value'>Veterinarian - Registration (PSA - Full License)</td>
                                    ) : (
                                        <td className='value'>{formik.values.pfn_applicationtypeid}</td>
                                    )}
                                </tr>
                                <tr>
                                    <td className='fw-bolder label'>Practice Name:</td>
                                    <td className='value'>{formik.values.pfn_practicename}</td>
                                </tr>
                                <tr>
                                    <td className='fw-bolder label'>English Proficency:</td>
                                    <td className='value'>{formik.values.pfn_englishschool}</td>
                                </tr>
                                {formik.values.pfn_testdate && (
                                    <tr>
                                        <td className='fw-bolder label'>English Test Date:</td>
                                        <td className='value'>{formik.values.pfn_testdate}</td>
                                    </tr>
                                )}
                                {formik.values.pfn_testtype && (
                                    <tr>
                                        <td className='fw-bolder label'>English Test Type:</td>
                                        <td className='value'>{formik.values.pfn_testtype}</td>
                                    </tr>
                                )}
                                <tr>
                                    <td className='fw-bolder label'>Certificate of Qualification:</td>
                                    <td className='value'>{formik.values.pfn_certificateofqualification}</td>
                                </tr>
                                {formik.values.pfn_dateofcq && (
                                    <tr>
                                        <td className='fw-bolder label'>Certificate of Qualification Date:</td>
                                        <td className='value'>{formik.values.pfn_dateofcq}</td>
                                    </tr>
                                )}
                                {formik.values.pfn_explanation && (
                                    <tr>
                                        <td className='fw-bolder label'>Explanation:</td>
                                        <td className='value'>{formik.values.pfn_explanation}</td>
                                    </tr>
                                )}
                                {formik.values.pfn_applicationtypeid === 'PSA - Full License' && (
                                    <tr>
                                        <td className='fw-bolder label'>Provisional Supervised Active Registration:</td>
                                        <td className='value'>Yes</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                    <div className='note-card d-flex align-items-center bg-light rounded p-5 mb-7'>
                        {/* <span className='bullet bullet-vertical h-200px bg-primary me-10'></span> */}

                        <div className='note-card-inner flex-grow-1 me-2'>
                            <div className='text-gray-800'>
                                <b>Note: </b>Please review the above information before proceeding. You won’t be able to
                                edit these details after pressing 'Next'.
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between no-print'>
                        <button className='btn btn-primary me-5' onClick={() => setReviewMode(false)}>
                            Edit Details
                        </button>
                        <button className='btn btn-primary' onClick={submitForm} disabled={loading}>
                            {!loading && <span className='indicator-label'>Submit</span>}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                    {errMsg && <div className='text-danger text-center mt-7'>{errMsg}</div>}
                </>
            ) : (
                <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='initailDetailsForm'>
                    {/* Start Form Row */}
                    <div className='fv-row mb-7'>
                        <label className='form-label fs-6 fw-bolder text-dark required'>Application Class</label>
                        <select
                            {...formik.getFieldProps('applicationClass')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid': formik.touched.applicationClass && formik.errors.applicationClass,
                            })}
                            name='applicationClass'
                            onChange={(e) => {
                                formik.values.pfn_applicationtypeid = '';
                                formik.handleChange(e);
                            }}
                        >
                            <option disabled value=''>
                                Select Class
                            </option>
                            <option value='private'>Private Practice (PP)</option>
                            <option value='public'>Public Sector (PS)</option>
                            <option value='special'>Specialty Private Practice (SPP)</option>
                        </select>
                        {formik.touched.applicationClass && formik.errors.applicationClass && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span className='text-danger' role='alert'>
                                        {formik.errors.applicationClass}
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    <div className='fv-row mb-7'>
                        <label className='form-label fs-6 fw-bolder text-dark required'>Application Type</label>
                        <select
                            {...formik.getFieldProps('pfn_applicationtypeid')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid':
                                    formik.touched.pfn_applicationtypeid && formik.errors.pfn_applicationtypeid,
                            })}
                            name='pfn_applicationtypeid'
                        >
                            <option disabled value=''>
                                Select Type
                            </option>
                            {formik.values.applicationClass === 'private' ? (
                                <>
                                    <option value='PP - Full License'>PP - Full License</option>
                                    <option value='PP - Temporary License'>PP - Temporary License</option>
                                </>
                            ) : formik.values.applicationClass === 'public' ? (
                                <>
                                    <option value='PS - Full License'>PS - Full License</option>
                                    {/* <option value='PS - Temporary License'>PS - Temporary License</option> */}
                                </>
                            ) : formik.values.applicationClass === 'special' ? (
                                <>
                                    <option value='SPP - Full License'>SPP - Full License</option>
                                    <option value='SPP - Temporary License'>SPP - Temporary License</option>
                                </>
                            ) : (
                                <></>
                            )}
                        </select>
                        {formik.touched.pfn_applicationtypeid && formik.errors.pfn_applicationtypeid && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_applicationtypeid}
                                </span>
                            </div>
                        )}
                    </div>
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    <div className='fv-row mb-7'>
                        <label className='form-label fs-6 fw-bolder text-dark required'>
                            Intended place of employment (veterinarians may only provide veterinary services through
                            CVBC-accredited practice facilities)
                        </label>
                        <input
                            {...formik.getFieldProps('pfn_practicename')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid': formik.touched.pfn_practicename && formik.errors.pfn_practicename,
                            })}
                            name='pfn_practicename'
                            autoComplete='off'
                        />
                        {formik.touched.pfn_practicename && formik.errors.pfn_practicename && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_practicename}
                                </span>
                            </div>
                        )}
                    </div>
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    <div className='fv-row mb-7'>
                        <label className='form-label fs-6 fw-bolder text-dark required'>
                            Was your veterinary education provided in English?
                        </label>
                        <select
                            {...formik.getFieldProps('pfn_englishschool')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid': formik.touched.pfn_englishschool && formik.errors.pfn_englishschool,
                            })}
                            name='pfn_englishschool'
                        >
                            <option disabled value=''>
                                Select Option
                            </option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                        </select>
                        {formik.touched.pfn_englishschool && formik.errors.pfn_englishschool && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_englishschool}
                                </span>
                            </div>
                        )}
                        <div className='text-muted'>
                            <b>Note:</b> Graduates of veterinary programs that were not instructed in English must
                            provide a verifiable result in an English language proficiency test administered by one of
                            the listed providers.
                        </div>
                    </div>
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    {formik.values.pfn_englishschool === 'No' && (
                        <>
                            <div className='fv-row mb-7'>
                                <label className='form-label fs-6 fw-bolder text-dark required'>
                                    Date of English language proficiency test
                                </label>
                                <input
                                    type='date'
                                    min='1900-01-01'
                                    max='9999-12-31'
                                    autoComplete='off'
                                    {...formik.getFieldProps('pfn_testdate')}
                                    className={clsx('form-control form-control-lg form-control-solid', {
                                        'is-invalid': formik.touched.pfn_testdate && formik.errors.pfn_testdate,
                                    })}
                                />
                                {formik.touched.pfn_testdate && formik.errors.pfn_testdate && (
                                    <div className='fv-plugins-message-container'>
                                        <span className='text-danger' role='alert'>
                                            {formik.errors.pfn_testdate}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    {formik.values.pfn_englishschool === 'No' && (
                        <>
                            <div className='fv-row mb-7'>
                                <label className='form-label fs-6 fw-bolder text-dark required'>
                                    English language proficiency test provider
                                </label>
                                <select
                                    {...formik.getFieldProps('pfn_testtype')}
                                    className={clsx('form-control form-control-lg form-control-solid', {
                                        'is-invalid': formik.touched.pfn_testtype && formik.errors.pfn_testtype,
                                    })}
                                    name='pfn_testtype'
                                >
                                    <option disabled value=''>
                                        Select Option
                                    </option>
                                    <option value='IELTS - Academic Version'>IELTS - Academic Version</option>
                                    <option value='TOEFL'>TOEFL</option>
                                    <option value='CAEL'>CAEL</option>
                                </select>
                                {formik.touched.pfn_testtype && formik.errors.pfn_testtype && (
                                    <div className='fv-plugins-message-container'>
                                        <span className='text-danger' role='alert'>
                                            {formik.errors.pfn_testtype}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    <div className='fv-row mb-7'>
                        <label className='form-label fw-bolder text-dark fs-6 required'>
                            Have you received a Certificate of Qualification that was assessed in the English language
                            from the National Examining Board of the Canadian Veterinary Medical Association?
                        </label>

                        <select
                            {...formik.getFieldProps('pfn_certificateofqualification')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid':
                                    formik.touched.pfn_certificateofqualification &&
                                    formik.errors.pfn_certificateofqualification,
                            })}
                            name='pfn_certificateofqualification'
                        >
                            <option disabled value=''>
                                Select Option
                            </option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                        </select>
                        {formik.touched.pfn_certificateofqualification && formik.errors.pfn_certificateofqualification && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span className='text-danger' role='alert'>
                                        {formik.errors.pfn_certificateofqualification}
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    {formik.values.pfn_certificateofqualification === 'No' && (
                        <div className='fv-row mb-7'>
                            <label className='form-label fw-bolder text-dark fs-6 required'>
                                Explanation as to why you answered 'No' to the previous question
                            </label>

                            <textarea
                                autoComplete='off'
                                rows={5}
                                {...formik.getFieldProps('pfn_explanation')}
                                className={clsx('form-control form-control-lg form-control-solid', {
                                    'is-invalid': formik.touched.pfn_explanation && formik.errors.pfn_explanation,
                                })}
                            />
                            {formik.touched.pfn_explanation && formik.errors.pfn_explanation && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span className='text-danger' role='alert'>
                                            {formik.errors.pfn_explanation}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    {formik.values.pfn_certificateofqualification === 'Yes' && (
                        <div className='fv-row mb-7'>
                            <label className='form-label fs-6 fw-bolder text-dark required'>
                                Date of Certificate of Qualification (CQ)
                            </label>
                            <input
                                {...formik.getFieldProps('pfn_dateofcq')}
                                className={clsx('form-control form-control-lg form-control-solid', {
                                    'is-invalid': formik.touched.pfn_dateofcq && formik.errors.pfn_dateofcq,
                                })}
                                type='date'
                                min='1900-01-01'
                                max='9999-12-31'
                                name='pfn_dateofcq'
                            />
                            {formik.touched.pfn_dateofcq && formik.errors.pfn_dateofcq && (
                                <div className='fv-plugins-message-container'>
                                    <span className='text-danger' role='alert'>
                                        {formik.errors.pfn_dateofcq}
                                    </span>
                                </div>
                            )}
                        </div>
                    )}
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    {formik.values.pfn_certificateofqualification === 'No' &&
                        formik.values.pfn_applicationtypeid === 'PP - Full License' && (
                            <div className='fv-row mb-7'>
                                <label className='form-label fs-6 fw-bolder text-dark required'>
                                    I have passed the BCSE and NAVLE examinations, and request the CVBC to grant me
                                    Provisional Supervised Active registration, pending my successful completion of the
                                    NEB’s Pre-Surgical Assessment (PSA) and Clinical Proficiency Exam (CPE)
                                </label>
                                <select
                                    {...formik.getFieldProps('psaQuestion')}
                                    className={clsx('form-control form-control-lg form-control-solid', {
                                        'is-invalid': formik.touched.psaQuestion && formik.errors.psaQuestion,
                                    })}
                                    name='psaQuestion'
                                >
                                    <option disabled value=''>
                                        Select Option
                                    </option>
                                    <option value='Yes'>Yes</option>
                                    <option value='No'>No</option>
                                </select>
                                {formik.touched.psaQuestion && formik.errors.psaQuestion && (
                                    <div className='fv-plugins-message-container'>
                                        <span className='text-danger' role='alert'>
                                            {formik.errors.psaQuestion}
                                        </span>
                                    </div>
                                )}
                            </div>
                        )}
                    {/* End Form Row */}

                    <div className='d-flex align-items-center mb-10'>
                        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                    </div>

                    <div className='fv-row'>
                        <div className='d-flex justify-content-between'>
                            <Link to='/applications/registration'>
                                <button type='button' className='btn btn-primary me-3'>
                                    Cancel
                                </button>
                            </Link>

                            <button type='submit' className='btn btn-primary' disabled={formik.isSubmitting || loading}>
                                Next
                            </button>
                        </div>
                    </div>
                </form>
            )}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { InitialDetailsForm };

/* -------------------------------------------------------------------------- */
