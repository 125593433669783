/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useState } from 'react';
import { KTSVG } from '../../../../../_metronic/helpers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import userApi from '../../../../api/userApi';
import { changeEmail } from '../../../../redux/slices/UserSlice';
import { closeModalById } from '../../../../helpers';
import MaskedInput from 'react-text-mask';

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const ChangePersonalEmail: FC = () => {
    const user = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [submittedRequest, setSubmittedRequest] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);

    const initialValues = {
        pfn_changeemailaddress: user.email.personal ? user.email.personal : '',
        pfn_changeemailaddressverificationcode: '',
        emailType: 'personal',
    };

    const validationSchema = Yup.object().shape({
        pfn_changeemailaddress: Yup.string()
            .required('email is required')
            .email('Wrong email format')
            .max(50, 'Maximum 50 characters'),
        pfn_changeemailaddressverificationcode: Yup.string().test(
            'checkIfRequestHasBeenSubmitted',
            'Verification code required',
            (val) => {
                if (!submittedRequest) {
                    return true;
                } else {
                    if (val) {
                        return true;
                    } else {
                        return false;
                    }
                }
            },
        ),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                setErrMsg(null);
                setLoading(true);

                if (submittedRequest) {
                    const response = await dispatch(changeEmail(values));
                    if (response) {
                        if (!response.payload?.success) {
                            if (response.payload?.message) {
                                setErrMsg(response.payload.message);
                            } else {
                                setErrMsg('An error has occured, please try again later');
                            }
                        } else {
                            closeModalById('kt_modal_edit_personal_email');
                        }
                    } else {
                        setErrMsg('An error has occured, please try again later');
                    }
                } else {
                    const response = await userApi.requestChangeEmail(values.pfn_changeemailaddress);
                    if (response) {
                        if (!response.success) {
                            if (
                                response.errors?.validationError?.pfn_changeemailaddress &&
                                response.errors?.validationError?.pfn_changeemailaddress ===
                                    'pfn_changeemailaddress already exists'
                            ) {
                                setErrMsg('Email address already in use');
                            } else if (response.message) {
                                setErrMsg(response.message);
                            } else {
                                setErrMsg('An error has occured, please try again later');
                            }
                        } else {
                            setSubmittedRequest(true);
                        }
                    } else {
                        setErrMsg('An error has occured, please try again later');
                    }
                }
                setLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
                setLoading(false);
            }
        },
    });

    // const handleDeleteEmail = async () => {
    //     try {
    //         setLoading(true);
    //         setErrMsg(null);

    //         const response = await dispatch(deleteEmail('personal'));
    //         if (response) {
    //             if (!response.payload?.success) {
    //                 if (response.payload?.message) {
    //                     setErrMsg(response.payload.message);
    //                 } else {
    //                     setErrMsg('An error has occured, please try again later');
    //                 }
    //             } else {
    //                 const myModalEl: Element = document.getElementById('kt_modal_delete_personal_email') as Element;
    //                 const modal = Modal.getInstance(myModalEl);
    //                 if (modal) {
    //                     modal.hide();
    //                 }
    //             }
    //         } else {
    //             setErrMsg('An error has occured, please try again later');
    //         }

    //         setLoading(false);
    //     } catch (err) {
    //         if (err instanceof Error) {
    //             setErrMsg(err.message);
    //         } else {
    //             setErrMsg('An error has occurred, please try again later');
    //         }
    //         setLoading(false);
    //     }
    // };

    // const deletePersonalEmailModal = () => {
    //     return (
    //         <div className='modal fade' tabIndex={-1} id='kt_modal_delete_personal_email'>
    //             <div className='modal-dialog'>
    //                 <div className='modal-content'>
    //                     <div className='modal-header'>
    //                         <h5 className='modal-title'>
    //                             Are you sure you want to delete your personal email address?
    //                         </h5>
    //                         <div
    //                             className='btn btn-icon btn-sm btn-active-light-primary ms-2'
    //                             data-bs-dismiss='modal'
    //                             aria-label='Close'
    //                         >
    //                             <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
    //                         </div>
    //                     </div>
    //                     <div className='modal-body'>
    //                         <div className='d-flex justify-content-center'>
    //                             <button type='button' className='btn btn-light me-3' data-bs-dismiss='modal'>
    //                                 Cancel
    //                             </button>

    //                             <button
    //                                 type='button'
    //                                 className='btn btn-danger'
    //                                 disabled={loading}
    //                                 onClick={handleDeleteEmail}
    //                             >
    //                                 {!loading && <span className='indicator-label'>Delete</span>}
    //                                 {loading && (
    //                                     <span className='indicator-progress text-light' style={{ display: 'block' }}>
    //                                         Please wait...
    //                                         <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
    //                                     </span>
    //                                 )}
    //                             </button>
    //                         </div>
    //                         {errMsg && <div className='text-danger text-center mt-7'>{errMsg}</div>}
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // };

    const editPersonalEmailModal = () => {
        return (
            <div className='modal fade' tabIndex={-1} id='kt_modal_edit_personal_email'>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Edit Personal Email</h5>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
                            </div>
                        </div>
                        <div className='modal-body'>{editPersonalEmailForm()}</div>
                    </div>
                </div>
            </div>
        );
    };

    const editPersonalEmailForm = () => {
        return (
            <form className='form w-100' onSubmit={formik.handleSubmit} id='edit_email_form'>
                {/* Start Form Row */}
                <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>
                        {/* Personal Email {user.email.main === user.email.personal && '(Main)'} */}
                        Personal Email
                    </label>
                    <input
                        type='text'
                        disabled={submittedRequest ? true : false}
                        autoComplete='off'
                        {...formik.getFieldProps('pfn_changeemailaddress')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_changeemailaddress && formik.errors.pfn_changeemailaddress,
                        })}
                    />
                    {formik.touched.pfn_changeemailaddress && formik.errors.pfn_changeemailaddress && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                    {formik.errors.pfn_changeemailaddress}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                {submittedRequest && (
                    <>
                        <div className='fv-row mb-5'>
                            <label className='form-label fs-6 fw-bolder text-dark'>Verification Code</label>
                            <MaskedInput
                                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                {...formik.getFieldProps('pfn_changeemailaddressverificationcode')}
                                className={clsx('form-control form-control-lg form-control-solid', {
                                    'is-invalid':
                                        formik.touched.pfn_changeemailaddressverificationcode &&
                                        formik.errors.pfn_changeemailaddressverificationcode,
                                })}
                            />
                            {formik.touched.pfn_changeemailaddressverificationcode &&
                                formik.errors.pfn_changeemailaddressverificationcode && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert' className='text-danger'>
                                                {formik.errors.pfn_changeemailaddressverificationcode}
                                            </span>
                                        </div>
                                    </div>
                                )}
                        </div>
                        <div className='text-gray-700 mb-5'>
                            An email with a 6 digit verification code was sent to the email address you entered above.
                            Please enter the verification code to confirm this is the correct email address.
                        </div>
                    </>
                )}
                {/* End Form Row */}

                <div className='d-flex align-items-center mb-10 mt-9'>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                </div>

                <div className='fv-row'>
                    <div className='d-flex justify-content-end'>
                        <button type='button' className='btn btn-light me-3' data-bs-dismiss='modal'>
                            Cancel
                        </button>

                        <button type='submit' className='btn btn-primary' disabled={formik.isSubmitting || loading}>
                            {!loading && <span className='indicator-label'>Verify</span>}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </div>
                {errMsg && <div className='text-danger text-center mt-7'>{errMsg}</div>}
            </form>
        );
    };

    return (
        <div className='d-flex flex-column mb-8'>
            <label className='form-label fs-6 fw-bolder text-dark required'>
                Personal Email
                {/* Personal Email {user.email.main === user.email.personal && '(Main)'} */}
            </label>
            <div className='d-flex align-items-center me-2 bg-light h-45px rounded'>
                <div className='px-5 flex-grow-1 fs-6 d-block text-gray-600 fw-bold'>
                    {user.email.personal || '---'}
                </div>
                {/* {user.email.personal && (
                    <button
                        className='btn btn-danger btn-icon h-100 rounded-0'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_delete_personal_email'
                        onClick={() => {
                            setErrMsg(null);
                            setLoading(false);
                        }}
                    >
                        <i className='bi bi-trash3 fs-2 pe-0'></i>
                    </button>
                )} */}

                <button
                    className='btn btn-icon btn-primary h-100 rounded-0 rounded-end'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_edit_personal_email'
                    onClick={() => {
                        formik.resetForm();
                        setErrMsg(null);
                        setSubmittedRequest(false);
                    }}
                >
                    <i className='bi bi-pencil-square fs-2 pe-0'></i>
                </button>
            </div>
            {editPersonalEmailModal()}
            {/* {deletePersonalEmailModal()} */}
        </div>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { ChangePersonalEmail };

/* -------------------------------------------------------------------------- */
