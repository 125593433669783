import { FacilityAssessment } from '../../../../../types';

/**
 * Generates the PDF content for facility types from a facility assessment.
 * @param {FacilityAssessment} assessment - The assessment containing facility types.
 * @returns {{ header: string[]; data: Array<{ category: string; subcategory: string }> }} - An object containing headers and data for the PDF table.
 */
export const generateFacilityTypeTableContentForPDF = (assessment: FacilityAssessment) => {
    if (!assessment.facilityTypes?.length) {
        return {
            tableHeader: ['FACILITY CATEGORY', 'DETAILS'],
            data: [{ category: 'None', subcategory: 'None' }],
        };
    }

    const data = assessment.facilityTypes.flatMap((facilityType) => {
        const category = facilityType._pfn_facilitycategoryid_value_Formatted || 'Unknown';
        
        if (facilityType.pfn_facilitytype_facilitysubcategory && facilityType.pfn_facilitytype_facilitysubcategory.length > 0) {
            return facilityType.pfn_facilitytype_facilitysubcategory.map((subcategory) => ({
                category,
                subcategory: subcategory.pfn_name,
            }));
        } else {
            return [{ category, subcategory: 'N/A' }];
        }
    });

    return {
        tableHeader: ['FACILITY CATEGORY', 'DETAILS'],
        data,
    };
};