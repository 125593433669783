/* --------------------------------- IMPORTS -------------------------------- */

import * as Yup from 'yup';
import { FC, useState } from 'react';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { FacilityAssessment } from '../../../../../../types';
import facilityAssessmentApi from '../../../../../../api/facilityAssessmentsApi';
import { useAppSelector } from '../../../../../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { convertToSafeString } from '../../../../../../helpers';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

interface Props {
    goToNextStep: Function;
    assessment: FacilityAssessment;
    goToPreviousStep: Function;
}

/* -------------------------------------------------------------------------- */

/* --------------------------------- SCHEMA --------------------------------- */

const validationSchema = Yup.object().shape({
    declaration: Yup.string()
        .required('This field is required.')
        .test('checkIfAgree', 'Please contact CVBC as to why you do not agree, thank you.', (val) => {
            return val === 'Yes';
        }),
    isAcknowledged: Yup.boolean().oneOf([true], 'This field is required.'),
});

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const DeclarationsForm: FC<Props> = ({ goToNextStep, assessment, goToPreviousStep }) => {
    const user = useAppSelector((state) => state.user);
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const navigate = useNavigate();

    const initialValues = {
        declaration: '',
        isAcknowledged: false,
    };
    
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            try {
                setErrMsg(null);
                setLoading(true);
    
                const response = await facilityAssessmentApi.submitAssessment(
                    assessment.pfn_facilityselfassessmentid,
                    assessment._pfn_facilityid_value,
                );
    
                if (response) {
                    if (!response.success) {
                        setErrMsg(response.message || 'An error has occurred, please try again later');
                    } else {
                        navigate(`/annual-declaration-submission/${assessment.pfn_facilityselfassessmentid}`);
                    }
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
    
                setLoading(false);
            } catch (err) {
                setErrMsg(err instanceof Error ? err.message : 'An error has occurred, please try again later');
                setLoading(false);
            }
        },
    });

    return (
        <>
            {errMsg ? (
                <div className='text-danger text-center'>{errMsg}</div>
            ) : (
                <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='initialDetailsForm'>
                    {/* Start Form Row */}
                    <div className='fv-row mb-7'>
                        <label className='form-label fs-6'>
                            <div className='mb-5'>
                                I,{' '}
                                <span className='text-primary'>
                                    {user.firstname} {user.lastname}
                                </span>
                                , the Designated Registrant of{' '}
                                <span className='text-primary'>{assessment._pfn_facilityid_value_Formatted}</span>{' '}
                                hereby sign this undertaking declaring that I have read and understood my duties under
                                section 3.6 of{' '}
                                <a
                                    href='https://www.cvbc.ca/wp-content/uploads/2020/03/Part-3-Accreditation-and-Naming.pdf'
                                    target='_blank'
                                    rel='noreferrer'
                                    className='text-decoration-underline'
                                >
                                    Part 3 of the CVBC Bylaws 3
                                </a>
                                , and without limiting the generality of section 3.6, I understand that:
                            </div>
                            <ol>
                                <li className='mb-2'>
                                    The form above has been filled out to the best of my knowledge and in an accurate manner.
                                </li>
                                <li className='mb-2'>
                                    I will promptly notify the CVBC of any of the following noted in Part 3.6(9), those being a substantial(a) change in the scope of practice of the facility, a significant(b) or material renovation, a change of address or location, a change in Designated Registrant, a closure, a significant loss of controlled drugs or records, and/or a change of ownership.
                                </li>
                                <li className='mb-2'>
                                    I understand anything reported under 3.6(9) may be conveyed to the PFAC for review, and an inspection may be directed as per 3.18(1).
                                </li>
                            </ol>

                            <br />
                            <div className='text-muted mt-4' style={{ fontSize: '0.9rem' }}>
                                <p className='mb-2'>
                                    <strong>a. Substantial:</strong> One that leads to additional Accreditation Standards and/or related PPS being applicable. 
                                    This would include, but is not limited to, the addition of veterinary service categories as per Standard 1, 
                                    the request to order controlled drugs when not previously accredited for such, the addition of a new species 
                                    group to the services offered, or the addition of major imaging equipment (other than endoscopy and ultrasound).
                                </p>
                                <p className='mb-2'>
                                    <strong>b. Significant:</strong> A significant or material renovation would be structural alterations that change 
                                    the existing floor plan or expand the space, with or without a change to scope of practice, i.e., additions or 
                                    subtractions of walls, add-on structure to an existing facility, addition of a fixed facility to a previously 
                                    mobile-only facility. Painting and changes to fixtures would not be considered significant.
                                </p>
                            </div>
                            <br />
                            <br />
                        </label>
                        <select
                            {...formik.getFieldProps('declaration')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid': formik.touched.declaration && formik.errors.declaration,
                            })}
                            name='declaration'
                        >
                            <option disabled value=''>
                                Select Option
                            </option>
                            <option value='Yes'>Yes, I agree</option>
                            <option value='No'>No, I do not agree</option>
                        </select>
                        {formik.touched.declaration && formik.errors.declaration && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.declaration}
                                </span>
                            </div>
                        )}
                    </div>
                    {/* End Form Row */}

                    <br />
                    
                    {/* Acknowledgment Checkbox */}
                    <label>
                        <input
                            type="checkbox"
                            id="sa-acknowledgement"
                            {...formik.getFieldProps('isAcknowledged')}
                        /> I acknowledge that I have completed the accompanying Self-Assessment Form, which assesses the previous year and is kept in the facility to be available upon request.
                    </label>
                    {formik.touched.isAcknowledged && formik.errors.isAcknowledged && (
                        <div className='text-danger'>
                            {formik.errors.isAcknowledged}
                        </div>
                    )}
                    <br/>
                    <br/>

                    <div className='d-flex align-items-center mb-10'>
                        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                    </div>

                    <div className='fv-row'>
                        <div className='d-flex justify-content-between'>
                            <button type='button' className='btn btn-primary me-3' onClick={() => goToPreviousStep()}>
                                Previous
                            </button>

                            <button type='submit' className='btn btn-primary' disabled={formik.isSubmitting || loading}>
                                {!loading && <span className='indicator-label'>Submit</span>}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                    {errMsg && <div className='text-danger text-center mt-7'>{errMsg}</div>}
                </form>
            )}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { DeclarationsForm };

/* -------------------------------------------------------------------------- */