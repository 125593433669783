/* eslint-disable react/jsx-no-target-blank */
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';
import { KTSVG } from '../../../helpers';
import { AsideMenuItem } from './AsideMenuItem';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
import { useAppSelector } from '../../../../app/redux/hooks';
import { LINK_REGISTRATIOM_RENEWAL_DETAILS1 } from '../../../../app/constants';

export function AsideMenuMain() {
    const intl = useIntl();
    const user = useAppSelector((state) => state.user);

    return (
        <>
            <AsideMenuItem
                to='/dashboard'
                icon='/media/icons/duotune/general/gen022.svg'
                title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
                fontIcon='bi-app-indicator'
            />
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Registration</span>
                </div>
            </div>
            <AsideMenuItemWithSub
                to='/applications'
                title='Applications'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/abstract/abs027.svg'
            >
            {
                user.statusHistory.subcategory.formatted === 'Applicant' ? (
                    <AsideMenuItem to="/applications/registration" title="Registration" hasBullet={true} />
                ) : user.renewalYear ? (
                    <>
                        <div className='menu-item'>
                            <a
                                className='menu-link without-sub'
                                href='https://www.cvbc.ca/registration/class-and-name-changes/'
                                target='_blank'
                                rel='noreferrer'
                            >
                                <span className='menu-bullet fs-3'>•</span>
                                <span className='menu-title'>Registration Class Transfer</span>
                            </a>
                        </div>
                        {user.pfn_canrenew && (
                            <AsideMenuItem to={LINK_REGISTRATIOM_RENEWAL_DETAILS1.value} title="Renewal" hasBullet={true} />
                        )}
                    </>
                ) : (
                    <div className='menu-item'>
                        <a
                            className='menu-link without-sub'
                            href='https://www.cvbc.ca/registration/class-and-name-changes/'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <span className='menu-bullet fs-3'>•</span>
                            <span className='menu-title'>Registration Class Transfer</span>
                        </a>
                    </div>
                )
            }
            <AsideMenuItem to='/applications/history' title='Application History' hasBullet={true} />
            </AsideMenuItemWithSub>
            <AsideMenuItem
                to='/certificate-licensure'
                title='Certificate of Licensure'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/abstract/abs037.svg'
            />
            <AsideMenuItem
                to='/wallet-card'
                title='Wallet Card'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/communication/com005.svg'
            />
            <AsideMenuItemWithSub
                to='/facilities'
                title='Facilties'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/abstract/abs027.svg'
            >
                <AsideMenuItem to='/facilities/my-facilities' title='My Facilities' hasBullet={true} />
                <AsideMenuItem to='/facilities/search' title='Facility Search' hasBullet={true} />
                <AsideMenuItem to='/facilities/assessments' title='Annual Declarations' hasBullet={true} />
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
                to='/receipts'
                title='Receipts'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/coding/cod002.svg'
            >
                <AsideMenuItem to='/receipts/all' title='All Receipts' hasBullet={true} />
                <AsideMenuItem to='/receipts/personal' title='Personal Receipts' hasBullet={true} />
                <AsideMenuItem to='/receipts/facility' title='Facility Receipts' hasBullet={true} />
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
                to='/invoices'
                title='Invoices'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/general/gen005.svg'
            >
                <AsideMenuItem to='/invoices/all' title='All Invoices' hasBullet={true} />
                <AsideMenuItem to='/invoices/personal' title='Personal Invoices' hasBullet={true} />
                <AsideMenuItem to='/invoices/facility' title='Facility Invoices' hasBullet={true} />
            </AsideMenuItemWithSub>
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Continuing Education</span>
                </div>
            </div>
            <AsideMenuItem
                to='/transcripts'
                title='CE Transcripts'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/general/gen005.svg'
            />

            <div className='menu-item'>
                <a className='menu-link without-sub' href='https://classroom.cvbc.ca/' target='_blank' rel='noreferrer'>
                    <span className='menu-icon'>
                        <KTSVG path='/media/icons/duotune/general/gen017.svg' className='svg-icon-2' />
                    </span>

                    <i className={clsx('bi fs-3', '/media/icons/duotune/general/gen005.svg')}></i>
                    <span className='menu-title'>Learning Hub</span>
                </a>
            </div>
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Manage Account</span>
                </div>
            </div>
            <AsideMenuItem
                to='/contact-info'
                title='Contact Information'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/maps/map008.svg'
            />
            <AsideMenuItem
                to='/change-password'
                title='Change Password'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/general/gen051.svg'
            />
            <AsideMenuItem
                to='/change-permission'
                title='Change Permission'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/coding/cod001.svg'
            />
            <AsideMenuItem
                to='/change-email'
                title='Change Email'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/communication/com002.svg'
            />
            <div className='menu-item'>
                <div className='menu-content'>
                    <div className='separator mx-1 my-4'></div>
                </div>
            </div>
        </>
    );
}
