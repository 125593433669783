import { FacilityAssessment } from '../../../../../types';
import { base64HeaderImage } from '../../../../../utils/base64FacilitiesADHeaderImage';
import { generatePDF } from '../../../../../utils/generatePDF';
import { generateGeneralInfoPDFContent } from './generateGeneralInfo';
import { generateAnimalSpeciesTableContentForPDF } from './generateAnimalSpeciesTableContentForPDF';
import { generateFacilityInformationTableContenForPDF } from './generateFacilityInformationTableContenForPDF';
import { generatePublicationContentForPDF } from './generatePublicationContentForPDF';
import { generateGeographicLocationContentForPDF } from './generateGeographicLocationContentForPDF';
import { generateFacilityTypeTableContentForPDF } from './generateFacilityTypeTableContentForPDF';
import { generateVeterinaryServicesTableContentForPDF } from './generateVeterinaryServicesTableContentForPDF';
import { generateDeclarationsContentForPDF } from './generateDeclarationsContentForPDF';

/**
 * Generates the annual declaration PDF report.
 * @param {FacilityAssessment} assessment.
 */
export const generateAnnualDeclarationPDF = async (assessment: FacilityAssessment) => {
    if (assessment) {
        const { tableHeader: facilityHeader, data: facilityData } = generateFacilityInformationTableContenForPDF(assessment);
        const { content: publicationContent } = generatePublicationContentForPDF(assessment);
        const { content: geographicLocationContent } = generateGeographicLocationContentForPDF(assessment);
        const { tableHeader: speciesHeader, data: animalSpeciesData } = generateAnimalSpeciesTableContentForPDF(assessment);
        const { tableHeader: facilityTypeHeader, data: facilityTypeData } = generateFacilityTypeTableContentForPDF(assessment);
        const { tableHeader: veterinaryServicesHeader, data: veterinaryServicesData } = generateVeterinaryServicesTableContentForPDF(assessment);
        const { content: declarationsHeader } = generateDeclarationsContentForPDF(assessment, assessment._pfn_contactid_value_Formatted);

        const sections = [
            {
                label: 'GENERAL INFORMATION',
                introText: '',
                content: generateGeneralInfoPDFContent().map(text => ({ text })) as Array<{ text: string; bold?: boolean }>
            },
            {
                label: 'FACILITY INFORMATION',
                introText: '',
                tableHeader: facilityHeader,
                content: facilityData
            },
            {
                label: 'PUBLICATION',
                introText: '',
                content: publicationContent
            },
            {
                label: 'GEOGRAPHIC LOCATION',
                introText: '',
                content: geographicLocationContent
            },
            {
                label: 'FACILITY TYPE',
                introText: 'All Facility Type categories that apply to the practice:',
                tableHeader: facilityTypeHeader,
                content: facilityTypeData
            },
            {
                label: 'ANIMAL SPECIES',
                introText: 'All categories/species seen by the practice:',
                tableHeader: speciesHeader,
                content: animalSpeciesData
            },
            {
                label: 'VETERINARY SERVICES',
                introText: 'All Veterinary Services categories that apply to your practice:',
                tableHeader: veterinaryServicesHeader,
                content: veterinaryServicesData
            },
            {
                label: 'DECLARATION STATEMENT',
                introText: '',
                content: declarationsHeader
            },
            {
                label: 'APPENDIX - TERMS RELATED TO SCOPE OF PRACTICE',
                introText: '',
                content: [
                    { text: 'Introduction' , bold: true },
                    { text: 'Bylaws Part 3 and Schedule D describe facilities and scope of practice. This Appendix presents the revised terminology for "Facility Type" and "Veterinary Services" to assist the Designated registrants with the revised Declaration Form.' },
                    { text: 'Questions or comments may be directed to the CVBC Facilities department at facilities@cvbc.ca.' },
                    { text: 'Detailed definitions are on the CVBC website here.' }
                ]
            },
            {
                label: 'APPENDIX - FACILITY TYPE',
                introText: '',
                content: [           
                    { text: 'Schedule D defines several "facility types":' },
                    { text: '- Self-standing facility: "Non-ambulatory facility within, on or from which veterinary medicine is conducted."' },
                    { text: '- Ambulatory facility: "Any vehicle in, on or from which veterinary services are provided, and includes the permanent base of operations."' },
                    { text: '- Primary Care facility: "A facility owned and/or operated by a registrant from which a patient may be referred for emergency treatment."' },
                    { text: '- Emergency Facility: "A veterinary medical facility whose primary function is receiving, treating and monitoring of emergency patients during specified hours of operation, with a veterinarian and sufficient staff in attendance at all hours of operation and sufficient instrumentation, medications, and supplies available to provide appropriate care."' },
                    { text: '- Specialist Facility: "Facility owned and/or operated by a Specialty Private Practice member to which a patient may be referred for treatment."' },
                    { text: '- Tertiary Care Facility: "A Center, e.g. of a specialty critical care practice."' },
                    { text: '' },
                    { text: 'By Laws Part 3 defines two special cases of practice categories:' },
                    { text: '- Consulting Practice: "consulting practice" means a veterinary practice in which a registrant provides veterinary services to other registrants or practice facilities, including online, and does not have its own premise, structure, vehicle or facility.' },
                    { text: '- Philanthropic practice: "philanthropic practice" means the humanitarian provision of veterinary services… Part 3 describes the process for "Philanthropic accreditation" of a facility.' },
                    { text: '' },
                    { text: 'The following Facility Type categories have been added/updated:' },
                    { text: '- A subcategory for practices that encompass both ambulatory and self-standing hospital facilities: "Where the practice includes both ambulatory and self-standing hospital facilities, equipment and supplies may be shared between the fixed and ambulatory practices so long as patient needs are met in a timely manner, including emergencies, and standards for both fixed and ambulatory facilities are met."' },
                    { text: '- Subcategories for type of office associated with an ambulatory/mobile practice. These include: a public office which the public can access to pick up medications or drop off diagnostic samples; and a non-public office for administration where no clients are seen.' },
                    { text: '- Category for Veterinary Specialists disciplines and species specialization under the Specialists Facilities. An increasingly diverse range of boarded Veterinary Specialists are practicing in BC.' }
                ]
            },
            {
                label: 'APPENDIX - VETERINARY SERVICES',
                introText: '',
                content: [
                    { text: 'Schedule D describes veterinary services, which may include:' },
                    { text: '- Physical examination of the patient.' },
                    { text: '- Patient treatments.' },
                    { text: '- Medical procedures.' },
                    { text: '- Preparation, packaging and/or processing biological samples.' },
                    { text: '- Obtaining images of diagnostic quality.' },
                    { text: '- Storage, handling, and dispensing of drugs and biologicals.' },
                    { text: '- Anesthetic procedures.' },
                    { text: '- Surgical procedures.' },
                    { text: '- Dental procedures.' },
                    { text: '- Emergency services.' },
                    { text: '- Ambulatory services.' },
                    { text: '- Patient confinement and accommodation.' },
                    { text: '' },
                    { text: 'Various procedures are further defined and/or detailed in Schedule D (e.g., dental procedures and complementary and integrative medicine).' },
                    { text: '' },
                    { text: 'The following Veterinary Service categories have been added/updated:' },
                    { text: '- Complementary or alternative medicine: "A group of treatments or therapeutic options that lie outside the mainstream of conventional medicine." These could also include Integrative Medicine: "The diagnosis and treatment involving the combination of complementary and conventional medicine."' },
                    { text: '- Mobile clinic (under the category "Ambulatory services"): "A temporary, non-accredited, third-party-hosted location to which clients come to meet with the registrant for veterinary services."' },
                    { text: '- Mobile-euthanasia only (under "Ambulatory services"): "A limited scope mobile service with focus on security of control drugs and proper care of remains."' },
                    { text: '- Telemedicine services.' },
                    { text: '- Population Medicine: "includes but is not limited to herd health, shelter medicine, wildlife, small animal breeders animals, aquaculture and apiculture."' },
                    { text: '- Other (describe) services: to capture unanticipated and/or future veterinary services.' }
                ]
            }
        ];

        // Generate the PDF
        await generatePDF(
            assessment._pfn_assessmentyear_value_Formatted + ' Practice Facility Annual Declaration Form',
            sections,
            base64HeaderImage
        );
    }
};