/* --------------------------------- IMPORTS -------------------------------- */
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../../_metronic/layout/core';
import { ChangeWorkEmail } from './components/ChangeWorkEmail';
import { ChangePersonalEmail } from './components/ChangePersonalEmail';
import { ChangeMainEmail } from './components/ChangeMainEmail';

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const ChangePermission: FC = () => {
    return (
        <div className='w-lg-600px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
            <div className='text-gray-700 mb-15'>
                <span className='fw-bold text-dark'>Note:</span> The email address designated as your Main Email must be
                a unique email address not used by any other registrant – this email address is your Username for
                accessing your online registrant account. This email address will also be used for routine CVBC
                communications (registration renewal reminders, fee notices, newsletters, eblasts, etc). If different
                from your Main Email, the CVBC will use your Personal Email address for confidential communications. You
                are responsible for monitoring your email accounts (including your Junk folders) for communications from
                the CVBC and for responding promptly when required.
            </div>
            <ChangePersonalEmail />
            <ChangeWorkEmail />
            <ChangeMainEmail />
        </div>
    );
};

/* -------------------------------------------------------------------------- */

/* ------------------------------ PAGE WRAPPER ------------------------------ */

const ChangeEmailWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.CHANGEEMAIL' })}</PageTitle>
            <ChangePermission />
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { ChangeEmailWrapper };
