/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../../_metronic/layout/core';
import Table from 'react-bootstrap/Table';
import receiptApi from '../../../api/receiptApi';
import { Link } from 'react-router-dom';
import { Receipt } from '../../../types';
import { convertIsoDateToString } from '../../../helpers';

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const PersonalReceiptsPage: FC = () => {
    const [receipts, setReceipts] = useState<Receipt[]>();
    const [loading, setLoading] = useState(true);
    const [errMsg, setErrMsg] = useState<string | null>(null);

    useEffect(() => {
        const getReceiptsAsync = async () => {
            try {
                const response = await receiptApi.getAllReceipts();

                if (response) {
                    if (!response.success) {
                        if (response.message) {
                            setErrMsg(response.message);
                        } else {
                            setErrMsg('An error occured, please try again later');
                        }
                    } else {
                        if (response.data) {
                            setReceipts(response.data);
                        } else {
                            setErrMsg('No data found');
                        }
                    }
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
            }
            setLoading(false);
        };

        getReceiptsAsync();

        return () => {
            setErrMsg(null);
            setLoading(false);
            setReceipts([]);
        };
    }, []);

    const CreateTable = () => {
        return (
            <div className='table-container mb-10'>
                <Table className='receipts-table' striped bordered>
                    <thead>
                        <tr>
                            <th className='fw-bolder'>Date</th>
                            <th className='fw-bolder'>Type</th>
                            <th className='fw-bolder'>Total Amount</th>
                            <th className='fw-bolder'>Payment Receipt</th>
                        </tr>
                    </thead>
                    <tbody>
                        {receipts?.map((receipt, i) => {
                            if (!receipt.pfn_invoiceid?._pfn_accountid_value) {
                                return (
                                    <tr key={i}>
                                        <td className='date'>{convertIsoDateToString(receipt.pfn_receiveddate)}</td>
                                        <td className='type'>Personal</td>
                                        <td className='amount'>{receipt.pfn_amount_Formatted}</td>
                                        <td className='action'>
                                            <Link to={`/receipts/${receipt.pfn_name}/${receipt.pfn_paymentid}`}>
                                                <button type='button' className='btn btn-sm btn-primary my-1'>
                                                    View / Print
                                                </button>
                                            </Link>
                                        </td>
                                    </tr>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </tbody>
                </Table>
            </div>
        );
    };

    const renderNote = () => {
        return (
            <div className='note-card d-flex align-items-center bg-light rounded p-5 mb-10'>
                <div className='note-card-inner flex-grow-1 me-2'>
                    <div className='text-gray-800'>
                        <div className='pb-3'>
                            <span>
                                <span className='fw-bolder'>Note: </span>
                            </span>
                            <span>
                                Payments may take some time to process and therefore you may not see a receipt for a
                                payment right away. If your receipt is not created over 24 hours after the payment was
                                made, then please contact us at{' '}
                                <a className='text-decoration-underline fw-bold' href='mailto:reception@cvbc.ca'>
                                    reception@cvbc.ca
                                </a>
                                .
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <p>View and print your receipt(s) for fees paid to CVBC.</p>
            <div className='mb-10'>
                <div className='mb-10'>
                    {loading ? (
                        <p>Retreiviing receipts, please wait...</p>
                    ) : errMsg ? (
                        <p>{errMsg}</p>
                    ) : receipts && receipts?.length >= 1 ? (
                        CreateTable()
                    ) : (
                        <div>No receipts found.</div>
                    )}
                </div>
                {renderNote()}
            </div>
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* ------------------------------ PAGE WRAPPER ------------------------------ */

const PersonalReceiptsWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.PERSONALRECEIPTS' })}</PageTitle>
            <PersonalReceiptsPage />
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { PersonalReceiptsWrapper };

/* -------------------------------------------------------------------------- */
