import { FacilityAssessment } from '../../../../../types';

/**
 * Generates the PDF content from a facility assessment.
 * @param {FacilityAssessment} assessment.
 * @returns {{ header: string[]; data: Array<{ category: string; species: string }> }} - An object containing headers and data for the PDF table.
 */
export const generateAnimalSpeciesTableContentForPDF = (assessment: FacilityAssessment) => {
    if (!assessment.facilityAnimalSpecies?.length) {
        return {
            tableHeader: ['CATEGORY', 'SPECIES'],
            data: [{ category: 'None', species: 'None' }],
        };
    }

    const data = assessment.facilityAnimalSpecies.flatMap((speciesEntry) => {
        const category = speciesEntry._pfn_animalcategoryid_value_Formatted || 'Unknown';
        if (speciesEntry.pfn_animalspecies_species && speciesEntry.pfn_animalspecies_species.length > 0) {
            return speciesEntry.pfn_animalspecies_species.map((spec) => ({
                category,
                species: spec.pfn_name,
            }));
        } else {
            return [{ category, species: 'No animal species recorded' }];
        }
    });

    return {
        tableHeader: ['CATEGORY', 'SPECIES'],
        data,
    };
};