/* --------------------------------- IMPORTS -------------------------------- */

import { FC } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../../_metronic/layout/core';
import { ContactInfoPhone } from './components/ContactInfoPhone';
import { ContactInfoEmail } from './components/ContactInfoEmail';
import { ContactInfoHomeAddress } from './components/ContactInfoHomeAddress';
import { ContactInfoWorkAddress } from './components/ContactInfoWorkAddress';
import { ContactInfoPersonal } from './components/ContactInfoPersonal';

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const ContactInformationPage: FC = () => {
    const GenerateNote = () => {
        return (
            <div className='no-print'>
                <div className='card card-xl mb-5 mt-5 mb-xl-8'>
                    <div className='card-body pt-0 pt-8'>
                        <div className='note-card d-flex align-items-center bg-light rounded p-5'>
                            <div className='note-card-inner flex-grow-1 me-2'>
                                <div className='text-gray-800'>
                                    <div className='pb-3'>
                                        <span>
                                            <span className='fw-bolder'>Note: </span>
                                        </span>
                                        <span>
                                            You are responsible for ensuring that your contact information is complete
                                            and up-to-date in the CVBC’s records.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <div className='row gy-5 g-xl-8'>
                <div className='col-xxl-12'>{GenerateNote()}</div>
            </div>
            <div className='row gy-5 g-xl-8'>
                <div className='col-xxl-4'>
                    <ContactInfoPhone />
                    <ContactInfoEmail />
                    <ContactInfoPersonal />
                </div>
                <div className='col-xxl-8'>
                    <ContactInfoHomeAddress />
                    <ContactInfoWorkAddress />
                </div>
            </div>
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- WRAPPER -------------------------------- */

const ContactInformationWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.CONTACTINFORMATION' })}</PageTitle>
            <ContactInformationPage />
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { ContactInformationWrapper };

/* -------------------------------------------------------------------------- */
