import { FacilityAssessment } from '../../../../../types';

/**
 * Generates the PDF content from a facility assessment.
 * @param {FacilityAssessment} assessment.
 * @returns {{ content: { text: string; bold?: boolean; line?: boolean }[] }} - An object containing the text content for the PDF with formatting.
 */
export const generateGeographicLocationContentForPDF = (assessment: FacilityAssessment) => {
    const content: { text: string; bold?: boolean, line?: boolean  }[] = [
    ];

    const value = 'Physical location of the Facility: ' + assessment._pfn_districtid_value_Formatted || '';
    content.push({ text: value, line: false });

    return {
        content: content,
    };
};