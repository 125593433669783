/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useEffect, useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components';
import userApi from '../../../api/userApi';

/* ----------------------------- INITIAL VALUES ----------------------------- */

const initialValues = {
    pfn_password: '',
    passwordConfirmation: '',
};

/* -------------------------------------------------------------------------- */

/* ------------------------------- FORM SCHEMA ------------------------------ */

const schema = Yup.object().shape({
    pfn_password: Yup.string()
        .required('Password is required')
        .min(8, 'Minimum 8 characters is required')
        .matches(/[a-z]+/, 'One lowercase character is required')
        .matches(/[A-Z]+/, 'One uppercase character is required')
        .matches(/[@$!%*#?&.+=_-]+/, 'One special character is required')
        .matches(/\d+/, 'One number is required'),
    passwordConfirmation: Yup.string()
        .required('Password confirmation is required')
        .when('pfn_password', {
            is: (val: string) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf([Yup.ref('pfn_password')], 'Passwords need to match'),
        }),
});

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

export const ResetPassword: FC = () => {
    const [hidden, setHidden] = useState(true);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [successfullyResetPassword, setSuccessfullyResetPassword] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const { contactid, pfn_passwordresetcode } = useParams();

    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values) => {
            try {
                if (contactid && pfn_passwordresetcode) {
                    setLoading(true);
                    setErrMsg(null);
                    const response = await userApi.resetPassword(
                        contactid,
                        pfn_passwordresetcode,
                        values.pfn_password,
                        values.passwordConfirmation,
                    );
                    if (response) {
                        if (response.success) {
                            setSuccessfullyResetPassword(true);
                        } else {
                            if (response.message) {
                                setErrMsg(response.message);
                            } else {
                                setErrMsg('Could not reset password, please try again later');
                            }
                        }
                    } else {
                        setErrMsg('Could not reset password, please try again later');
                    }
                    setSubmitted(true);
                    setLoading(false);
                }
            } catch (err) {
                if (err instanceof Error) {
                    if (err.message) {
                        setErrMsg(err.message);
                    } else {
                        setErrMsg('Could not reset password, please try again later');
                    }
                } else {
                    setErrMsg('Could not reset password, please try again later');
                }
                setLoading(false);
                setSubmitted(true);
            }
        },
    });

    useEffect(() => {
        PasswordMeterComponent.bootstrap();
    }, []);

    return (
        <div className='w-lg-600px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
            <form
                className='form w-300 fv-plugins-bootstrap5 fv-plugins-framework'
                id='registration_form'
                onSubmit={formik.handleSubmit}
            >
                <div className='mb-10 text-center'>
                    <h1 className='text-dark mb-3'>Reset Password</h1>
                </div>

                <div className='d-flex align-items-center mb-10'>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                </div>

                {submitted && errMsg ? (
                    <>
                        <div className='mb-10 alert alert-danger'>
                            <div className='alert-text font-weight-bold'>{errMsg}</div>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <Link to='/auth/login'>
                                <button
                                    type='button'
                                    id='kt_login_password_reset_form_reset_button'
                                    className='btn btn-lg btn-primary fw-bolder'
                                    onClick={() => {
                                        formik.resetForm();
                                        setSubmitted(false);
                                        setErrMsg(null);
                                    }}
                                >
                                    Back
                                </button>
                            </Link>
                        </div>
                    </>
                ) : (
                    submitted &&
                    successfullyResetPassword && (
                        <>
                            <div className='mb-10 alert alert-success'>
                                <div className='alert-text font-weight-bold'>
                                    Your password has been successfully changed!
                                </div>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <Link to='/auth/login'>
                                    <button
                                        type='button'
                                        id='kt_login_password_reset_form_reset_button'
                                        className='btn btn-lg btn-primary fw-bolder'
                                        onClick={() => {
                                            formik.resetForm();
                                            setSubmitted(false);
                                        }}
                                    >
                                        Login
                                    </button>
                                </Link>
                            </div>
                        </>
                    )
                )}
                {!submitted && (
                    <>
                        {/* Start Form Row */}
                        <div className='mb-10 fv-row' data-kt-password-meter='true'>
                            <div className='mb-1'>
                                <label className='form-label fw-bolder text-dark fs-6 required'>Password</label>

                                <div className='position-relative mb-3'>
                                    <div className='password-input-container'>
                                        <input
                                            type={hidden ? 'password' : 'text'}
                                            // placeholder='Password'
                                            autoComplete='off'
                                            {...formik.getFieldProps('pfn_password')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid password-input',
                                                {
                                                    'is-invalid':
                                                        formik.touched.pfn_password && formik.errors.pfn_password,
                                                },
                                                // {
                                                //   'is-valid': formik.touched.pfn_password && !formik.errors.pfn_password,
                                                // }
                                            )}
                                        />
                                        <i
                                            className={clsx('bi fs-1 password-toggle', {
                                                'bi-eye': hidden,
                                                'bi-eye-slash': !hidden,
                                            })}
                                            onClick={() => {
                                                setHidden(!hidden);
                                            }}
                                        ></i>
                                    </div>
                                    {formik.touched.pfn_password && formik.errors.pfn_password && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert' className='text-danger'>
                                                    {formik.errors.pfn_password}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div
                                    className='d-flex align-items-center mb-3'
                                    data-kt-password-meter-control='highlight'
                                >
                                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                                </div>
                            </div>
                            <div className='text-muted'>
                                Use 8 or more characters with a mix of letters, numbers & symbols.
                            </div>
                        </div>
                        {/* End Form Row */}

                        {/* Start Form Row */}
                        <div className='fv-row mb-10'>
                            <label className='form-label fw-bolder text-dark fs-6 required'>Confirm Password</label>

                            <div className='password-input-container'>
                                <input
                                    type={hidden ? 'password' : 'text'}
                                    // placeholder='Password confirmation'
                                    autoComplete='off'
                                    {...formik.getFieldProps('passwordConfirmation')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid password-input',
                                        {
                                            'is-invalid':
                                                formik.touched.passwordConfirmation &&
                                                formik.errors.passwordConfirmation,
                                        },
                                        // {
                                        //   'is-valid': formik.touched.passwordConfirmation && !formik.errors.passwordConfirmation,
                                        // }
                                    )}
                                />
                                <i
                                    className={clsx('bi fs-1 password-toggle', {
                                        'bi-eye': hidden,
                                        'bi-eye-slash': !hidden,
                                    })}
                                    onClick={() => {
                                        setHidden(!hidden);
                                    }}
                                ></i>
                            </div>
                            {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert' className='text-danger'>
                                            {formik.errors.passwordConfirmation}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* End Form Row */}

                        <div className='d-flex align-items-center mb-10'>
                            <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                            {/* <span className='fw-bold text-gray-400 fs-7 mx-2'>OR</span> */}
                            <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                        </div>

                        {/* Start Form Row */}
                        <div className='text-center'>
                            <button
                                type='submit'
                                id='kt_sign_up_submit'
                                className='btn btn-lg btn-primary w-100 mb-5'
                                // disabled={formik.isSubmitting || !(formik.isValid && formik.dirty)}
                                disabled={formik.isSubmitting || loading}
                            >
                                {!loading && <span className='indicator-label'>Submit</span>}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                            <Link to='/auth/login'>
                                <button
                                    type='button'
                                    id='kt_login_signup_form_cancel_button'
                                    className='btn btn-lg btn-light-primary w-100 mb-5'
                                >
                                    Cancel
                                </button>
                            </Link>
                        </div>
                        {/* End Form Row */}
                    </>
                )}
            </form>
        </div>
    );
};

/* -------------------------------------------------------------------------- */
