import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CVBC_OFFICE_ADDRESS, CVBC_RECEPTION_EMAIL, CVBC_RECEPTION_FAX, CVBC_RECEPTION_PHONE } from '../constants';

/* ----------------------------- CONSTANT VALUES ---------------------------- */

export const FONT_FAMILY = 'helvetica';
export const FONT_SIZE_TITLE = 13;
export const FONT_SIZE_SECTION = 11;
export const FONT_SIZE_CONTENT = 10;
export const FONT_SIZE_FOOTER = 8;
export const SPACE_BELOW_HEADER = 15;
export const LINE_SPACING_TEXT = 10;
export const LINE_SPACING_FOOTER = 5;
export const HEADER_IMG_HEIGHT = 30;
export const FOOTER_HEIGHT = 20;
export const SECTION_SPACING = 10;

// Calculate available content height after header and footer.
export const PAGE_CONTENT_HEIGHT = 340 - HEADER_IMG_HEIGHT - FOOTER_HEIGHT - SPACE_BELOW_HEADER;

/* ----------------------------- PDF GENERATION ----------------------------- */
export const generatePDF = async (
    title: string,
    sections: Array<{
        label: string;
        introText?: string;
        tableHeader?: string[];
        content: Array<{ text: string; bold?: boolean; line?: boolean; }> | Array<{ [key: string]: string }>;
    }>,
    imageUrl: string
) => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    const setDefaultFont = () => {
        doc.setFont(FONT_FAMILY, 'normal');
        doc.setFontSize(FONT_SIZE_CONTENT);
    };

    const addDateOnHeader = (doc: jsPDF, pageWidth: number) => {
        const date = new Date();
        const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
        
        doc.setFont(FONT_FAMILY, 'normal');
        doc.setFontSize(FONT_SIZE_FOOTER);
        doc.text(formattedDate, pageWidth - 30, HEADER_IMG_HEIGHT + 5); // Below the header image.
    };

    const resetPage = () => {
        doc.addPage();
        if (imageUrl) {
            doc.addImage(imageUrl, 'JPEG', 0, 0, pageWidth, HEADER_IMG_HEIGHT);
        }
        setDefaultFont();
        return HEADER_IMG_HEIGHT + SPACE_BELOW_HEADER;
    };

    const renderContentWithCheck = (content: Array<{ text: string; bold?: boolean; line?: boolean; }>, startY: number) => {
        let currentY = startY;
        setDefaultFont();

        for (const lineObj of content) {
            if (lineObj.line) {
                if (currentY + LINE_SPACING_TEXT > PAGE_CONTENT_HEIGHT) {
                    addFooter(doc, pageWidth);
                    currentY = resetPage();
                }
                doc.setDrawColor(0);
                doc.setLineWidth(0.1);
                doc.line(15, currentY, pageWidth - 15, currentY);
                currentY += LINE_SPACING_TEXT;
                continue;
            }

            const wrappedText = doc.splitTextToSize(lineObj.text, 180);
            for (const textLine of wrappedText) {
                if (currentY + LINE_SPACING_TEXT > PAGE_CONTENT_HEIGHT) {
                    addFooter(doc, pageWidth);
                    currentY = resetPage();
                }

                doc.setFont(FONT_FAMILY, lineObj.bold ? 'bold' : 'normal');
                doc.text(textLine, 15, currentY);
                currentY += LINE_SPACING_TEXT;
            }
        }
        return currentY;
    };

    // Add initial image and date on the first page.
    if (imageUrl) {
        doc.addImage(imageUrl, 'JPEG', 0, 0, pageWidth, HEADER_IMG_HEIGHT);
    }
    addDateOnHeader(doc, pageWidth);

    let currentY = HEADER_IMG_HEIGHT + SPACE_BELOW_HEADER;

    // Title
    doc.setFont(FONT_FAMILY, 'bold');
    doc.setFontSize(FONT_SIZE_TITLE);
    const textWidth = doc.getTextWidth(title);
    const xOffset = (pageWidth - textWidth) / 2;
    doc.text(title, xOffset, currentY);
    currentY += 14;
    setDefaultFont();

    sections.forEach((section) => {
        if (currentY + 20 > PAGE_CONTENT_HEIGHT) {
            addFooter(doc, pageWidth);
            currentY = resetPage();
        }

        // Section label
        doc.setFont(FONT_FAMILY, 'bold');
        doc.setFontSize(FONT_SIZE_SECTION);
        doc.text(section.label, 10, currentY);
        currentY += 8;

        doc.setDrawColor(53, 80, 119);
        doc.setLineWidth(0.3);
        doc.line(10, currentY, pageWidth - 10, currentY);
        currentY += LINE_SPACING_TEXT;

        // Render introText if available.
        if (section.introText && section.introText.trim() !== "") {
            const wrappedIntroText = doc.splitTextToSize(section.introText, pageWidth - 20);
            const introTextHeight = wrappedIntroText.length * LINE_SPACING_TEXT;

            if (currentY + introTextHeight > PAGE_CONTENT_HEIGHT) {
                addFooter(doc, pageWidth);
                currentY = resetPage();
            }

            setDefaultFont();
            wrappedIntroText.forEach((line) => {
                doc.text(line, 15, currentY);
                currentY += LINE_SPACING_TEXT;
            });
        }

        if (section.introText && section.introText.trim() !== "") {
            currentY += LINE_SPACING_TEXT / 2;
        }

        // Render content based on type.
        if (section.tableHeader && Array.isArray(section.content) && typeof section.content[0] === 'object') {
            currentY = addTableContent(
                doc,
                section.tableHeader,
                section.content as Array<{ [key: string]: string }>,
                currentY,
                imageUrl,
                HEADER_IMG_HEIGHT
            );
        } else if (Array.isArray(section.content)) {
            currentY = renderContentWithCheck(section.content as Array<{ text: string; bold?: boolean }>, currentY);
            currentY += SECTION_SPACING;
        }
    });

    addFooter(doc, pageWidth);

    const pdfBlob = doc.output('blob');
    const pdfUrl = URL.createObjectURL(pdfBlob);

    const container = document.createElement('div');
    container.style.position = 'fixed';
    container.style.top = '0';
    container.style.left = '0';
    container.style.width = '100%';
    container.style.height = '100%';
    container.style.display = 'flex';
    container.style.justifyContent = 'center';
    container.style.alignItems = 'center';
    container.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
    container.style.setProperty('backdrop-filter', 'blur(5px)');
    container.style.zIndex = '1000';

    const iframe = document.createElement('iframe');
    iframe.src = pdfUrl;
    iframe.style.width = '80%';
    iframe.style.height = '90%';
    iframe.style.border = 'none';

    container.appendChild(iframe);
    document.body.appendChild(container);

    container.addEventListener('click', (event) => {
        if (event.target === container) {
            document.body.removeChild(container);
        }
    });
};

// Add footer content to each page.
export const addFooter = (doc: jsPDF, pageWidth: number): void => {
    const pageHeight = doc.internal.pageSize.getHeight();
    const footerTopY = pageHeight - FOOTER_HEIGHT;

    doc.setDrawColor(53, 80, 119);
    doc.setLineWidth(0.5);
    doc.line(10, footerTopY, pageWidth - 10, footerTopY);

    doc.setFontSize(FONT_SIZE_FOOTER);
    doc.setFont(FONT_FAMILY, 'normal');

    doc.text(`${CVBC_RECEPTION_PHONE.title}: ${CVBC_RECEPTION_PHONE.value}`, 10, pageHeight - 15);
    doc.text(`${CVBC_RECEPTION_FAX.title}: ${CVBC_RECEPTION_FAX.value}`, 10, pageHeight - 10);
    doc.text(`${CVBC_RECEPTION_EMAIL.title}: ${CVBC_RECEPTION_EMAIL.value}`, 10, pageHeight - 5);
    doc.text(CVBC_OFFICE_ADDRESS.title, pageWidth / 2, pageHeight - 15);
    doc.text(CVBC_OFFICE_ADDRESS.value, pageWidth / 2, pageHeight - 10);
};

// Add table content to a PDF document.
export const addTableContent = (
    doc: jsPDF,
    header: string[],
    data: Array<{ [key: string]: string }>,
    startY: number,
    imageUrl: string,
    headerHeight: number
): number => {
    const pageWidth = doc.internal.pageSize.getWidth();
    const footerTop = doc.internal.pageSize.getHeight() - FOOTER_HEIGHT;

    if (startY + 50 > footerTop) {
        addFooter(doc, pageWidth);
        doc.addPage();
        if (imageUrl) doc.addImage(imageUrl, 'JPEG', 0, 0, pageWidth, headerHeight);
        startY = headerHeight + SPACE_BELOW_HEADER;
    }

    doc.setFont(FONT_FAMILY, 'normal');
    doc.setFontSize(FONT_SIZE_CONTENT);

    (doc as any).autoTable({
        startY,
        head: [header],
        body: data.map(row => Object.values(row)),
        styles: { halign: 'left' },
        theme: 'grid',
        headStyles: { fillColor: [53, 80, 119], textColor: 255, fontStyle: 'bold', font: FONT_FAMILY },
        bodyStyles: { fillColor: [240, 240, 240], font: FONT_FAMILY },
        alternateRowStyles: { fillColor: [255, 255, 255] },
        didDrawPage: () => {
            if (imageUrl) doc.addImage(imageUrl, 'JPEG', 0, 0, pageWidth, headerHeight);
            doc.setFont(FONT_FAMILY, 'normal');
            doc.setFontSize(FONT_SIZE_CONTENT);
        
            addFooter(doc, pageWidth);
        },
    });

    return (doc as any).lastAutoTable.finalY + SECTION_SPACING + 10;
};