/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useState } from 'react';
import { KTSVG } from '../../../../../../../_metronic/helpers';
import applicationApi from '../../../../../../api/applicationApi';
import { closeAllModals } from '../../../../../../helpers';
import { EmploymentHistory } from '../../../../../../types';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

interface Props {
    employment: EmploymentHistory;
    modalName: string;
    getCurrentEmployments: Function;
}

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const DeleteEmploymentBtn: FC<Props> = ({ employment, modalName, getCurrentEmployments }) => {
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);

    const handleDeleteClick = async () => {
        try {
            setErrMsg(null);
            setLoading(true);
            if (!employment.pfn_employmenthistoryid) {
                throw new Error('Employment selected has no id');
            }
            const response = await applicationApi.deleteEmploymentHistory(employment.pfn_employmenthistoryid);
            if (response) {
                if (!response.success) {
                    if (response.message) {
                        setErrMsg(response.message);
                    } else {
                        setErrMsg('An error has occured, please try again later');
                    }
                } else {
                    closeAllModals();
                    getCurrentEmployments();
                }
            } else {
                setErrMsg('An error has occured, please try again later');
            }

            setLoading(false);
        } catch (err) {
            if (err instanceof Error) {
                setErrMsg(err.message);
            } else {
                setErrMsg('An error has occurred, please try again later');
            }
            setLoading(false);
        }
    };

    const renderDeleteEmploymentModal = () => {
        return (
            <div className='modal fade' tabIndex={-1} id={modalName}>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Are you sure you want to remove this employment?</h5>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
                            </div>
                        </div>
                        <div className='modal-body'>
                            <div className='d-flex justify-content-center'>
                                <button type='button' className='btn btn-light me-3' data-bs-dismiss='modal'>
                                    Cancel
                                </button>

                                <button
                                    type='button'
                                    className='btn btn-danger'
                                    disabled={loading}
                                    onClick={() => handleDeleteClick()}
                                >
                                    {!loading && <span className='indicator-label'>Delete</span>}
                                    {loading && (
                                        <span className='indicator-progress text-light' style={{ display: 'block' }}>
                                            Please wait...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                            {errMsg && <div className='text-danger text-center mt-7'>{errMsg}</div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <button
                type='button'
                className='btn btn-danger btn-sm btn-icon'
                data-bs-toggle='modal'
                data-bs-target={`#${modalName}`}
                onClick={() => {
                    setErrMsg(null);
                }}
            >
                <i className='bi bi-trash3 fs-2'></i>
            </button>

            {renderDeleteEmploymentModal()}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { DeleteEmploymentBtn };

/* -------------------------------------------------------------------------- */
