/* --------------------------------- IMPORTS -------------------------------- */

import * as Yup from 'yup';
import { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { FacilityAnimalCategory, FacilityAssessment, FacilitySpecies } from '../../../../../../types';
import facilityAssessmentApi from '../../../../../../api/facilityAssessmentsApi';
import { Table } from 'react-bootstrap';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

interface Props {
    goToNextStep: Function;
    assessment: FacilityAssessment;
    goToPreviousStep: Function;
}

/* -------------------------------------------------------------------------- */

/* --------------------------------- SCHEMA --------------------------------- */

const validationSchema = Yup.object().shape({
    types: Yup.array()
        .of(Yup.string())
        .required('Must pick atleast one species')
        .test('atleastOne', 'Must select atleast one species', (val) => {
            if (val && val.length > 0) {
                return true;
            } else {
                return false;
            }
        }),
});

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const AnimalSpeciesForm: FC<Props> = ({ goToNextStep, goToPreviousStep, assessment }) => {
    const [formLoading, setFormLoading] = useState(false);
    const [speciesLoading, setSpeciesLoading] = useState(true);
    const [animalCategoriesLoading, setAnimalCategoriesLoading] = useState(true);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const [species, setSpecies] = useState<FacilitySpecies[]>([]);
    const [animalCategories, setAnimalCategories] = useState<FacilityAnimalCategory[]>([]);

    useEffect(() => {
        const getSpeciesAsync = async () => {
            try {
                setSpeciesLoading(true);
                const response = await facilityAssessmentApi.getSpecies();

                if (response) {
                    if (!response.success) {
                        setErrMsg(response?.message || 'No species found');
                    } else {
                        if (response.data) {
                            setSpecies(response.data);
                        }
                    }
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
            }
            setSpeciesLoading(false);
        };

        const getAnimalCategoriesAsync = async () => {
            try {
                setAnimalCategoriesLoading(true);
                const response = await facilityAssessmentApi.getAnimalCategories();

                if (response) {
                    if (!response.success) {
                        setErrMsg(response?.message || 'No animalCategories found');
                    } else {
                        if (response.data) {
                            setAnimalCategories(response.data);
                        }
                    }
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
            }
            setAnimalCategoriesLoading(false);
        };

        getSpeciesAsync();
        getAnimalCategoriesAsync();

        return () => {
            setSpecies([]);
            setAnimalCategories([]);
            setErrMsg(null);
            setSpeciesLoading(false);
            setAnimalCategoriesLoading(false);
        };
    }, []);

    const getTypes = () => {
        const newTypes: string[] = [];

        assessment.facilityAnimalSpecies?.forEach((type) => {
            if (type.pfn_animalspecies_species && type.pfn_animalspecies_species.length > 0) {
                type.pfn_animalspecies_species.forEach((spec) => newTypes.push(spec.pfn_speciesid));
            }
        });

        return newTypes;
    };

    const convertValues = (values: string[]) => {
        const newValues: {
            pfn_name: string;
            pfn_animalcategoryid: string;
            pfn_speciesid: string;
        }[] = [];

        values.forEach((value) => {
            const spec = species.find((speci) => speci.pfn_speciesid === value);

            if (spec) {
                newValues.push({
                    pfn_name: spec._pfn_animalcategoryid_value_Formatted,
                    pfn_animalcategoryid: spec._pfn_animalcategoryid_value,
                    pfn_speciesid: spec.pfn_speciesid,
                });
            }
        });

        return newValues;
    };

    const initialValues: { types: string[] } = {
        types: assessment.facilityAnimalSpecies && assessment.facilityAnimalSpecies.length > 0 ? getTypes() : [],
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            try {
                setErrMsg(null);
                setFormLoading(true);

                if (assessment) {
                    const response = await facilityAssessmentApi.updateAssessmentAnimalSpecies(
                        assessment.pfn_facilityselfassessmentid,
                        assessment._pfn_facilityid_value,
                        convertValues(values.types),
                    );

                    if (response) {
                        if (!response.success) {
                            if (response.message) {
                                setErrMsg(response.message);
                            } else {
                                setErrMsg('An error has occured, please try again later');
                            }
                        } else {
                            goToNextStep();
                        }
                    } else {
                        setErrMsg('An error has occured, please try again later');
                    }
                } else {
                    setErrMsg('No assessment found');
                }

                setFormLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
                setFormLoading(false);
            }
        },
    });

    return (
        <>
            {speciesLoading || animalCategoriesLoading ? (
                <div>loading...</div>
            ) : errMsg ? (
                <div>{errMsg}</div>
            ) : (
                <div>
                    <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='typesForm'>
                        <div className='table-container mb-7'>
                            <label className='form-label fs-6 fw-bolder text-dark required mb-5'>
                                Please fill in all animal species seen by your practice.
                            </label>
                            <Table className='species-table' striped bordered>
                                <thead>
                                    <tr>
                                        <th className='fw-bolder'>Category</th>
                                        <th className='fw-bolder'>Species</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {animalCategories?.map((category, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className='category'>{category.pfn_name}</td>

                                                <td className='species'>
                                                    {species.map((spec, k) => {
                                                        if (
                                                            spec._pfn_animalcategoryid_value ===
                                                            category.pfn_animalcategoryid
                                                        ) {
                                                            return (
                                                                <div key={k}>
                                                                    <input
                                                                        type='checkbox'
                                                                        {...formik.getFieldProps('types')}
                                                                        className={clsx('form-check-inline ', {
                                                                            'is-invalid':
                                                                                formik.touched.types &&
                                                                                formik.errors.types,
                                                                        })}
                                                                        name='types'
                                                                        value={spec.pfn_speciesid}
                                                                        checked={formik.values.types.includes(
                                                                            spec.pfn_speciesid,
                                                                        )}
                                                                    />
                                                                    <label>{spec.pfn_name}</label>
                                                                </div>
                                                            );
                                                        } else {
                                                            return null;
                                                        }
                                                    })}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                            {formik.touched.types && formik.errors.types && (
                                <div className='fv-plugins-message-container'>
                                    <span className='text-danger' role='alert'>
                                        {formik.errors.types}
                                    </span>
                                </div>
                            )}
                        </div>

                        <div className='d-flex align-items-center mb-10'>
                            <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                            <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                        </div>

                        <div className='fv-row'>
                            <div className='d-flex justify-content-between'>
                                <button
                                    type='button'
                                    className='btn btn-primary me-3'
                                    onClick={() => goToPreviousStep()}
                                >
                                    Previous
                                </button>

                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    disabled={formik.isSubmitting || formLoading}
                                >
                                    {!formLoading && <span className='indicator-label'>Next</span>}
                                    {formLoading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                        {errMsg && <div className='text-danger text-center mt-7'>{errMsg}</div>}
                    </form>
                </div>
            )}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { AnimalSpeciesForm };

export const getAnimalSpeciesSummary = (assessment: FacilityAssessment, species: FacilitySpecies[]) => {
    if (!assessment.facilityAnimalSpecies || assessment.facilityAnimalSpecies.length === 0) {
        return 'No animal species recorded.';
    }

    return assessment.facilityAnimalSpecies
        .map((spec, index) => `${index + 1}. ${spec.pfn_name} (ID: ${spec.pfn_animalspeciesid})`)
        .join('\n');
};
/* -------------------------------------------------------------------------- */
