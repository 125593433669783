/* --------------------------------- IMPORTS -------------------------------- */

import React from 'react';
import { DropdownCustom2 } from '../../content/dropdown/DropdownCustom2';
import { KTSVG } from '../../../helpers';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../../app/redux/hooks';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

type Props = {
    className: string;
    color: string;
};

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const MixedWidgetCustom2: React.FC<Props> = ({ className, color }) => {
    const statusHistory = useAppSelector((state) => state.user.statusHistory);

    return (
        <div className={`card ${className}`}>
            {/* begin::Body */}
            <div className='card-body p-0'>
                {/* begin::Header */}
                <div className={`px-9 pt-7 card-rounded h-275px w-100 bg-${color}`}>
                    {/* begin::Heading */}
                    <div className='d-flex flex-stack'>
                        <h3 className='m-0 text-white fw-bolder fs-3'>Registration</h3>
                        <div className='ms-1'>
                            {/* begin::Menu */}
                            <button
                                type='button'
                                className={`btn btn-sm btn-icon btn-color-white btn-active-white btn-active-color-${color} border-0 me-n3`}
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                                data-kt-menu-flip='top-end'
                            >
                                <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                            </button>
                            <DropdownCustom2 />
                            {/* end::Menu */}
                        </div>
                    </div>
                    {/* end::Heading */}
                    {/* begin::Balance */}
                    <div className='d-flex text-center flex-column text-white pt-8'>
                        <span className='fw-bold fs-7'>Current Status</span>
                        <span className='fw-bolder fs-2x pt-1'>{statusHistory.subcategory.formatted}</span>
                    </div>
                    {/* end::Balance */}
                </div>
                {/* end::Header */}
                {/* begin::Items */}
                <div
                    className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-white'
                    style={{ marginTop: '-100px' }}
                >
                    {/* begin::Item */}
                    <Link to='/certificate-licensure' className='d-flex align-items-center py-3 dashboard-link'>
                        {/* begin::Symbol */}
                        <div className='symbol symbol-45px w-40px me-5'>
                            <span className='symbol-label bg-lighten'>
                                <KTSVG
                                    path='/media/icons/duotune/coding/cod002.svg'
                                    className='svg-icon-1 icon-colorize'
                                />
                            </span>
                        </div>
                        {/* end::Symbol */}
                        {/* begin::Description */}
                        <div className='d-flex align-items-center flex-wrap w-100'>
                            {/* begin::Title */}
                            <div className='mb-1 pe-3 flex-grow-1'>
                                <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                                    License Certificate
                                </span>
                                <div className='text-gray-400 fw-bold fs-7'>View & Print</div>
                            </div>
                            {/* end::Title */}
                            {/* begin::Label */}
                            <div className='d-flex align-items-center arrow'>
                                {/* <div className='fw-bolder fs-5 text-gray-800 pe-1'>$8,8m</div> */}
                                <KTSVG
                                    path={'/media/icons/duotune/arrows/arr080.svg'}
                                    className={'svg-icon-1 rotate-180 pe-2 icon-colorize'}
                                />
                            </div>
                            {/* end::Label */}
                        </div>
                        {/* end::Description */}
                    </Link>
                    {/* end::Item */}
                    {/* begin::Item */}
                    <Link to='/wallet-card' className='d-flex align-items-center py-3 dashboard-link'>
                        {/* begin::Symbol */}
                        <div className='symbol symbol-45px w-40px me-5'>
                            <span className='symbol-label bg-lighten'>
                                <KTSVG
                                    path='/media/icons/duotune/communication/com005.svg'
                                    className='svg-icon-1 icon-colorize'
                                />
                            </span>
                        </div>
                        {/* end::Symbol */}
                        {/* begin::Description */}
                        <div className='d-flex align-items-center flex-wrap w-100'>
                            {/* begin::Title */}
                            <div className='mb-1 pe-3 flex-grow-1'>
                                <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>Wallet Card</span>
                                <div className='text-gray-400 fw-bold fs-7'>View & Print</div>
                            </div>
                            {/* end::Title */}
                            {/* begin::Label */}
                            <div className='d-flex align-items-center arrow'>
                                {/* <div className='fw-bolder fs-5 text-gray-800 pe-1'>$8,8m</div> */}
                                <KTSVG
                                    path={'/media/icons/duotune/arrows/arr080.svg'}
                                    className={'svg-icon-1 rotate-180 pe-2 icon-colorize'}
                                />
                            </div>
                            {/* end::Label */}
                        </div>
                        {/* end::Description */}
                    </Link>
                    {/* end::Item */}
                    {/* begin::Item */}
                    <Link to='/facilities/my-facilities' className='d-flex align-items-center py-3 dashboard-link'>
                        {/* begin::Symbol */}
                        <div className='symbol symbol-45px w-40px me-5'>
                            <span className='symbol-label bg-lighten'>
                                <KTSVG
                                    path='/media/icons/duotune/abstract/abs037.svg'
                                    className='svg-icon-1 icon-colorize'
                                />
                            </span>
                        </div>
                        {/* end::Symbol */}
                        {/* begin::Description */}
                        <div className='d-flex align-items-center flex-wrap w-100'>
                            {/* begin::Title */}
                            <div className='mb-1 pe-3 flex-grow-1'>
                                <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>Facilities</span>
                                <div className='text-gray-400 fw-bold fs-7'>Manage & Update</div>
                            </div>
                            {/* end::Title */}
                            {/* begin::Label */}
                            <div className='d-flex align-items-center arrow'>
                                {/* <div className='fw-bolder fs-5 text-gray-800 pe-1'>$2,5b</div> */}
                                <KTSVG
                                    path={'/media/icons/duotune/arrows/arr080.svg'}
                                    className={'svg-icon-1 rotate-180 pe-2 icon-colorize'}
                                />
                            </div>
                            {/* end::Label */}
                        </div>
                        {/* end::Description */}
                    </Link>
                    {/* end::Item */}
                    {/* begin::Item */}
                    <Link to='/applications/history' className='d-flex align-items-center py-3 dashboard-link'>
                        {/* begin::Symbol */}
                        <div className='symbol symbol-45px w-40px me-5'>
                            <span className='symbol-label bg-lighten'>
                                <KTSVG
                                    path='/media/icons/duotune/abstract/abs027.svg'
                                    className='svg-icon-1 icon-colorize'
                                />
                            </span>
                        </div>
                        {/* end::Symbol */}
                        {/* begin::Description */}
                        <div className='d-flex align-items-center flex-wrap w-100'>
                            {/* begin::Title */}
                            <div className='mb-1 pe-3 flex-grow-1'>
                                <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                                    Application History
                                </span>
                                <div className='text-gray-400 fw-bold fs-7'>View & Print</div>
                            </div>
                            {/* end::Title */}
                            {/* begin::Label */}
                            <div className='d-flex align-items-center arrow'>
                                {/* <div className='fw-bolder fs-5 text-gray-800 pe-1'>$1,7b</div> */}
                                <KTSVG
                                    path={'/media/icons/duotune/arrows/arr080.svg'}
                                    className={'svg-icon-1 rotate-180 pe-2 icon-colorize'}
                                />
                            </div>
                            {/* end::Label */}
                        </div>
                        {/* end::Description */}
                    </Link>
                    {/* end::Item */}
                </div>
                {/* end::Items */}
            </div>
            {/* end::Body */}
        </div>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { MixedWidgetCustom2 };

/* -------------------------------------------------------------------------- */
