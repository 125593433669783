/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useEffect, useState } from 'react';
import { PageTitle } from '../../../../_metronic/layout/core';
import Table from 'react-bootstrap/Table';
import receiptApi from '../../../api/receiptApi';
import { Link, useParams } from 'react-router-dom';
import { Receipt } from '../../../types';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { useAppSelector } from '../../../redux/hooks';
import { convertIsoDateToString } from '../../../helpers';

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const ReceiptPage: FC = () => {
    const user = useAppSelector((state) => state.user);
    const [receipt, setReceipt] = useState<Receipt | null>();
    const [loading, setLoading] = useState(true);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const { pfn_paymentid } = useParams();

    useEffect(() => {
        const getReceiptAsync = async () => {
            try {
                if (pfn_paymentid) {
                    const response = await receiptApi.getReceipt(pfn_paymentid);

                    if (response) {
                        if (!response.success) {
                            if (response.message) {
                                setErrMsg(response.message);
                            } else {
                                setErrMsg('An error occured, please try again later');
                            }
                        } else {
                            if (response.data) {
                                setReceipt(response.data);
                            } else {
                                setErrMsg('No data found');
                            }
                        }
                    } else {
                        setErrMsg('An error has occurred, please try again later');
                    }
                }
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
            }
            setLoading(false);
        };

        getReceiptAsync();

        return () => {
            setErrMsg(null);
            setLoading(false);
            setReceipt(null);
        };
    }, [pfn_paymentid]);

    const CreateItemsTable = () => {
        return (
            <div className='mb-10 mt-20'>
                <h5 className='mt-5'>Receipt Items:</h5>
                <div className='table-container'>
                    <Table className='receipt-items-table border' striped bordered>
                        <thead>
                            <tr>
                                <th className='fw-bolder'>Fee / Item</th>
                                <th className='fw-bolder'>Amount</th>
                                <th className='fw-bolder'>Quantity</th>
                                <th className='fw-bolder'>Subtotal</th>
                            </tr>
                        </thead>
                        <tbody>
                            {receipt?.pfn_invoiceid?.items?.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td className='item'>{item._pfn_feeid_value_Formatted}</td>
                                        <td className='amount'>{item.pfn_amount_Formatted}</td>
                                        <td className='qty'>{item.pfn_quantity_Formatted}</td>
                                        <td className='sub'>{item.pfn_subtotal_Formatted}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    };

    return (
        <div className='receipt-page'>
            {loading ? (
                <div>Retreiviing receipt, please wait...</div>
            ) : errMsg ? (
                <div>{errMsg}</div>
            ) : receipt ? (
                <>
                    <div className='d-flex justify-content-center mb-10'>
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/logos/cvbc_logo_trans_blue.png')}
                            className='payment-logo h-80px'
                        />
                    </div>
                    <div className='d-flex justify-content-between px-2'>
                        {receipt.pfn_invoiceid?._pfn_accountid_value ? (
                            <div>
                                <div className='text-primary'>
                                    <b>{receipt.pfn_invoiceid._pfn_accountid_value_Formatted}</b>
                                </div>
                                <div>
                                    <b>
                                        {user.firstname} {user.lastname}
                                    </b>
                                </div>
                                <div>{receipt.pfn_invoiceid.pfn_accountid?.address1_line1}</div>
                                <div>{receipt.pfn_invoiceid.pfn_accountid?.address1_line2}</div>
                                <div>{receipt.pfn_invoiceid.pfn_accountid?.address1_city}, BC</div>
                                <div>{receipt.pfn_invoiceid.pfn_accountid?.address1_postalcode}</div>
                                <div>Canada</div>
                            </div>
                        ) : user.address.mailing === 'Home' ? (
                            <div>
                                <div>
                                    <b>
                                        {user.firstname} {user.lastname}
                                    </b>
                                </div>
                                <div>{user.address.home.line1}</div>
                                <div>{user.address.home.line2}</div>
                                <div>
                                    {user.address.home.city}, {user.address.home.province}
                                </div>
                                <div>{user.address.home.postalCode}</div>
                                <div>{user.address.home.country}</div>
                            </div>
                        ) : (
                            <div>
                                <div>
                                    <b>
                                        {user.firstname} {user.lastname}
                                    </b>
                                </div>
                                <div>{user.facilities.primary.address.line1}</div>
                                <div>{user.facilities.primary.address.line2}</div>
                                <div>{user.facilities.primary.address.city}, BC</div>
                                <div>{user.facilities.primary.address.postalCode}</div>
                                <div>Canada</div>
                            </div>
                        )}

                        <div className='d-flex flex-column align-items-end'>
                            <div>
                                <b>{receipt?.pfn_name}</b>
                            </div>
                            <div>{convertIsoDateToString(receipt?.pfn_receiveddate)}</div>
                        </div>
                    </div>
                    <div className='mb-10 mt-20'>
                        <div className='mb-10'>{CreateItemsTable()}</div>
                    </div>
                    <div className='mb-10'>
                        <h5>Receipt Summary:</h5>
                        <div className='table-container'>
                            <Table className='receipt-summary-table border' bordered striped>
                                <tbody>
                                    <tr>
                                        <td className='title'>
                                            <b>Subtotal</b>
                                        </td>
                                        <td className='value'>{receipt.pfn_invoiceid?.pfn_subtotal_Formatted}</td>
                                    </tr>
                                    <tr>
                                        <td className='title'>
                                            <b>Taxes (GST)</b>
                                        </td>
                                        <td className='value'>{receipt.pfn_invoiceid?.pfn_tax_Formatted}</td>
                                    </tr>
                                    <tr>
                                        <td className='title'>
                                            <b>Total Paid</b>
                                        </td>
                                        <td className='value'>{receipt.pfn_amount_Formatted}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center no-print'>
                        <Link to='/receipts/all'>
                            <button className='btn btn-primary mx-3'>Back</button>
                        </Link>
                        <button className='btn btn-primary me-5' onClick={() => window.print()}>
                            Print
                        </button>
                    </div>
                </>
            ) : (
                <div>No receipt found.</div>
            )}
        </div>
    );
};

/* -------------------------------------------------------------------------- */

/* ------------------------------ PAGE WRAPPER ------------------------------ */

const ReceiptWrapper: FC = () => {
    const { pfn_name } = useParams();
    const title = `Receipt: ${pfn_name}`;
    // const breadcrumbs: PageLink[] = [
    //     { title: 'Home', path: '/dashboard', isActive: false },
    //     { title: 'Home', path: '/dashboard', isActive: false, isSeparator: true },
    //     { title: 'Receipts', path: '/receipts', isActive: false },
    //     { title: 'Receipts', path: '/receipts', isActive: false, isSeparator: true },
    // ];

    return (
        <>
            <PageTitle breadcrumbs={[]}>{title}</PageTitle>
            <ReceiptPage />
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { ReceiptWrapper };

/* -------------------------------------------------------------------------- */
