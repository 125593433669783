import React from 'react';
import { MenuItem } from './MenuItem';
import { MenuInnerWithSub } from './MenuInnerWithSub';
import { useIntl } from 'react-intl';
import { useAppSelector } from '../../../../app/redux/hooks';
import { LINK_REGISTRATIOM_RENEWAL_DETAILS1 } from '../../../../app/constants';

export function MenuInner() {
    const intl = useIntl();
    const user = useAppSelector((state) => state.user);

    return (
        <>
            <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/dashboard' />
            {/* <MenuItem title='Layout Builder' to='/builder' /> */}

            <MenuInnerWithSub title='Registration' to='/registration' menuPlacement='bottom-start' menuTrigger='click'>
                <MenuInnerWithSub
                    title='Application'
                    to='/application'
                    hasArrow={true}
                    menuPlacement='right-start'
                    menuTrigger={`{default:'click', lg: 'hover'}`}
                >
                    {user.statusHistory.subcategory.formatted === 'Applicant' ? (
                        <MenuItem to='/applications/registration' title='Registration' />
                    ) : (
                        <>
                            <div className='menu-item me-lg-1'>
                                <a
                                    className='menu-link py-3'
                                    href='https://www.cvbc.ca/registration/class-and-name-changes/'
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    <span className='menu-title'>Registration Class Transfer</span>
                                </a>
                            </div>
                            {user.renewalYear && user.pfn_canrenew && <MenuItem to={LINK_REGISTRATIOM_RENEWAL_DETAILS1.value} title='Renewal' />}
                        </>
                    )}
                    <MenuItem to='/applications/history' title='Application History' />
                </MenuInnerWithSub>
                <MenuItem title='Certificate of Licensure' to='/certificate-licensure' />
                <MenuItem title='Wallet Card' to='/wallet-card' />
                {/* <MenuItem title='Manage Facilities' to='/manage-facilties' /> */}
                <MenuInnerWithSub
                    title='Facilities'
                    to='/facilities'
                    hasArrow={true}
                    menuPlacement='right-start'
                    menuTrigger={`{default:'click', lg: 'hover'}`}
                >
                    <MenuItem to='/facilities/my-facilities' title='My Facilities' />
                    <MenuItem to='/facilities/search' title='Facility Search' />
                    <MenuItem to='/facilities/assessments' title='Annual Declarations' />
                </MenuInnerWithSub>
                <MenuInnerWithSub
                    title='Receipts'
                    to='/receipts'
                    hasArrow={true}
                    menuPlacement='right-start'
                    menuTrigger={`{default:'click', lg: 'hover'}`}
                >
                    <MenuItem to='/receipts/all' title='All Receipts' />
                    <MenuItem to='/receipts/personal' title='Personal Receipts' />
                    <MenuItem to='/receipts/facility' title='Facility Receipts' />
                </MenuInnerWithSub>
                {/* <MenuItem title='Receipts' to='/receipts' /> */}
                <MenuInnerWithSub
                    title='Invoices'
                    to='/invoices'
                    hasArrow={true}
                    menuPlacement='right-start'
                    menuTrigger={`{default:'click', lg: 'hover'}`}
                >
                    <MenuItem to='/invoices/all' title='All Invoices' />
                    <MenuItem to='/invoices/personal' title='Personal Invoices' />
                    <MenuItem to='/invoices/facility' title='Facility Invoices' />
                </MenuInnerWithSub>
                {/* <MenuItem title='Invoices' to='/invoices' /> */}
            </MenuInnerWithSub>
            <MenuInnerWithSub
                title='Continuing Education'
                to='/continuing-education'
                menuPlacement='bottom-start'
                menuTrigger='click'
            >
                <MenuItem title='CE Transcripts' to='/transcripts' />
                <div className='menu-item me-lg-1'>
                    <a className='menu-link py-3' href='https://classroom.cvbc.ca/' target='_blank' rel='noreferrer'>
                        <span className='menu-title'>Learning Hub</span>
                    </a>
                </div>
            </MenuInnerWithSub>
            <MenuInnerWithSub
                title='Account Management'
                to='/account-management'
                menuPlacement='bottom-start'
                menuTrigger='click'
            >
                <MenuItem title='Contact Information' to='/contact-info' />
                <MenuItem title='Change Password' to='/change-password' />
                <MenuItem title='Change Permission' to='/change-permission' />
                <MenuItem title='Change Email' to='/change-email' />
            </MenuInnerWithSub>
            <div className='menu-item me-lg-1'>
                <a className='menu-link py-3' href='https://cvbc.ca/for-registrant/' target='_blank' rel='noreferrer'>
                    <span className='menu-title'>Registrant Website Content</span>
                </a>
            </div>
        </>
    );
}
