/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../../_metronic/layout/core';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../redux/hooks';
import { FacilityState } from '../../../redux/slices/UserSlice';
import { convertToSafeString } from '../../../helpers';

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const FacilitiesReceiptsPage: FC = () => {
    const user = useAppSelector((state) => state.user);
    const [facilities, setFacilities] = useState<FacilityState[]>([]);

    useEffect(() => {
        const getDesignatedFacilities = () => {
            const facilities: FacilityState[] = [];

            if (user.facilities.primary.designatedVetId === user.id) {
                facilities.push(user.facilities.primary);
            }

            user.facilities.other.forEach((facility) => {
                if (facility.designatedVetId === user.id) {
                    facilities.push(facility);
                }
            });

            return facilities;
        };

        setFacilities(getDesignatedFacilities());

        return () => {
            setFacilities([]);
        };
    }, [user.facilities.other, user.facilities.primary, user.id]);

    return (
        <>
            <div className='card card-xl mb-5 mb-xl-8'>
                <div className='card-header border-0'>
                    <h3 className='card-title fw-bolder text-dark'>General Information</h3>
                </div>

                <div className='card-body pt-0'>
                    <ul>
                        <li className='mb-5'>
                            To view the receipts for a facility you must be the designated veterinarian on file for that
                            facility.
                        </li>
                        <li className='mb-5'>
                            You will only see the receipts that are filed under your name for the facility where you are
                            a designated veterinarian.
                        </li>
                        <li className='mb-5'>
                            If the list below is not accurate or complete, please update our records by removing or
                            adding facilities as necessary. To add a facility to your record, please search for it using
                            the{' '}
                            <Link className='text-decoration-underline fw-bold' to={'/facilities/search'}>
                                Facility Search
                            </Link>
                            . If your facility is added to{' '}
                            <Link className='text-decoration-underline fw-bold' to={'/facilities/my-facilities'}>
                                My Facilities
                            </Link>{' '}
                            and does not show you as the designated veterinarian, then please contact us at{' '}
                            <a className='text-decoration-underline fw-bold' href='mailto:reception@cvbc.ca'>
                                reception@cvbc.ca
                            </a>
                            .
                        </li>
                        <li>Please select a facility below to view the associated receipts.</li>
                    </ul>
                </div>
            </div>
            <div className='card card-xl mb-5 mb-xl-8'>
                <div className='card-header border-0'>
                    <h3 className='card-title fw-bolder text-dark'>My Designated Veterinarian Facilities</h3>
                </div>

                <div className='card-body pt-0'>
                    <ul>
                        {facilities?.length > 0 ? (
                            facilities.map((facility, i) => (
                                <li key={i} className='mb-5'>
                                    <Link
                                        to={`/receipts/facility/${convertToSafeString(facility.name)}/${facility.id}`}
                                    >
                                        {facility.name}
                                    </Link>
                                </li>
                            ))
                        ) : (
                            <li className='mb-5'>
                                You have no facilities added to{' '}
                                <Link className='text-decoration-underline fw-bold' to={'/facilities/my-facilities'}>
                                    My Facilities
                                </Link>{' '}
                                where you are the designated veterinarian. Please add the facility that is missing by
                                using the{' '}
                                <Link className='text-decoration-underline fw-bold' to={'/facilities/search'}>
                                    Facility Search
                                </Link>
                                . If it still does not show you as the designated veterinarian, then please contact us
                                at{' '}
                                <a className='text-decoration-underline fw-bold' href='mailto:reception@cvbc.ca'>
                                    reception@cvbc.ca
                                </a>
                                .
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* ------------------------------ PAGE WRAPPER ------------------------------ */

const FacilitiesReceiptsWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.FACILITYRECEIPTS' })}</PageTitle>
            <FacilitiesReceiptsPage />
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { FacilitiesReceiptsWrapper };

/* -------------------------------------------------------------------------- */
