/* --------------------------------- IMPORTS -------------------------------- */

import clsx from 'clsx';
import React, { FC, useState } from 'react';
import { Application } from '../../../../../types';
import { EducationForm } from './forms/EducationForm';
import { InitialDetailsForm } from './forms/InitialDetailsForm';
import { JurisdictionForm } from './forms/JuridictionForm';
import { EmploymentForm } from './forms/EmploymentForm';
import { QuestionsForm } from './forms/QuestionsForm';
import { SummaryForm } from './forms/SummaryForm';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

interface Props {
    initialStep: number;
    application: Application | null;
}

/* -------------------------------------------------------------------------- */

/* --------------------------------- HELPERS -------------------------------- */

const stepsTitles = ['Initial Details', 'Education', 'Jurisdiction', 'Employment', 'Questions', 'Summary'];

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const RegAppWizard: FC<Props> = ({ initialStep, application }) => {
    const [currentStep, setCurrentStep] = useState(initialStep);

    const goToNextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const goToPreviousStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const renderStepContent = () => {
        switch (currentStep) {
            case 0:
                return <InitialDetailsForm goToNextStep={goToNextStep} />;

            case 1:
                return <EducationForm goToNextStep={goToNextStep} />;

            case 2:
                if (application) {
                    return (
                        <JurisdictionForm
                            goToNextStep={goToNextStep}
                            goToPreviousStep={goToPreviousStep}
                            application={application}
                        />
                    );
                } else {
                    return <div>Application not found</div>;
                }

            case 3:
                if (application) {
                    return (
                        <EmploymentForm
                            goToNextStep={goToNextStep}
                            goToPreviousStep={goToPreviousStep}
                            application={application}
                        />
                    );
                } else {
                    return <div>Application not found</div>;
                }

            case 4:
                if (application) {
                    return (
                        <QuestionsForm
                            goToNextStep={goToNextStep}
                            goToPreviousStep={goToPreviousStep}
                            application={application}
                        />
                    );
                } else {
                    return <div>Application not found</div>;
                }

            case 5:
                if (application) {
                    return <SummaryForm goToPreviousStep={goToPreviousStep} application={application} />;
                } else {
                    return <div>Application not found</div>;
                }

            default:
                return <div>Not Found</div>;
        }
    };

    return (
        <>
            <div className='no-print'>
                <div className='wizard-stepper-progress'>
                    {stepsTitles.map((stepTitle, i) => {
                        return (
                            <React.Fragment key={i}>
                                {i !== 0 && (
                                    <div
                                        className={clsx('step-divider', {
                                            current: currentStep === i,
                                            completed: i < currentStep,
                                        })}
                                    ></div>
                                )}
                                <div className='step'>
                                    <div
                                        className={clsx('step-number', {
                                            current: currentStep === i,
                                            completed: i < currentStep,
                                        })}
                                    >
                                        {i + 1}
                                    </div>
                                    <div
                                        className={clsx('step-title', {
                                            current: currentStep === i,
                                            completed: i < currentStep,
                                        })}
                                    >
                                        {stepTitle}
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
            <div className='mb-10'>{renderStepContent()}</div>
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { RegAppWizard };

/* -------------------------------------------------------------------------- */
