import { useNavigate } from "react-router-dom";
import { Application } from "../../../../types";

export function RenewalAppButtons({ application, goToNextPage }: { application: Application | null; goToNextPage: () => void; }) {
    const navigate = useNavigate(); 
    const handleNavigate = (path: string) => {
        navigate(path);
    };

    return (
        <div>
            {!application ? (
                <button type='button' className='btn btn-primary my-1' onClick={goToNextPage}>
                    Continue to Registration Renewal
                </button>
            ) : application.statuscode_Formatted === 'Active' ? (
                application.pfn_fomcomplete ? (
                    <button type='button' className='btn btn-primary my-1' onClick={() => handleNavigate(`/applications/renewal/status/${application.pfn_applicationid}`)}>
                        Check Status of Renewal Application
                    </button>
                ) : (
                    <button type='button' className='btn btn-primary my-1' onClick={() => handleNavigate(`/applications/renewal/${application.pfn_applicationid}`)}>
                        Resume Registration Renewal Application
                    </button>
                )
            ) : application.statuscode_Formatted === 'Granted' ? (
                <button type='button' className='btn btn-primary my-1' onClick={() => handleNavigate(`/applications/renewal/status/${application.pfn_applicationid}`)}>
                    View Summary
                </button>
            ) : (
                <button type='button' className='btn btn-primary my-1' onClick={goToNextPage}>
                    Continue to Registration Renewal
                </button>
            )}
        </div>
    );
}